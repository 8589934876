"use client";
import React, { useEffect, useState } from "react";
import { ProductImageGallery } from ".";
import { AMAZONE_MEDIA_URL, defaultproduct } from "@/utils/constant";
import Skeleton from "react-loading-skeleton";

export default function ProductMediaBox({
  media_path,
  supplier_id,
  id,
  imgData,
}: any) {
  const [image, setImage] = useState<string>(
    media_path
      ? AMAZONE_MEDIA_URL +
          "User/" +
          supplier_id +
          "/Product/" +
          id +
          "/" +
          media_path
      : defaultproduct
  );

  const [loading, setLoading] = useState<boolean>(true) 
  const fetchImagePath = (image: any) => {
    setImage(image);
  };
  useEffect(()=>{
    if(media_path){
      setLoading(false)
    }
  },[media_path])
  return (
    <>
  {!loading ?(  <div>
      <div
        className="position-relative d-flex justify-content-center"
        style={{ width: "100%", height: "416px" }}
      >
        <img
          loading="lazy"
          alt="image"
          className="custom-marketplace-detail-img cursor-pointer"
          src={image}
        />
      </div>
      <ProductImageGallery
        subid={id}
        supplier_id={supplier_id}
        imgData={imgData}
        fetchImagePath={fetchImagePath}
      />
    </div>):
    (
      <div>
      <div
        className="position-relative d-flex justify-content-center product-media"
      >
        <Skeleton height={400} width={460} borderRadius={10}/>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Skeleton height={120} width={150} borderRadius={20}/>
        <Skeleton height={120} width={150} borderRadius={20}/>
        <Skeleton height={120} width={150} borderRadius={20}/>
      </div>
    </div>
    )
    }
    </>
  );
}
