"use client";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Button } from "@/components/bootstrap";
import { MarketplaceBlogCardProps } from "@/utils/types";
import StarRatingsFeature from "./star-ratings-feature";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
  blockchain_verified,
} from "@/utils/constant";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter, Link } from "@/navigation";
import { useSettingsContext } from "@/context";
import { fetchProductDetailSlug } from "@/utils/apiServices";
import NoMoreCompareModal from "../widgets/no-more-compare-modal";
import { useTranslations } from "next-intl";

function ProductCard({
  id,
  heading,
  content,
  image,
  price,
  cardVariants,
  rating,
  supplier_id,
  slug,
  inventoryType,
  businessLogo,
}: MarketplaceBlogCardProps) {
  const router = useRouter();
  const [isAddToCart, setIsAddToCart] = useState<boolean>(false)
  const [isAddToCompare, setIsAddToCompare] = useState<boolean>(false)
  const {cartItem, compareItem, onUpdate } = useSettingsContext();
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const marketplaceText = useTranslations("marketplaceText")


  useEffect(()=>{
    const cartStatus=cartItem?.some(cartItem => cartItem?.id === id)
    const compareStatus = compareItem?.some(compareItem => compareItem?.id === id)
    setIsAddToCart(cartStatus);
    setIsAddToCompare(compareStatus)
  },[cartItem,compareItem])

const addToCompare=async(slug:any)=>{
  if(compareItem?.length<3){

    const productDetailData = await fetchProductDetailSlug(slug);
    if (productDetailData) {
    const data = {
      id: productDetailData?.id,
      title: productDetailData?.title,
      price: productDetailData?.price,
      description: productDetailData?.description,
      media_path: productDetailData?.media_path,
      avg_rating: productDetailData?.avg_rating,
      supplier_id: productDetailData?.supplier_id,
      seller_id: productDetailData?.seller_id,
      seller_slug: productDetailData?.seller_slug,
      product_media: productDetailData?.product_media,
      product_brand_name: productDetailData?.product_brand_name,
      warranty_info: productDetailData?.warranty_info,
      specification: productDetailData?.specification,
      information: productDetailData?.information,
      total_reviews: productDetailData?.total_reviews,
      inventory_type: productDetailData?.inventory_type,
      business_logo: productDetailData?.business_logo,
      quantity: productDetailData?.quantity,
      slug: productDetailData?.slug,
    };
    let existingItem = compareItem?.find((item) => item.id === data.id);
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 0) + productQuantity;
    } else {
      data.quantity = productQuantity;
      compareItem.push(data);
    }
    onUpdate("compareItem", [...compareItem]);
    onUpdate("compareModal", true);
  }
  }
  else{
    setModalShow(true)
  }
}
  return (
    <motion.div className="marketplace-cards" variants={cardVariants}>
      <div className="card">
        <Link href={`/item-detail/${slug}`} title={heading}>
          <div className="w-100 d-flex justify-content-center image--wrapper position-relative">
            <div className="verification-icon-container position-absolute top-5 start-0 m-2">
              <img
                loading="lazy"
                src={blockchain_verified}
                className="verifiedIcon"
                alt="verified"
              />
              <img
                className="businessIcon"
                alt="image"
                src={
                  businessLogo
                    ? `${AMAZONE_MEDIA_URL}User/${supplier_id}/BusinessLogo/${businessLogo}`
                    : default_supplier_logo
                }
                height={58}
                width={58}
              />
            </div>
            <img
              loading="lazy"
              alt={heading}
              src={
                image
                  ? AMAZONE_MEDIA_URL +
                    "User/" +
                    supplier_id +
                    "/Product/" +
                    id +
                    "/" +
                    ThumbImageRatio +
                    "/" +
                    image
                  : defaultproduct
              }
              height="100%"
              width="100%"
            />
            <div className="product-text m-4 text-white bg-black rounded-4 px-3">
              {inventoryType}
            </div>
          </div>
          <div className="card__content">
            <h4 className="card__heading mt-2">{heading}</h4>
            <p className="card__product">{content}</p>
            <div className="card__rating">
              <StarRatingsFeature
                data={rating}
                starRatedColor="black"
                starHoverColor="black"
                starDimension="17px"
              />
            </div>
            <p className="card__price py-2">{price ? price : 0}</p>
          </div>
        </Link>
        <div className="card-button d-flex justify-content-between px-4 mb-2">
          <button
            className="text-white secondary_btn rounded-5 px-lg-3 px-md-2 py-2 my-sm-2 btn"
            disabled={isAddToCart}
            style={{cursor:`${isAddToCart ? "not-allowed": "pointer"}`,pointerEvents:`${isAddToCart ? "auto": "auto"}`}}
            onClick={() => router.push(`/item-detail/${slug}`)}
          >
          {isAddToCart ? marketplaceText("already_added") : marketplaceText("add_to_cart")}
          </button>
          <button
            className="rounded-5 px-lg-3 px-md-2 py-2 my-sm-2 primary_btn btn"
            disabled={isAddToCompare}
            onClick={() => addToCompare(slug)}
            style={{cursor:`${isAddToCompare ? "not-allowed": "pointer"}`,pointerEvents:`${isAddToCompare ? "auto": "auto"}`}}
          >
            {marketplaceText("compare")}
          </button>
        </div>
      </div>
      <NoMoreCompareModal
        type="supplier"
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={"You can maximum add 3 product to compare!"}
      />
    </motion.div>
  );
}

export default ProductCard;
