"use client";
import React, { useState } from "react";
import Select from "react-select";
import * as Yup from "yup";
import { Formik, Form, Field, ErrorMessage, FormikProps } from "formik";
import { Button, Col, Row } from "@/components/bootstrap";
import { CompanySuggestionData, FormValues } from "@/utils/types";
import { baseUrl, contactBaseUrl } from "@/utils/constant";
import { fetchApiData } from "@/utils/appService";
import { Link } from "@/navigation";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  company: Yup.string().required("Company name is required"),
  phone: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be a 10-digit number")
    .required("Mobile number is required"),
  reason: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

function Contact() {
  const [isError, setIsError] = useState<string>("false");

  const [companySuggestions, setCompanySuggestions] = useState<
    CompanySuggestionData[]
  >([]);

  const loadCompanySuggestions = async (newValue: string) => {
    try {
      const apiUrl = `${contactBaseUrl}/companies/suggest?query=${newValue}`;

      const response: CompanySuggestionData[] = await fetchApiData<
        CompanySuggestionData[]
      >(apiUrl);

      setCompanySuggestions(response);
    } catch (error) {
      console.error("Error fetching company suggestions:", error);
    }
  };

  const CustomOption = ({ innerRef, innerProps, data }: any) => (
    <div
      ref={innerRef}
      {...innerProps}
      className="custom-option cursor-pointer"
    >
      <img src={data.logo} alt={data.label} className="company-logo my-2" />
      {data.label}
    </div>
  );

  const handleSubmit = async (values: any, actions: any) => {
    setIsError("false");
    actions.setSubmitting(true);

    try {
      const apiUrl = `${baseUrl}/v2/user/contact-us`;
      const response: any = await fetchApiData(apiUrl, "post", values, true);

      if (response?.status === 200) {
        setIsError("success");
      } else {
        setIsError("error");
      }
    } catch (error) {
      console.error("Error submiting contact us:", error);
    }
  };

  return (
    <div className="container-fluid shadow p-3 mb-5 bg-body my-5 rounded-5">
      <h3 className="text-center mt-4">Have any questions for us?</h3>
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          reason: "",
          message: "",
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue }: FormikProps<any>) => (
          <Form className="mt-3">
            <Row className="form-input">
              <label htmlFor="name" className="visually-hidden">
                Name:
              </label>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <Field
                  type="text"
                  className="inputcss rounded-3 width-basic from-text"
                  id="name"
                  name="name"
                  placeholder="Name"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="error-message"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="email" className="visually-hidden">
                  email:
                </label>
                <Field
                  type="email"
                  className="inputcss rounded-3 width-basic from-text"
                  id="email"
                  name="email"
                  placeholder="Work Email"
                />
                <ErrorMessage
                  name="email"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <Row className="form-input">
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="company" className="visually-hidden">
                  Company Name:
                </label>
                <Select
                  inputId="react-select-3-input"
                  aria-label="Select company name"
                  options={companySuggestions.map((company) => ({
                    value: company.name,
                    label: company.name,
                    logo: company.logo,
                  }))}
                  onInputChange={(newValue) => {
                    if (newValue) {
                      loadCompanySuggestions(newValue);
                    }
                  }}
                  onChange={(e) => {
                    setFieldValue("company", e?.value || "");
                  }}
                  placeholder="Enter company name"
                  isClearable
                  name="company"
                  className="rounded-3 width-basic from-text"
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      padding: "12px 17px",
                      border: "1px solid #BEBEBE",
                      opacity: "1",
                      width: "100%",
                      height: "65px",
                      alignItems: "left",
                      borderRadius: "8px",
                    }),
                  }}
                  components={{ Option: CustomOption }}
                />
                <ErrorMessage
                  name="company"
                  component="div"
                  className="error-message"
                />
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} className="p-2">
                <label htmlFor="phone" className="visually-hidden">
                  Phone Number:
                </label>
                <Field
                  type="text"
                  className="inputcss rounded-3 width-basic from-text"
                  id="phone"
                  name="phone"
                  placeholder="Phone Number"
                />
                <ErrorMessage
                  name="phone"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <Row className="form-input">
              <label htmlFor="reason" className="visually-hidden">
                Subject:
              </label>
              <Col xs={12}>
                <Field
                  type="text"
                  className="inputcss rounded-3 width-full-length from-text"
                  id="reason"
                  name="reason"
                  placeholder="Subject for connecting"
                />
                <ErrorMessage
                  name="reason"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <Row className="form-input mt-md-2">
              <label htmlFor="message" className="visually-hidden">
                Message:
              </label>
              <Col xs={12}>
                <Field
                  as="textarea"
                  className="inputcss rounded-3 width-full from-text"
                  id="message"
                  name="message"
                  rows="6"
                  placeholder="Write what you would like to know about e-procedure"
                />
                <ErrorMessage
                  name="message"
                  component="div"
                  className="error-message"
                />
              </Col>
            </Row>
            <div className="text-center mt-4 mb-5">
              <Button
                type="submit"
                variant="secondary"
                className="text-white rounded-5 from-text my-2 get-started-btn"
                size="lg"
                disabled={isSubmitting}
              >
                Submit Your Query
              </Button>
              {isError === "success" && (
                <div className="alert-success">Successfully Submitted</div>
              )}
              <div className="info-label">
                By submitting this form you are agreeing to our{" "}
                <Link target="_blank" className="text-black a" href="/privacy-policy">
                  privacy policy.
                </Link>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default Contact;
