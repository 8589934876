import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CartItemCardSkeleton = () => {
  return (
    <div className="d-flex flex-sm-row flex-column justify-content-between px-sm-4 px-2 gap-3">
    <div className="d-flex justify-content-center align-items-center gap-3 cursor-pointer"
    >
     <Skeleton height={90} width={90} borderRadius={10}/>
      <div>
            <Skeleton height={10} width={140}/>
        <span className="fs-7 fw-light">
        <Skeleton height={10} width={40}/>
        </span>
        <div className="d-flex justify-content-start align-items-center gap-2 py-1">
     <Skeleton height={40} width={40} borderRadius={50}/>
        
     <Skeleton height={10} width={80}/>

        </div>
      </div>
    </div>
            <div className="d-flex justify-content-center align-items-center gap-2">
    <Skeleton height={20} width={40}/>

    <Skeleton height={60} width={60} borderRadius={50}/>
    <Skeleton height={20} width={40}/>
    </div>
    <div className="fw-bold fs-6 my-4 d-sm-block d-flex justify-content-center align-items-center">
    <Skeleton height={10} width={90} count={2}/>
    </div>
  </div>
  )
}

export default CartItemCardSkeleton