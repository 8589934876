"use client";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { useRouter } from "@/navigation";

function ProductImg({ image, id, supplier_id, slug }: any) {
  const router = useRouter();
  const handleData = (slug: any) => {
    router.replace(`/item-detail/${slug}`);
  };
  return (
    <div
      className="rounded-5 custom-theme-shadow overflow-hidden h-100 mx-3 my-3 cursor-pointer"
      onClick={() => handleData(slug)}
    >
      <img
        loading="lazy"
        alt="image"
        className="productImg"
        src={
          image
            ? AMAZONE_MEDIA_URL +
              "User/" +
              supplier_id +
              "/Product/" +
              id +
              "/" +
              ThumbImageRatio +
              "/" +
              image
            : defaultproduct
        }
      />
    </div>
  );
}

export default ProductImg;
