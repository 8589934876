import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogDetailCardSkeleton = () => {
  return (
    <div className="row">
      <div className="col-2 text-center">
        <div className="social-share">
          <Skeleton height={50} width={50} borderRadius={50} />
          <Skeleton height={50} width={50} borderRadius={50} />
          <Skeleton height={50} width={50} borderRadius={50} />
        </div>
      </div>
      <div className="col-10">
        <div className="d-flex justify-content-between mb-3 pb-4 ">
          <div className="d-flex justify-content-between  gap-4">
            <Skeleton height={50} width={50} borderRadius={50} />
            <div>
              <Skeleton height={10} width={250} />
              <Skeleton height={10} width={350} />
            </div>
          </div>

          <Skeleton height={40} width={150} borderRadius={50} />
        </div>

        <h4 className="fs-3 fw-fw-semibold text-black px-2 px-md-0 mb-5">
          <Skeleton height={30} width={450} />
        </h4>
        <h5 className="fs-5 fw-fw-normal text-black px-2 px-md-0 my-md-5 pt-5 pb-3 pt-md-0 pb-md-0">
          <Skeleton height={20} width={300} />
        </h5>
        <p className="mt-10">
          <Skeleton count={20} />
        </p>
      </div>
    </div>
  );
};

export default BlogDetailCardSkeleton;
