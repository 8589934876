"use client";
import {
  AMAZONE_MEDIA_URL,
  defaultproduct,
  ThumbImageRatio,
} from "@/utils/constant";
import { FeaturedProductDataProps } from "@/utils/types";
import { useRouter } from "@/navigation";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

export default function FeatureProductsSection({
  title,
  heading,
  data,
}: FeaturedProductDataProps) {
  const router = useRouter();
  const handleData = (slug: any) => {
    router.replace(`/item-detail/${slug}`);
  };
  const [loading, setLoading] = useState(true);
useEffect(() => {
  if (data) {
    setLoading(false);
  }
}, [data]);

  return (
    <div className="overflow-hidden">
      <h3 className="text-black fs-5 fw-normal mb-4">
        {" "}
        <span className="text-purple"> {title}</span> {heading}
      </h3>
      <div className="row featured-product-section rounded p-4 g-3">
        {!loading ? (data?.map((item: any, index: number) => (
          <div
            key={index}
            className="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-6 featured-product-card"
            onClick={() => handleData(item.slug)}
          >
            <img
              loading="lazy"
              className="bg-gray custom-market-cards cursor-pointer rounded"
              alt="image"
              src={
                item.media_path
                  ? AMAZONE_MEDIA_URL +
                    "User/" +
                    item.supplier_id +
                    "/Product/" +
                    item.id +
                    "/" +
                    ThumbImageRatio +
                    "/" +
                    item.media_path
                  : defaultproduct
              }
              width={"100%"}
              height={"auto"}
            />
            <p className="fs-6 text-truncate mt-2 mb-0">{item.title}</p>
          </div>
        ))):(
          Array.from({ length: 3 }, (_, index) => (
            <div
            key={index}
            className="col-xl-3 col-lg-4 col-md-4 mx-2 col-sm-6 col-6 featured-product-card"
          >
              <Skeleton height={140} width={140} borderRadius={10}/>
            <p className="fs-6 mt-2 mb-0">
              <Skeleton height={10} width={90}/>
            </p>
          </div>
          ))
        )}
      </div>
    </div>
  );
}
