"use client";
import {Link} from "@/navigation";
import { MAINDOMAINURL, PROTOCOL } from "@/utils/constant";
import { AccountSelectionCardProps } from "@/utils/types";
import { useEffect, useState } from "react";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";
import { getCookie } from "@/utils/helpers";
import { useSettingsContext } from "@/context";
import { useTranslations } from "next-intl";


function AccountSelectionCard({ item }: AccountSelectionCardProps) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [type, setType] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const { currentLocale } = useSettingsContext();
  const getStartedText = useTranslations("getStarted")
  const activeLang = currentLocale || "en";
  useEffect(() => {
    if (item) {
      setLoading(false);
    }
  }, [item]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      setType(urlParams.get("type"));
    }
  }, []);
  const getSignUpLink = () => {
    if (type === "create-workspace") {
      const redirectParams = {
        type: item.heading,
      };

      return `/new-workspace?${new URLSearchParams(redirectParams)}`;
    } else {
      if (item.heading === "Buyer") {
        return "/sign-up-buyer";
      } else if (item.heading === "Supplier") {
        return "/sign-up-supplier";
      } else {
        return "/signup-expert";
      }
    }
  };

  const style = {
    borderLeft:
      item?.heading === "Buyer"
        ? "5px solid #C875C4"
        : item?.heading === "Supplier"
        ? "5px solid #358B9E"
        : "5px solid green",
  };
  return (
    <>
    {!isMobile ? (!loading ? ( <div className="col-md-4 my-sm-5 position-relative">
    <Link
        href={getSignUpLink()}
        style={{ textDecoration: "none" }}
        rel="noopener noreferrer"
      >
        <div className="account-selection-card rounded-4 p-lg-5 p-3 bg-white m-auto">
          <img
            loading="lazy"
            alt="image"
            src={item.image}
            width={260}
            height={180}
            className="w-100"
          />
          <p className="fs-6 fw-light text-black mt-5">{getStartedText("register_as")}</p>
          <h4 className="fs-4 fw-bold text-black">{item.heading}</h4>
          <p className="fs-6 fw-light text-black px-1">{item.content}</p>
        </div>
      </Link>
    </div> ):(
      <div className="col-lg-4 col-md-4 col-sm-6 my-sm-5 position-relative">
      <div className="account-selection-card rounded-4 p-5 bg-white m-auto">
      <Skeleton height={100} width={100} borderRadius={50}/>
        <p className="fs-6 fw-light text-black mt-5">
      <Skeleton height={10} width={100}/>
          
          </p>
        <h4 className="fs-4 fw-bold text-black">
      <Skeleton height={25} width={130}/>
        </h4>
        <p className="fs-6 fw-light text-black px-1">
      <Skeleton height={20} count={3}/>
        </p>
      </div>
    </div>
    ) 
    ) :
      (
        !loading ? 
        (<Link
          href={getSignUpLink()}
          style={{ textDecoration: "none" }}
          rel="noopener noreferrer"
        >
          <div
            style={style}
            className="account-selection-card-mobile p-2 bg-white m-auto"
          >
            <div className="row">
              <div className="col-8 text-start ps-3">
                <h5>{item.heading}</h5>
                <p style={{ fontSize: "14px",lineHeight:  "1.3"}}>{item.content}</p>
              </div>
              <div className="col-4">
                <img
                  loading="lazy"
                  alt="image"
                  src={item.image}
                  width={260}
                  height={90}
                  className="w-100"
                />
              </div>
            </div>
          </div>
        </Link>)
       :
       (
        <div
        >
          <div
            style={style}
            className="account-selection-card-mobile p-2 bg-white m-auto"
          >
            <div className="row">
              <div className="col-8 text-start ps-3">
                <h5><Skeleton/></h5>
                <p>
                <Skeleton count={3}/>
                </p>
              </div>
              <div className="col-4">
              <Skeleton height={80} width={80} borderRadius={50}/>
              </div>
            </div>
          </div>
        </div>
      )  )
      }
        </>
  );
}

export default AccountSelectionCard;
