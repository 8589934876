'use client'
import { SupplierCompaniesCard, TopBrandsCard } from "@/components/common";
import {
  NetworkImgSection,
  ExpertCardSection,
  PopularSupplierSection,
  NetworkTabSection,
} from "@/components/widgets";
import { Button } from "@/components/bootstrap";
import Slider from "@/components/slick";
import {
  CategoryData,
  MarketplaceImageSectionDataProps,
  popularSupplierBuyer,
  topBrands,
} from "@/utils/types";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useTranslations } from "next-intl";
import { useSettingsContext } from "@/context";
import { Link } from "@/navigation";

type props = {
  popularSupplier: popularSupplierBuyer[];
  supplierCategories: CategoryData[];
  topBrands: topBrands[];
  expertData: any;
  supplierData: any;
  bannerData: any;
  handleTabChange: any;
};

const DiscoverAllLayout = ({
  popularSupplier,
  supplierCategories,
  topBrands,
  expertData,
  supplierData,
  bannerData,
  handleTabChange,
}: props) => {
  const networkTabText = useTranslations("networkTabText")
  const { onUpdate } = useSettingsContext();
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  const [expertLoading, setExpertLoading] = useState(true);
  const [bannerLoading, setBannerLoading] = useState(true);
  const [topBrandsLoading, setTopBrandsLoading] = useState(true);

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };

  const brandSetting = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 6,
    slidesToScroll: 6,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };
  useEffect(() => {
    if (supplierData) {
      setLoading(false);
    }
  }, [supplierData]);
  useEffect(() => {
    if (expertData) {
      setExpertLoading(false);
    }
  }, [expertData]);
  useEffect(() => {
    if (bannerData) {
      setBannerLoading(false);
    }
  }, [bannerData]);
  useEffect(() => {
    if (topBrands) {
      setTopBrandsLoading(false);
    }
  }, [topBrands]);
  return (
    <>
      {
      !loading ? 
         (supplierData && supplierData.length > 0 && (
        <div className="my-5 px-md-5">
          <div className="d-md-flex justify-content-between align-items-center text-md-end text-center bn-section-m">
            <h3 className="main-heading text-black fs-5 fw-normal">
              <span className="fw-bold">{networkTabText("discover")} </span> {networkTabText("the_trusted_network")}
            </h3>
            <Button
              variant="light bg-white"
              className="text-black rounded-5 border border-dark border-1 custom-network-button px-3"
              size="sm"
              onClick={() => {handleTabChange("suppliers")
              onUpdate("currentTab", "Supplier");
              }}
            >
              {networkTabText("view_all")}
            </Button>
          </div>
          <NetworkImgSection supplierData={supplierData} />
        </div>
      ))
      :
      (
        <div className="my-5 px-md-5">
        <div className="d-md-flex justify-content-between align-items-center text-md-end text-center bn-section-m">
          <h3 className="main-heading text-black fs-5 fw-normal">
            <Skeleton height={20} width={290} />
          </h3>
          <Skeleton height={40} width={90} borderRadius={70}/>
        </div>
        <NetworkImgSection supplierData={supplierData} />
      </div>
      )
      }

      <div className="expert-carousel-wrapper my-5 px-md-5">
      {!expertLoading ? ( <div className="d-md-flex justify-content-between align-items-center text-md-end text-center bn-section-m">
          <h3 className="text-black fs-5 fw-normal">
            <span className="fw-bold">{networkTabText("ask")} </span> {networkTabText("an_expert_for_your_business")}
          </h3>
          <Button
            variant="light bg-white"
            className="text-black rounded-5 border border-dark border-1 custom-network-button px-3"
            size="sm"
            onClick={() => {handleTabChange("experts")
              onUpdate("currentTab", "Experts");
            }}
          >
            {networkTabText("view_all")}
          </Button>
        </div>)
      :
      (
        <div className="d-md-flex justify-content-between align-items-center text-md-end text-center bn-section-m">
          <h3 className="text-black fs-5 fw-normal">
          <Skeleton height={20} width={290} />
          </h3>
          <Skeleton height={40} width={90} borderRadius={70}/>
        </div>
      )  
      }
        <ExpertCardSection expertData={expertData} />
      </div>

      <div className="px-md-5 px-2 my-md-5">
        <Slider {...settings}>
          {!bannerLoading ? (bannerData &&
            bannerData?.items?.length &&
            bannerData.items.map((item: MarketplaceImageSectionDataProps) => (
              <div className="" key={item.id}>
                <div
                  className={`network-banner rounded-5 p-5 border border-dark`}
                  style={{
                    backgroundImage: `url(${item.imageUrl})`,
                    height: "250px",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    opacity: 1,
                  }}
                >
                  <h3 className="pr-10 text-white">{item.title}</h3>
                  <h3 className="pr-10 text-white">{item.headline}</h3>
                  <Link
                    href="/get-started"
                    className="d-inline-block text-white rounded-5 border border-white border-1 my-3 py-3 px-4"
                  >
                    {item.cta?.label}
                  </Link>
                </div>
              </div>
            )))
          :
          (
            Array.from({ length: 4 }, (_, index) => (
             <Skeleton borderRadius={50} height={220} key={index} className="mx-2 mt-3" />
            ))
          )
          }
        </Slider>
      </div>

      <div className="my-md-8 px-md-5 my-2 px-3">
        <div className="row p-0">
          <div className={`${isMobile ? "col-md-2 text-center" : "col-md-2"}`}>
            <h5 className="fs-5 fw-normal mt-5">
            {networkTabText("few_popular")}
               <span className="fw-bold">
            {networkTabText("suppliers")}
               </span>
            </h5>
            <h6 className="fs-6 fw-light">
            {networkTabText("explore_all")}
            </h6>
          </div>
          <div className="col-md-10">
            <PopularSupplierSection data={popularSupplier} type="Supplier" />
          </div>
        </div>
      </div>

      <div className="my-md-5 my-5 px-lg-5 px-3">
        <SupplierCompaniesCard
          title={networkTabText("supplier_companies")}
          supplierData={supplierData}
        />
      </div>

      <div className="my-md-5 px-md-5 my-2 px-3">
        <div className="row d-flex align-items-center">
          <div className="col-md-3 px-4 ">
            <h2 className="fs-5 fw-normal mt-5">{networkTabText("Suppliers_by")}</h2>
            <h2 className="fs-4 fw-bold">{networkTabText("Category")}</h2>
          </div>
          <div className="col-md-8">
            <NetworkTabSection
              supplierCategories={supplierCategories}
              pageType="network page"
            />
          </div>
        </div>
      </div>

      <div className="bg-white p-4">
        <h3 className="main-heading text-black fs-5 fw-bold m-0 mb-4">
          {networkTabText("top_brands")} 
        </h3>
        <div className="row">
          <div className="col">
            <Slider {...brandSetting}>
              {!topBrandsLoading ? topBrands.map((item: any, index) => (
                <TopBrandsCard
                  key={item.product_brand_id}
                  image={item.product_brand_logo}
                />
              )):
              (
                Array.from({ length: 10 }, (_, index) => (
                <div key={index}>
                <div
                  className="d-flex justify-content-center align-items-center"
                  style={{ height: "100%" }}
                >
                  <Skeleton height={70} width={80}/>
                </div>
              </div>
                ))
              )
              }
            </Slider>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscoverAllLayout;
