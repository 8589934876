"use client";
import React, { useState } from "react";
import { ExpertImgCard, ExpertLoginSignupModal } from "../common";
import { Button } from "@/components/bootstrap";
import Slider from "@/components/slick";

const SuggestedExpertSection = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [expertData, setExpertData] = useState<{}>({});
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };
  const hireModalShow = ({ id, image, firstName, lastName }: any) => {
    setModalShow(true);
    setExpertData({
      user_id: id,
      profile_picture_path: image,
      name: firstName + " " + lastName,
    });
  };
  return (
    <>
      <div className="d-md-flex justify-content-between align-items-center px-5">
        <h3 className="main-heading text-black fs-5 fw-normal">
          <span className="fw-bold">Ask </span> An Expert For Your Business
        </h3>
        <Button
          href="#"
          variant="light bg-white"
          className="text-black rounded-5 border border-dark border-1 custom-network-button px-3"
          size="sm"
        >
          View All
        </Button>
      </div>
      <div className="services-container">
        {data?.data?.slice(0, 5).length < 4 ? (
          data?.data?.slice(0, 5).map((item: any, index: number) => {
            return (
              <div className="row">
                <div className="col-md-3">
                  <ExpertImgCard
                    key={index}
                    id={item.expert_id}
                    image={item.profile_picture_path}
                    firstName={item.first_name}
                    lastName={item.last_name}
                    hireModalShow={hireModalShow}
                  />
                </div>
              </div>
            );
          })
        ) : (
          <div className="row justify-content-center gap-4">
            <Slider {...settings}>
              {data?.data?.slice(0, 5).map((item: any, index: number) => {
                return (
                  <ExpertImgCard
                    key={index}
                    id={item.expert_id}
                    image={item.profile_picture_path}
                    firstName={item.first_name}
                    lastName={item.last_name}
                    hireModalShow={hireModalShow}
                  />
                );
              })}
            </Slider>
          </div>
        )}
      </div>
      <ExpertLoginSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={expertData}
      />
    </>
  );
};

export default SuggestedExpertSection;
