"use client";
import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const CategoryProductModal = ({
  productCategories,
  setSelectedCategory,
  show,
  setCategoryModal,
  setSelectCategoryName
}: any) => {
  const [selectCategoryId, setSelectCategoryId] = useState<number>();
  const [currentCategoryName, setCurrentCategoryName] = useState<string>("");

  const selectCategory = () => {
    setSelectedCategory(selectCategoryId);
    setSelectCategoryName(currentCategoryName);
    setCategoryModal(false);
  };

  const isSelected = (categoryId: number) => {
    return categoryId === selectCategoryId;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => setCategoryModal(false)}
        size="sm"
        className="category-modal"
      >
        <Modal.Header className="custom-modal-header" closeButton>
          <Modal.Title style={{ fontSize: "1.625rem" }}>
            Choose Category
          </Modal.Title>
        </Modal.Header>

        <Modal.Body className="custom-modal-body px-4">
          {productCategories.map((category: any) => (
            <div
              key={category.product_category_id}
              onClick={() => {
                setSelectCategoryId(category.product_category_id);
                setCurrentCategoryName(category.product_category_name)
              }}
              className={`custom-dropdown-item p-2 cursor-pointer ${
                isSelected(category.product_category_id)
                  ? "selected-dropdown-item"
                  : ""
              }`}
            >
              {category.product_category_name}
            </div>
          ))}
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-center custom-modal-footer">
          <Button
            variant="secondary"
            className="text-white rounded-5 px-4"
            onClick={() => selectCategory()}
          >
            Choose
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategoryProductModal;
