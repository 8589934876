"use client";
import { Formik, Field, Form } from "formik";
import { SearchIcon } from "@/utils/icons";
import * as Yup from "yup";
import { Container, Navbar, Offcanvas } from "@/components/bootstrap";
import StarRatings from "react-star-ratings";
import { useEffect, useState } from "react";
import { useSettingsContext } from "@/context";
import { useTranslations } from "next-intl";

function BussinessNetworkSidebar({
  onSearch,
  changeRating,
  categoryData,
  selectedCategories,
  handleCategoryCheck,
  handleIsFeatured,
  handleIsBookMarked,
  count,
}: any) {
  const networkTabText = useTranslations("networkTabText")
  const { searchTerm } = useSettingsContext();
  const [initialValues, setInitialValues] = useState({
    searchQuery: "",
  });
  const [rating, setRating] = useState(0);
  const [isChecked, setIsChecked] = useState(false);
  const [isBookMarked, setIsBookMarked] = useState(false);
  const searchValidationSchema = Yup.object({
    searchQuery: Yup.string(),
  });

  useEffect(() => {
    if (searchTerm !== "" && searchTerm !== undefined) {
      setInitialValues({
        searchQuery: searchTerm,
      });
      onSearch(searchTerm);
    }
  }, []);

  let debounceTimeout: string | number | NodeJS.Timeout | undefined;

  const debounceSearch = async (event: { target: any }) => {
    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    debounceTimeout = setTimeout(() => {
      onSearch(event.target.value);
    }, 1000);
  };

  const handleChangeRating = (newRating: any) => {
    setRating(newRating);
    changeRating(newRating);
  };
  const enableFeature = (feature: any) => {
    setIsChecked(!isChecked);
    handleIsFeatured(!isChecked);
  };
  const enableBookmarked = (feature: any) => {
    setIsBookMarked(!isBookMarked);
    handleIsBookMarked(!isBookMarked);
  };
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      style={{ alignItems: "flex-start" }}
    >
      <Container>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="ms-auto border-none"
        >
          <div className="p-1">
            <SearchIcon />
          </div>
        </Navbar.Toggle>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-sm`}
            aria-labelledby={`offcanvasNavbarLabel-expand-sm`}
            placement="end"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand-sm`} />
            </Offcanvas.Header>
            <Offcanvas.Body>
              <div className="bg-white px-3">
                <Formik
                  initialValues={initialValues}
                  validationSchema={searchValidationSchema}
                  onSubmit={() => {}}
                  enableReinitialize
                >
                  {({ setFieldValue }) => {
                    return (
                      <Form>
                        <div className="form-group my-5">
                          <Field
                            type="text"
                            id="searchQuery"
                            name="searchQuery"
                            className="form-control rounded-5 form-control-lg custom-search-input"
                            placeholder={networkTabText("search_by_name_category")}
                            onChange={(e: { target: { value: any } }) => {
                              setFieldValue("searchQuery", e.target.value);
                              debounceSearch(e);
                            }}
                          />
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                <div className="d-flex justify-content-between">
                  <p className="fw-bold fs-6">{networkTabText("categories")}</p>
                  <SearchIcon />
                </div>
                <div className="category-list">
                  <div className="category-scroll">
                    {categoryData &&
                      categoryData.map((item: any) => (
                        <div
                          key={item.industry_id}
                          className="d-flex align-items-center my-2 me-2"
                        >
                          <label
                            className="fw-light pe-3"
                            style={{ fontSize: "14px", width: "179px" }}
                          >
                            {item.industry_name}
                          </label>
                          <input
                            type="checkbox"
                            className="custom-checkbox cursor-pointer"
                            checked={selectedCategories.includes(
                              item.industry_id
                            )}
                            onChange={() =>
                              handleCategoryCheck(item.industry_id)
                            }
                          />
                        </div>
                      ))}
                  </div>
                </div>
                {/* <div className="d-flex justify-content-between align-items-center form-switch my-2 mb-1">
                  <label className="form-check-label mb-0 fw-bold fs-6">
                    Bookmark
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={isBookMarked}
                    onChange={enableBookmarked}
                  />
                </div> */}
                <div className="d-flex justify-content-between align-items-center form-switch my-2">
                  <label className="form-check-label mb-0 fw-bold fs-6">
                    {networkTabText("featured")}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={isChecked}
                    onChange={enableFeature}
                  />
                </div>
                {count && (
                  <span className="fw-light">
                    {isChecked
                      ? count?.featured_supplier_count
                      : count?.un_featured_supplier_count}
                    <span className="ms-2">Supplier</span>
                  </span>
                )}
                <p className="fw-bold fs-6 mt-3">
                  {networkTabText("with_special_offer")}
                  <br />
                  <span className="fw-light">2 supplier </span>
                </p>
                <hr />
                <p className="fw-normal fs-6 my-2">
                {networkTabText("rating")}
                </p>
                <div>
                  <StarRatings
                    rating={rating}
                    starRatedColor="gray"
                    numberOfStars={5}
                    starDimension="22px"
                    starSpacing="0px"
                    starHoverColor="black"
                    changeRating={handleChangeRating}
                  />
                </div>
                <hr />
                {/* <p className="fw-normal fs-6 my-2">Distance</p> */}
              </div>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BussinessNetworkSidebar;
