import Skeleton from "react-loading-skeleton";

function ExpertCardSectionSkeleton() {
  return (
    <div className="card text-center">
          <Skeleton height={100} width={100} borderRadius={50}/>
          <Skeleton height={20} width={80}/>
         <Skeleton height={40} width={130}  borderRadius={50}/>
    </div>
  );
}

export default ExpertCardSectionSkeleton;
