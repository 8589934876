"use client";
import React, { useState } from "react";
import { Modal } from "@/components/bootstrap";
import { BsXLg } from "react-icons/bs";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
} from "react-share";
import { FaFacebook, FaTwitter, FaLinkedin } from "react-icons/fa";
import { useTranslations } from "next-intl";

interface ShowLoginModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  url: string;
  isIcon: boolean
  iconName: JSX.Element
}

const CopyLinkModal: React.FC<ShowLoginModalProps> = ({
  show,
  onHide,
  title,
  url,
  isIcon,
  iconName
}) => {
  const shareModalText = useTranslations("bookmarkModel")
  const [copy, setCopy] = useState<boolean>(false);
  const copyToClipboard = () => {
    if (url) {
      navigator.clipboard
        .writeText(url)
        .then(() => {
          setCopy(true);
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="text-center p-3">
        <div
          className="d-flex justify-content-end  align-items-center cursor-pointer"
          onClick={onHide}
        >
          <BsXLg size={30} />
        </div>
        {isIcon && <h4 className="text-secondary">{iconName}</h4>}
        <h4 className="text-secondary">{title}</h4>
        <div className="m-4">
          <div className="border-link">
            <p className="fs-7">{url}</p>
            <button
              className={`${copy ? "copy-text" : "copy"}`}
              disabled={copy}
              onClick={copyToClipboard}
            >
              {copy ? "Copied" : "Copy Link"}
            </button>
          </div>
          <div className="share-link">
            <p className="mb-0">
          {shareModalText("share-product-via")}
            </p>
            <div className="social-share-buttons">
              <FacebookShareButton url={url} className="share-button mx-2">
                <FaFacebook size={28} className="facebook-link" />
              </FacebookShareButton>

              <TwitterShareButton url={url} className="share-button mx-2">
                <FaTwitter size={28} className="twitter-link" />
              </TwitterShareButton>

              <LinkedinShareButton url={url} className="share-button mx-2">
                <FaLinkedin size={28} className="linkedin-link" />
              </LinkedinShareButton>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default CopyLinkModal;
