"use client";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { SupplierBuyerNetworkProps } from "@/utils/types";
import { useRouter } from "@/navigation";
import { Button } from "@/components/bootstrap";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useTranslations } from "next-intl";
const SupplierBuyerNetworkCard = ({
  id,
  type,
  data,
  slug,
}: SupplierBuyerNetworkProps) => {
  const {
    business_logo,
    supplier_id,
    supplier_name,
    business_name,
    distance,
    location,
    industry_name,
    buyer_id,
  } = data;

  const router = useRouter();

  const handleData = (slug: string) => {
    if (type === "Supplier") {
      router.push(`/supplier-profile/${slug}`);
    } else {
      router.push(`/buyer-profile/${slug}`);
    }
  };
  const networkTabText = useTranslations("networkTabText")

  return (
    <div
      className="supplier-network-card rounded-4 shadow bg-white overflow-hidden position-relative my-3 p-3 p-lg-3 cursor-pointer"
      onClick={() => handleData(slug)}
      style={{ minWidth: "300px" }}
    >
      <div className="d-flex align-items-center gap-3">
        <img
          loading="lazy"
          className="rounded-circle"
          alt="image"
          src={
            business_logo
              ? `${AMAZONE_MEDIA_URL}User/${
                  supplier_id ? supplier_id : buyer_id
                }/BusinessLogo/${business_logo}`
              : default_supplier_logo
          }
          width={68}
          height={68}
        />

        <div>
          <h4 className="main-heading fs-6 fw-normal supplier-content-color text-capitalize">
            {supplier_name ?? business_name}
          </h4>
          <h5 className="main-heading fs-7 text-muted fw-light">
            {distance} KM away
          </h5>
        </div>
      </div>
      <div className="my-sm-4 supplier-content">
        <p className="main-heading fs-7 fw-normal my-sm-2 supplier-content-color">
          Address
        </p>
        <p className="main-heading fs-7 fw-light my-sm-2 supplier-content-color">
          {location}
        </p>
        {/* <p className="main-heading fs-7 fw-light my-sm-2 supplier-content-color">
          {industry_name}
        </p> */}
      </div>

      <Button
        href="#"
        variant=""
        className="supplier-content-color fs-7 text-start rounded-5 my-sm-2 px-3"
        style={{ backgroundColor: "#F3F3FA" }}
        size="sm"
      >
        {industry_name}
      </Button>
      <div className="overlay" />

      <Button
        href="#"
        variant=""
        className="supplier-content-color rounded-5 my-sm-2 px-3 send-invite-button"
        size="sm"
      >
        {networkTabText("send_invite")}
      </Button>
    </div>
  );
};

export default SupplierBuyerNetworkCard;
