"use client";
import React, { useState } from "react";
import { Button } from "@/components/bootstrap";
import { CustomerFeedbackCard } from "../common";

function DetailsCustomerFeedback(supplier_reviews: any) {
  const [numReviewsDisplayed, setNumReviewsDisplayed] = useState(3);
  const handleViewMore = (event: any) => {
    event.preventDefault();
    setNumReviewsDisplayed((prevNum) => prevNum + 6);
  };
  return (
    <div className="bg-white px-0 px-md-5">
      <h3 className="main-heading text-black text-center fs-5 fw-bold">
        Here’s What Our Customers Say Orange Food Systems
      </h3>

      <div className="row my-sm-5 my-3">
        {supplier_reviews.supplier_reviews && 
          supplier_reviews.supplier_reviews
            .slice(0, numReviewsDisplayed)
            .map((review: any, index: any) => (
              <div className="col-md-4 mt-1" key={index}>
                <CustomerFeedbackCard
                  title={review.message}
                  author={review.buyer_name}
                  post="Buyer"
                />
              </div>
            ))
}
        {supplier_reviews.supplier_reviews &&
          supplier_reviews.supplier_reviews.length > numReviewsDisplayed && (
            <div className="col-md-12 mt-1">
              <div className="d-flex justify-content-center">
                <Button
                  href="#"
                  variant="dark"
                  className="text-white rounded-5"
                  size="lg"
                  onClick={handleViewMore}
                >
                  View More
                </Button>
              </div>
            </div>
          )}
      </div>
    </div>
  );
}

export default DetailsCustomerFeedback;
