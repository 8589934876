"use client";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { ProductsData } from "@/utils/types";
import { useRouter } from "@/navigation";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

type props = {
  newProducts: ProductsData[];
};

function CatelogueBox({ newProducts }: props) {
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const handleData = (slug: any) => {
    router.replace(`/item-detail/${slug}`);
  };
  useEffect(() => {
    if (newProducts) {
      setLoading(false);
    }
  }, [newProducts]);
  return (
    <div className="bg-white">
      <div className="row">
        {!loading ? (newProducts &&
          newProducts.map((item) => (
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 mb-3" key={item.id}>
              <img
                loading="lazy"
                className="rounded-5 custom-catelogue-box cursor-pointer"
                alt="image"
                src={
                  item.media_path
                    ? AMAZONE_MEDIA_URL +
                      "User/" +
                      item.supplier_id +
                      "/Product/" +
                      item.id +
                      "/" +
                      ThumbImageRatio +
                      "/" +
                      item.media_path
                    : defaultproduct
                }
                onClick={() => handleData(item.slug)}
              />
            </div>
          ))):(
            Array.from({ length: 6 }, (_, index) => (
            
            <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 mb-3" key={index}>
              <Skeleton height={190} borderRadius={10}/>
          </div>
          ))
          )}
      </div>
    </div>
  );
}

export default CatelogueBox;
