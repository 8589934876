"use client";
import { useEffect, useState } from "react";
import { MarketCard } from "../common";
import Slider from "@/components/slick";
import Skeleton from "react-loading-skeleton";

function MarketCardSection({ data }: any) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          className: "center",
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: "0px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          centerPadding: "0",
        },
      },
    ],
  };
  return (
    <>
      <Slider {...settings}>
        {!loading ? (data &&
          data.map((item: any, index: number) => (
            <div className="col-md-3" key={index}>
              <MarketCard
                id={item.user_id}
                image={item?.business_logo}
                title={item?.business_name}
                slug={item?.slug}
                media={item?.media_path}
                product_id={item?.product_id}
              />
            </div>
          ))):(
            Array.from({ length: 11 }, (_, index) => (
              <div className="col-md-3" key={index}>
                <Skeleton height={350} borderRadius={20}/>
                <Skeleton count={2} width={90}/>
                </div>
            ))
          )}
      </Slider>
    </>
  );
}

export default MarketCardSection;
