'use client'
import { Button } from "@/components/bootstrap";
import { CallToActionSimpleProps } from "@/utils/types";
import { useEffect, useState } from "react";
import CallToActionSimpleSkeleton from "../skeleton/call-to-action-simple-skeleton";
import { Link } from "@/navigation";

function CallToActionSimple({
  heading,
  subHeading,
  buttonText,
  buttonLink,
  bgColor,
}: CallToActionSimpleProps) {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [])
  return (
    <>
    {
      !loading ? (
        <div
        className={`call-to-action-simple d-flex justify-content-between align-items-center flex-column p-4 p-md-5 p-md-8 p-lg-11`}
        style={{ background: !!bgColor ? bgColor : "" }}
      >
        {heading}
        {subHeading}
  
        <Link
          href={buttonLink}
          className="text-white rounded-5 text-decoration-none custom-cta-btn my-3 py-3 px-5"
        >
          {buttonText}
        </Link>
      </div>
      ) : (
        <CallToActionSimpleSkeleton />
      )
    }
    </>

  );
}

export default CallToActionSimple;
