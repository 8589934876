import { defaultproduct } from "@/utils/constant";

function TopBrandsCard({ key, image }: any) {
  return (
    <div key={key}>
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <img
          loading="lazy"
          src={image ? image : defaultproduct}
          alt=""
          width={100}
          height={90}
        />
      </div>
    </div>
  );
}

export default TopBrandsCard;
