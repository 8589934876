"use client";
import React, { useCallback, useEffect, useState } from "react";
import {
  GoogleMap,
  Marker,
  useJsApiLoader,
  InfoWindow,
} from "@react-google-maps/api";
import { mapStyles } from "@/utils/data";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL, DOMAINNAME, PROTOCOL } from "@/utils/constant";
import { EllipsisIcon, ShareProfile, WeChat, AddUser,CloseIcon } from "@/utils/icons";
import { Button } from "react-bootstrap";
import { useRouter } from "@/navigation";
import { motion } from "framer-motion";
import ShowLoginModal from "./modal-login";
import CopyLinkModal from "./copy-link-modal";
import { getCookie } from "@/utils/helpers";
import { BsFillShareFill } from "react-icons/bs";
import { useSettingsContext } from "@/context";

// Define the interface for location data
interface Location {
  lat: number;
  lng: number;
  title?: string;
  description?: string;
  logo?: string;
  industry_name?: string;
  url?: string;
  distance?: string;
}
interface DropDownOption {
  title: string;
  icon: JSX.Element; // Specify that the icon is a React element
}
function BussinessMapView({ networkData, view, type }: any) {
  const router = useRouter();
  const [hoveredLocation, setHoveredLocation] = useState<Location | null>(null);
  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [selectDropDown, setSelectDropDown] = useState<DropDownOption>();
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [center, setCenter] = useState({ lat: 23.8859, lng: 45.0792 });
  const [showLink, setShowLink] = useState<boolean>(false);
  const [hostUrl, setHostUrl] = useState<string>("");
  const { currentLocale } = useSettingsContext();
  const { isLoaded: apiLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAVo6ysFs5z0UEVVPtLns6lr3DCi6ZTXWc",
  });
  useEffect(() => {
    if (apiLoaded) {
      setIsLoaded(true);
    }
  }, [apiLoaded]);


  const locations: Location[] = networkData.map((data: any) => ({
    lat: data?.latitude,
    lng: data?.longitude,
    title:
      type === "Supplier"
        ? data?.supplier_name
        : type === "Buyer"
        ? data?.business_name
        : `${data?.first_name} ${data?.last_name}`,
    description: data?.industry_name + " at " + data?.location,
    logo: data?.business_logo
      ? `${AMAZONE_MEDIA_URL}User/${data?.supplier_id}/BusinessLogo/${data?.business_logo}`
      : default_supplier_logo,
    industry_name: data?.industry_name,
    url: data?.slug,
    distance: data?.distance
  }));

  const handleDropdownClick = (option: DropDownOption,slug:string) => {
    const updatedOption = { ...option, slug };
    setSelectDropDown(updatedOption);
    if(option.title==='Share Profile'){
      if (typeof window !== "undefined") {
        const currentType =
          type === "Supplier"
            ? "supplier-profile"
            : type === "Buyer"
            ? "buyer-profile"
            : "expert-profile";
        const activeLang = currentLocale || "en";
        const url = `${window.location.protocol + PROTOCOL + DOMAINNAME}/${activeLang}/${currentType}/${
          slug
        }`;
        setHostUrl(url);
      }
      setShowLink(true)
    }else{
      setModalShow(true);
    }
  };
  const dropDownOption: DropDownOption[] = [
    { title: "Send Invite", icon: <AddUser /> },
    { title: "Chat Now", icon: <WeChat /> },
    { title: "Share Profile", icon: <ShareProfile /> },
  ];
  const handleMarkerClick = useCallback((location: Location) => {
    setHoveredLocation(location);
    setCenter({ lat: location.lat, lng: location.lng });
  }, []);
  return (
    <div className="my-sm-2">
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={
            isMobile
              ? {
                  width: "100%",
                  height: "520px",
                }
              : view === "combineView"
              ? {
                  width: "650px",
                  height: "520px",
                }
              : view === "mapView"
              ? {
                  width: "1000px",
                  height: "520px",
                }
              : {
                  width: "100%",
                  maxWidth: "100%",
                  height: "520px",
                }
          }
          center={center}
          zoom={6}
          options={{
            styles: mapStyles,
          }}
          onClick={() => {setHoveredLocation(null),setDropdownVisible(false)}}
        >
          {locations.map((location, index) => (
            <Marker
              key={index}
              position={{ lat: location?.lat, lng: location?.lng }}
              title={location?.title}
              onClick={() => {handleMarkerClick(location)
                setDropdownVisible(false)
              }}
            />
          ))}

          {hoveredLocation && (
            <InfoWindow
              position={{ lat: hoveredLocation?.lat, lng: hoveredLocation?.lng }}
              options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
              onCloseClick={() => {setHoveredLocation(null);
                setDropdownVisible(false)
              }}
            >
              <motion.div
                className="col-md-12 pt-3 pb-1 w-100"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                onClick={(e) => e.stopPropagation()}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                    <div className="supplier-img position-relative">
                      <img
                        loading="lazy"
                        className="rounded-circle border"
                        alt="image"
                        src={hoveredLocation?.logo}
                        width={48}
                        height={48}
                      />
                    </div>
                  </div>
                  <div className="position-relative">
                    <button
                      className="ellipses"
                      onClick={() => setDropdownVisible(!dropdownVisible)}
                    >
                      {!dropdownVisible ? <EllipsisIcon /> : <CloseIcon/>}
                    </button>
                    {dropdownVisible && (
                      <>
                        <motion.div
                          className="position-absolute"
                          style={{ top: "0", right: "30px" }}
                          initial={{ opacity: 0, y: -20 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -20 }}
                          transition={{ duration: 0.3 }}
                        >
                          <ul className="list-unstyled bg-white shadow rounded p-2">
                            {dropDownOption.map((option, index) => (
                              <li
                                key={index}
                                className="dropdown-item py-1 px-2 cursor-pointer fw-semibold  d-flex justify-content-start gap-2 align-items-center"
                                onClick={() => handleDropdownClick(option,hoveredLocation?.url || '')}
                              >
                                <span>{option?.icon}</span>{" "}
                                <span>{option?.title}</span>
                              </li>
                            ))}
                          </ul>
                        </motion.div>
                        <ShowLoginModal
                          show={modalShow}
                          onHide={() => setModalShow(false)}
                          data={selectDropDown}
                        />
                        <CopyLinkModal
                        show={showLink}
                        onHide={() => setShowLink(false)}
                        title={'Share Profile'}
                        url={hostUrl}
                        isIcon={false}
                        iconName={<BsFillShareFill size={40} />}
                        />

                      </>
                    )}
                  </div>
                </div>
                <h6 className="text-capitalize fs-6 mt-2 mb-1">
                  {hoveredLocation?.title}
                </h6>
                <p className="fw-normal my-2 fs-7">
                  {hoveredLocation?.industry_name}
                </p>
                <p className="main-heading bg-gray p-2 rounded-2 main-heading-tablet text-black my-2 fs-7 fw-bold">
                  {`${hoveredLocation?.distance} KM away`} 
                </p>
                <Button
                  variant="secondary"
                  className="text-white rounded-5 fw-semibold fs-7 px-4"
                  size="sm"
                  onClick={() => {
                    if (type === "Supplier") {
                      router.push(`/supplier-profile/${hoveredLocation?.url}`);
                    } else if (type === "Buyer") {
                      router.push(`/buyer-profile/${hoveredLocation?.url}`);
                    } else {
                      const encrypt = encodeURIComponent(
                        hoveredLocation?.url ?? ""
                      );
                      router.push(`/expert-profile/${encrypt}`);
                    }
                  }}
                >
                  View
                </Button>
              </motion.div>
            </InfoWindow>
          )}
        </GoogleMap>
      )}
    </div>
  );
}

export default React.memo(BussinessMapView);
