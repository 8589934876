"use client";
import React, { useEffect, useState } from "react";
import { profile_pic } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { Button } from "@/components/bootstrap";
import { printCurrency } from "@/utils/helpers";
import { LocationIcon } from "@/utils/icons";
import ExpertLoginSignupModal from "./expert-login-signup-modal";
import Skeleton from "react-loading-skeleton";
import { useSettingsContext } from "@/context";

const ExpertDetailsCard = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);
  const {onUpdate} = useSettingsContext()
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  function renderHTML(htmlString: string): React.ReactNode {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }
  return (
    <>
    {
      !loading ? (
        <div className="rounded-5 bg-white overflow-hidden position-relative my-3 text-center">
      <div
        className="expert-card-img"
        style={{
          backgroundImage: `url(${
            data.profile_picture_path
              ? `${AMAZONE_MEDIA_URL}User/${data.user_id}/Profile/${data.profile_picture_path}`
              : profile_pic
          })`,
          width: "100%",
          height: 107,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      ></div>
      <div className="position-relative" style={{ marginBottom: "70px" }}>
        <div className="position-absolute top-0 start-50 translate-middle">
          <img
            loading="lazy"
            className="rounded-circle border border-white"
            alt="image"
            src={
              data.profile_picture_path
                ? `${AMAZONE_MEDIA_URL}User/${data.user_id}/Profile/${data.profile_picture_path}`
                : profile_pic
            }
            width={103}
            height={103}
          />
        </div>
      </div>
      <div className="mb-4">
        <h4 className="main-heading expert-heading-color fs-5 fw-bold px-4">
          {data.name}
        </h4>

        <h4 className="main-heading expert-content-color fs-7 my-sm-2 fw-light px-5">
          {data.industry_name}
        </h4>
        <div className="px-4">
          <hr />
        </div>
        <h6 className="fw-normal expert-content-color fs-6 px-4">
          I am an expert in chemical industry, I am passionate about working
          with startups, small and medium enterprises. Hire me now and you won’t
          regret it .
        </h6>
        <h4 className="main-heading expert-heading-color fs-6 fw-bold px-4 py-2">
          {renderHTML(
            printCurrency(data.price_start_range) +
              "-" +
              printCurrency(data.price_end_range)
          )}
        </h4>
        <div className="fw-normal mx-4 px-2 py-2 my-2 expert-location d-flex justify-content-center align-items-center p-2 gap-2 text-start">
          <LocationIcon />
          <div>
            <h6
              className="fw-normal fs-7 expert-content-color"
              style={{ fontSize: "0.9rem !important" }}
            >
              {data.location}
            </h6>
            <h6 className="fw-light fs-7 expert-content-color">
              View Location
            </h6>
          </div>
        </div>
        <Button
          href="#"
          variant="secondary"
          className="text-color-white rounded-5 px-3 py-2 my-2"
          style={{ width: "250px" }}
          size="sm"
          onClick={() => {
            onUpdate("redirectionUrl", {
              path: "create RFI for expert",
              id: data?.user_id || ""
            });
            setModalShow(true)}}
        >
          Hire {data.name}
        </Button>

        <Button
          href="#"
          variant="outline-dark"
          className="rounded-5 px-3 py-2 my-2 expert-heading-color"
          size="sm"
          style={{ width: "250px" }}
          onClick={() => setModalShow(true)}
        >
          Connect
        </Button>
      </div>
    </div>
      ) : (
        <div className="rounded-5 bg-white overflow-hidden position-relative my-3 text-center">
             <Skeleton height={120}/>
      <div className="position-relative" style={{ marginBottom: "70px" }}>
        <div className="position-absolute top-0 start-50 translate-middle">
        <Skeleton height={90} width={90} borderRadius={50}/>
        </div>
      </div>
      <div className="mb-4">
        <h4 className="main-heading expert-heading-color fs-5 fw-bold px-4">
        <Skeleton height={20} width={90} />
        </h4>

        <h4 className="main-heading expert-content-color fs-7 my-sm-2 fw-light px-5">
        <Skeleton height={10} width={170} />
        <Skeleton height={10} width={150} />
        </h4>
        <div className="px-4">
          <hr />
        </div>
        <h6 className="fw-normal expert-content-color fs-6 px-4">
        <Skeleton height={10} count={5}/>
        </h6>
        <h4 className="main-heading expert-heading-color fs-6 fw-bold px-4 py-2">
        <Skeleton height={20} width={90} />
        </h4>
        <h6 className="fw-normal expert-content-color fs-6 px-4">
        <Skeleton height={70} />
        </h6>
        <h6 className="fw-normal expert-content-color fs-6 px-2">
        <Skeleton height={40} borderRadius={50} count={2} />
        </h6>
      </div>
    </div>
      )
    }
    <ExpertLoginSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
};

export default ExpertDetailsCard;
