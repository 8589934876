"use client";
import { FC, useEffect, useState } from "react";
import BlogMobileCard from "../common/blog-mobile-card";
import { stripHtmlTags } from "@/utils/helpers";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";

const BlogMobileSection: FC<any> = ({ item, blogs }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, []);
  return (
    <>
      <div className="row blog-mobile-section px-4 px-lg-5 w-md-100">
        {loading &&
          blogs.length &&
          Array.from({ length: item }, (_, index) => (
            <div key={index} className="col-md-4 my-sm-3">
              <div className="row cursor-pointer border-top border-bottom overflow-hidden mobile-blog-card">
                <div className="col-5 mobile-card">
                  <Skeleton height="100%" />
                </div>
                <div className="col-7 p-2" style={{ paddingLeft: "10px" }}>
                  <Skeleton height={40} />
                  <Skeleton height={80} count={1} />
                </div>
              </div>
            </div>
          ))}
        {!loading &&
          blogs?.slice(0, item).map((item: any, index: number) => {
            return (
              <BlogMobileCard
                key={index}
                heading={stripHtmlTags(item?.title)}
                content={stripHtmlTags(item?.headline)}
                image={item?.heroImg}
                slug={item.seoDetails?.slug}
              />
            );
          })}
      </div>
    </>
  );
};

export default BlogMobileSection;
