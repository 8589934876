import { CustomerFeedbackProps } from "@/utils/types";

function CustomerFeedbackCard({ title, author, post }: CustomerFeedbackProps) {
  return (
    <div className="rounded-5 shadow bg-white p-3 my-4">
      <p className="fw-light m-0 px-4 mt-5" style={{ minHeight: "120px" }}>
        {title}
      </p>
      <p className="fw-normal m-0 px-4 mt-3">{author}</p>
      <p className="fw-light m-0 px-4 mb-3">{post}</p>
    </div>
  );
}

export default CustomerFeedbackCard;
