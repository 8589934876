"use client";
import { HeroSectionItems } from "@/utils/types";
import {
  ProcurementSoftwareMobileIcon,
  MarketplaceMobileIcon,
  NetworkMobileIcon,
} from "@/utils/icons";

type serviceCardProps = {
  item: HeroSectionItems;
  type: string;
};

function MobileSecondaryServiceCard({ item, type }: serviceCardProps) {
  let iconComponent;
  if (item.title === "Procurement Software") {
    iconComponent = <ProcurementSoftwareMobileIcon />;
  } else if (item.title === "B2B Marketplace") {
    iconComponent = <MarketplaceMobileIcon />;
  } else if (item.title === "Business Network") {
    iconComponent = <NetworkMobileIcon />;
  }
  return (
    <div className="col-12 slide-up-box-mobile mt-4">
      <div className="simple-box-content p-4 position-relative">
        <div
          style={{
            content: "",
            position: "absolute",
            top: "50%",
            right: 0,
            width: 0,
            height: "90px",
            borderRight: `4px solid ${item.theme.backgroundColor}`,
            transform: "translateY(-50%)",
          }}
        ></div>
        <div className="row align-items-center ">
          <div className="col-3">
        <img src={item?.imageUrl} style={{maxWidth:"100%"}} width={`${item.title === "Business Network" ? "130" : item.title === "B2B Ecommerce Software" ? "93" : "100"}`}/>
          </div>
          <div className="col-9 text-start">
            <h4 className={`fs-6 fw-normal text-start`}>{item.title}</h4>
            <h5 className={`fs-6 fw-bold text-black text-start `}>
              {item.headline}
            </h5>
            <p className={`tag m-0 text-black text-start`}>{item.content}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSecondaryServiceCard;
