"use client";
import { BussinessCardProps } from "@/utils/types";
import { motion } from "framer-motion";

function BussinessCard({
  image,
  heading,
  content,
  subcontent,
  cardVariants,
}: BussinessCardProps) {
  return (
    <motion.div
      className="col-md-6 col-lg-3 col-sm-6 my-5"
      variants={cardVariants}
    >
      <div className="position-relative">
        <div className="text-center service-contact-card rounded-4 p-3 bg-white mx-md-0 mx-5">
          <div className="position-absolute top-0 start-50 translate-middle">
            <img loading="lazy" alt="" src={image} width={100} height="100%" />
          </div>
          <h4 className="main-heading text-black mt-11 fs-6 fw-normal px-4">
            {heading}
          </h4>
          <p className="fw-light m-0 px-4 pb-4" style={{ minHeight: "120px" }}>
            {content}
          </p>
          <h4 className="main-heading text-black mt-4 fs-6 fw-normal px-4 pb-4">
            {subcontent}
          </h4>
        </div>
      </div>
    </motion.div>
  );
}

export default BussinessCard;
