"use client";
import React, { useEffect, useState } from "react";
import { useRouter } from "@/navigation";
import { Button } from "@/components/bootstrap";
import { BenefitsCardProps } from "@/utils/types";
import { benefitCardPropsItems } from "@/utils/data";
import BenefitsCard from "@/components/common/benefits-card";
import IconCard from "@/components/common/icon-card";
import { stripHtmlTags } from "@/utils/helpers";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share";
import { FaFacebookF, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import BlogDetailCardSkeleton from "../skeleton/blog-detail-card-skeleton";

function BlogDetailsCard({ data }: any) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [currentUrl, setCurrentUrl] = useState<string>("");
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const dateFun = (createdAt: any) => {
    const date = new Date(createdAt);
    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: true,
    });
    return formattedDate;
  };
  useEffect(() => {
    setLoading(false)
    if (typeof window !== "undefined") {
      setCurrentUrl(window.location.href);
    }
  }, []);
  return (
    <div
      className={`container-fluid p-4 px-md-5 px-3 py-md-5 py-3 ${
        isMobile ? "bg-white" : "bg-body rounded-5"
      }`}
      style={{marginTop:"-140px"}}
    >
     
   {!loading ? (<div className="row">
        <div className="col-md-2 text-center mb-md-0 mb-4">
        <div className="social-share">
                <FacebookShareButton url={currentUrl} className="social-circle hover-facebook">
                  <FaFacebookF size={20} className="m-2" />
                </FacebookShareButton>

                <TwitterShareButton url={currentUrl} className="social-circle hover-twitter">
                  <FaTwitter size={20} className="m-2"/>
                </TwitterShareButton>

                <LinkedinShareButton url={currentUrl} className="social-circle hover-linkedin">
                  <FaLinkedinIn size={20} className="m-2" />
                </LinkedinShareButton>
              </div>
        </div>
        <div className="col-md-10">
        {data && data?.author != null && (
        <div className="d-flex justify-content-between mb-3 pb-4 ">
          <IconCard
            icon={data?.author?.profileImg}
            title={data?.author?.firstName + " " + data?.author.lastName}
            time={dateFun(data?.author?.createdAt)}
            id={data?.id}
            page={"blog-page"}
          />
          {!isMobile && (
            <Button
              type="button"
              variant="outline-dark"
              className="rounded-5"
              size="sm"
              onClick={() => router.replace(`/blog`)}
            >
              Back
            </Button>
          )}
        </div>
      )}
        <h4 className="fs-3 fw-fw-semibold text-black px-2 px-md-0 mb-md-5 mb-3">
        {data && stripHtmlTags(data?.title)}
      </h4>
      <h5 className="fs-5 fw-fw-normal text-black px-2 px-md-0 my-md-5 pt-5 pb-3 pt-md-0 pb-md-0">
        {data && stripHtmlTags(data?.headline)}
      </h5>
      {data && data?.content && (
        <div
          className="fs-6 fw-fw-lighter text-black px-2 px-md-0"
          dangerouslySetInnerHTML={{ __html: data?.content || "" }}
        />
      )}
      <h5 className="fs-5 fw-fw-normal text-black mt-5 px-2 px-md-0 mb-3">
        Benefits of e-Procurement and B2B Online Trading:
      </h5>

     

      <h5 className="fs-5 fw-fw-normal text-black my-3 px-2 px-md-0">
        Key Components
      </h5>
      <ul className="custom-list">
        <li>
          {" "}
          <span className="fs-6 fw-bold text-black">e-Catalogs :</span> Digital
          catalogs that contain product information, prices, and specifications,
          enabling buyers to browse and select items for purchase.
        </li>
        <li>
          {" "}
          <span className="fs-6 fw-bold text-black">e-Sourcing :</span> Online
          platforms that facilitate supplier identification, request for
          proposals (RFPs), and negotiation processes.
        </li>
        <li>
          {" "}
          <span className="fs-6 fw-bold text-black">e-Ordering :</span>{" "}
          Electronic order placement and management systems that allow buyers to
          submit purchase orders to suppliers and track their status.
        </li>
        <li>
          {" "}
          <span className="fs-6 fw-bold text-black">
            e-Invoicing and Payment :
          </span>{" "}
          Electronic invoicing and payment systems that streamline billing
          processes and facilitate secure online transactions.
        </li>
        <li>
          {" "}
          <span className="fs-6 fw-bold text-black">
            Supplier Relationship Management (SRM) :
          </span>{" "}
          Tools and processes to manage supplier interactions, performance
          evaluation, and collaboration.
        </li>
      </ul>
        </div>
      </div>):(
        <BlogDetailCardSkeleton />
      )}
      
    </div>
  );
}

export default BlogDetailsCard;
