"use client";
import { CallToActionProps } from "@/utils/types";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { Link } from "@/navigation";

function CallToAction({
  heading,
  subHeading,
  buttonText,
  buttonLink,
  bgImage,
}: CallToActionProps) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  return (
    <div
      className={`call-to-action call_to_action_bg rounded d-flex justify-content-between align-items-center flex-column flex-md-row rounded-md-5 flex-lg-row p-3 px-md-4 py-md-5 p-lg-5${
        bgImage === undefined ? "bg-secondary" : ""
      }`}
    >
      <div>
        <h3
          className={`text-white m-0 ${
            isMobile ? "fs-4 text-center" : "fs-2"
          }`}
        >
          {heading}
        </h3>
        <p
          className={`text-white m-0 ${
            isMobile ? "fs-6 text-center" : "fs-5"
          } fw-light`}
        >
          {subHeading}
        </p>
      </div>
      <div>
        {" "}
        <Link
          href={buttonLink ?? ""}
          className={`text-decoration-none text-white secondary_btn rounded-5 fw-bold py-3 px-5 my-3 d-block my-md-0  ${
            isMobile ? "fs-6" : ""
          }`}
        >
          {buttonText}
        </Link>
      </div>
    </div>
  );
}

export default CallToAction;
