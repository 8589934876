"use client";
import { Button } from "@/components/bootstrap";
import { CallToActionProps } from "@/utils/types";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "@/navigation";

function MarketplaceCallToAction({
  heading,
  subHeading,
  buttonText,
  buttonLink,
  bgImage,
}: CallToActionProps) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (heading) {
      setLoading(false);
    }
  }, [heading]);
  return (
    <div
      className={`call-to-action call_to_action_marketplace rounded d-flex justify-content-between align-items-center flex-column flex-md-row rounded-5 flex-lg-row py-md-5 px-md-3 py-lg-5 px-lg-5${
        bgImage === undefined ? "bg-secondary" : ""
      }`}
      style={{ borderRadius: `${isMobile ? "0px" : "20px !important"}` }}
    >
    {!loading ? (
      <>
        <div>
        <h3 className="text-white  m-0 fs-5">{heading}</h3>
        <p className="text-white  m-0 fs-5 fw-bold">{subHeading}</p>
      </div>
      <Link
        href={buttonLink ?? ""}
        className="text-white rounded-5 py-3 px-lg-5 px-md-3 px-3 mt-3 secondary_btn"
      >
        {buttonText}
      </Link>
      </>
    ) : (
      <>
      <div>
      <h3 className="text-white text-black m-0 fs-5">
        <Skeleton width={490} borderRadius={10}/>
      </h3>
      <p className="text-white text-black m-0 fs-5 fw-bold">
      <Skeleton width={590} borderRadius={10}/>
      </p>
    </div>
    <Skeleton width={190} borderRadius={50} height={40}/>
    </>
    )}
    </div>
  );
}

export default MarketplaceCallToAction;
