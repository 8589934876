import React from 'react'
import Skeleton from 'react-loading-skeleton'

const IconCardSkeleton = () => {
  return (
    <div
    className="align-items-center d-flex bg-white border rounded-4 icon-mobile-card p-3"
  >
    <div>

        <Skeleton height={50} width={50} borderRadius={50}/>
    </div>
        <p className="fs-6 fw-bold m-0 ms-1 text-truncate">
        <Skeleton count={2} height={10} width={270}/>
    </p>
  </div>
  )
}

export default IconCardSkeleton