import React from 'react'
import Skeleton from 'react-loading-skeleton'

const ProductSliderSkeleton = () => {
  return (
    <div className="borde-radius-5">
    <div className="card">
        <div className="w-100 d-flex justify-content-center image--wrapper position-relative">
          <Skeleton height={170} width={280}/>
        </div>
        <div className='px-2'>
          <Skeleton height={10} width={190}/>
          <Skeleton height={10} width={150}/>
          <p><Skeleton height={10} width={50} borderRadius={50}/></p>
          <p><Skeleton height={10} width={150}/></p>
        </div>
      <div className=" d-flex justify-content-between px-4 mb-2">
      <Skeleton height={30} width={90} borderRadius={50}/>
      <Skeleton height={30} width={90} borderRadius={50}/>
      </div>
    </div>
  </div>
  )
}

export default ProductSliderSkeleton