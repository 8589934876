"use client";

import { useTranslations } from "next-intl";

function ExpertCommonCard() {
  const networkTabText = useTranslations("networkTabText")
  return (
    <>
      <div
        className="call-to-action text-center p-5 me-4 my-4"
        style={{
          background: `url(/assets/img/bg-img.webp)`,
          backgroundSize: "100% 100%",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          height: "200px",
        }}
      >
        <div>
          <h3 className="text-white fw-normal fs-4 mt-4">
            {networkTabText("public_rfp_banners_can_be_added_here")}
          </h3>
        </div>
      </div>
    </>
  );
}

export default ExpertCommonCard;
