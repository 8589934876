"use client";
import { FC, useEffect, useState } from "react";
import SearchForm from "../advanced-search/SearchForm";
import { HeroSectionProps } from "@/utils/types";
import { BackgroundLayoutHome } from "../common";
import { Button } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";

const HeroSection: FC<HeroSectionProps> = ({
  heading,
  subheading,
  content,
  showSearch,
  containerHeight,
  bgHeight,
  sectionHeight,
  children,
  searchCategory,
  searchTopCards,
  isMobile,
  searchDropdown,
  isButton,
  buttonText,
  redirectionUrl
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isLoading, SetIsLoading] = useState(true);
  const mobile = useClientMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (searchCategory) {
      SetIsLoading(false);
    }
  }, [searchCategory]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, []);

  return (
    <div
      className={`${
        containerHeight ? containerHeight : "vh-150"
      } home-hero-widget position-relative overflow-hidden`}
    >
      <div
        className={`hero-content d-flex flex-column mvertical-10 justify-content-center align-items-center ${
          mobile ? "w-90" : "w-75"
        } m-auto`}
      >
        {!loading ? heading || "" : 
                <Skeleton height={30} width={470} className="my-2"/>
        
        }
        {!loading ? (subheading ? subheading : ""): 
                <Skeleton height={30} width={630} className="my-2"/>
        }
        {!loading ? (content ? content : "") : 
                <Skeleton height={10} width={390} className="my-2"/>
        }
        {!loading ? (isButton &&  <Button
                type="button"
                className="rounded-5 d-sm-block secondary_btn d-none get-started-btn my-4 text-white"
                size="lg"
                href={redirectionUrl}
              >
                {buttonText}
              </Button>): (
            <Skeleton height={40} width={300} borderRadius={50} />
              )}
      </div>
      {!!children && children}
    </div>
  );
};

const PlaceholderComponent = ({ minHeight }: any) => (
  <div
    style={{
      minHeight,
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  ></div>
);

export default HeroSection;
