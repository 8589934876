"use client";
import React from "react";
import { Accordion } from "@/components/bootstrap";
import { ProductDetailsAccordianProps } from "@/utils/types";
import { StarRatingsFeature } from "../common";
export default function ProductDetailAccordian({
  description,
  warranty_info,
  specification,
  total_reviews,
  information,
  avg_rating,
}: ProductDetailsAccordianProps) {
  return (
    <Accordion className="accordian--wrapper" defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Product Details</Accordion.Header>
        <Accordion.Body>
          <p style={{ fontSize: "14px" }}>{description}</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>Specifications</Accordion.Header>
        <Accordion.Body>
          <div
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{ __html: specification }}
          />
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>Warranty & Support</Accordion.Header>
        <Accordion.Body>
          <p style={{ fontSize: "14px" }}>{warranty_info}</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>Return Policy & Shipping Policy</Accordion.Header>
        <Accordion.Body>
          <p style={{ fontSize: "14px" }}> {information}</p>
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>Ratings & Reviews</Accordion.Header>
        <Accordion.Body>
          <div className="" style={{ display: "flex" }}>
            <StarRatingsFeature
              data={avg_rating}
              starRatedColor="black"
              starHoverColor="black"
              starDimension="17px"
            />{" "}
            <div style={{ marginLeft: "20px", paddingTop: "3.5px" }}>
              {avg_rating ? avg_rating : 0}/5
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
