"use client";

import { default_supplier_logo } from "@/utils/CountryCodes";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { useRouter } from "@/navigation";

function MarketCard({ id, image, title, slug, media, product_id }: any) {
  const router = useRouter();
  const handleData = (slug: any) => {
    router.replace(`/supplier-profile/${slug}`);
  };

  return (
    <div className="rounded-5 overflow-hidden h-100 product-services-card cursor-pointer" 
    onClick={() => handleData(slug)}
    key={id}>
      <div className="image-height">
      <div
        style={{
          backgroundImage: media
            ? `url(${AMAZONE_MEDIA_URL}User/${id}/Product/${product_id}/${ThumbImageRatio}/${media})`
            : `url(${defaultproduct})`,
        }}
        className="product-back-img"
      >
        <div className="logo-container">
          <img
            className=" img-market-card cursor-pointer"
            alt="image"
            src={
              image
                ? `${AMAZONE_MEDIA_URL}User/${id}/BusinessLogo/${image}`
                : default_supplier_logo
            }
            loading="lazy"
            height={94}
            width={94}
          />
        </div>
      </div>
    </div>

      <h4 className="text-black mt-4 fs-6 fw-normal px-4">{title}</h4>
      <p className="fw-light m-0 px-4 pb-4 fs-7">View Catelogue</p>
    </div>
  );
}

export default MarketCard;
