"use client";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { ExpertCardProps } from "@/utils/types";
import { profile_pic } from "@/utils/CountryCodes";

function ExpertImgCard({
  id,
  image,
  firstName,
  lastName,
  hireModalShow,
}: ExpertCardProps) {
  return (
    <div className="card text-center" key={id}>
      {id ? (
        <div className="img-section py-2 pt-md-0 d-flex justify-content-center align-items-center">
          <img
            loading="lazy"
            className="rounded-circle my-sm-4"
            alt="image"
            src={`${
              image
                ? AMAZONE_MEDIA_URL + "User/" + id + "/Profile/" + image
                : profile_pic
            }`}
            style={{ width: "121px", height: "121px" }}
          />
        </div>
      ) : (
        <img
          loading="lazy"
          className="rounded-circle my-sm-4 pt-2 pt-md-0 d-flex justify-content-center align-items-center"
          alt="image"
          src={image}
          width={121}
          height={121}
        />
      )}
      <p>{firstName + " " + lastName}</p>
      {hireModalShow !== undefined && (
        <button
          type="button"
          className="btn"
          onClick={() => hireModalShow({ id, image, firstName, lastName })}
        >
          Hire Now
        </button>
      )}
    </div>
  );
}

export default ExpertImgCard;
