"use client";
import { bussinessTypeCardData } from "@/utils/types";
import Reveal from "../common/Reveal";
import { Variants } from "framer-motion";
import BussinessCard from "../common/bussiness-card";
import BusinessMobileCard from "../common/business-mobile-card";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { useEffect, useState } from "react";
import BussinessCardSkeleton from "../skeleton/business-card-skeleton";

function BussinessTypeCard({ contactSectionsList }: any) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (contactSectionsList) {
      setLoading(false);
    }
  }, [contactSectionsList]);
  return (
    <Reveal width="100%">
      <div className="row px-md-4 px-lg-5">
        {!loading ? contactSectionsList?.map(
          (item: bussinessTypeCardData, index: number) => {
            const cardVariants: Variants = {
              hidden: {
                y: 100,
              },
              visible: {
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2 * (index + 1),
                },
              },
            };
            return isMobile ? (
              <BusinessMobileCard
                key={index}
                image={item?.imageUrl}
                heading={item?.title}
                content={item?.headline}
                subcontent={item?.cta?.label}
              />
            ) : (
              <BussinessCard
                key={index}
                image={item?.imageUrl}
                heading={item?.title}
                content={item?.headline}
                subcontent={item?.cta?.label}
                cardVariants={cardVariants}
              />
            );
          }
        ):(
          Array.from({ length: 4 }, (_, index) => (
            <BussinessCardSkeleton />
          ))
        )}
      </div>
    </Reveal>
  );
}

export default BussinessTypeCard;
