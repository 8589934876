"use client";
import { FC, useEffect, useState } from "react";
import { Button } from "@/components/bootstrap";
import Skeleton from "react-loading-skeleton";

const ContactMaskGroup: FC<any> = ({ data }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  return (
  <>
  {!loading ?
     ( <div className="container-fuild p-md-11 p-5 text-white community-mask-card-1 ">
      <div className="row gx-md-5">
        <div className="col-md-5">
          <h2 className="fs-2 fw-normal">{data?.title}</h2>
          <p className="py-2">{data?.headline}</p>
          <Button
            href="#"
            variant="secondary"
            className="text-white rounded-5 get-started-btn"
            size="lg"
          >
            {data?.cta?.label}
          </Button>
        </div>
      </div>
    </div>)
    :
    (
      <div className="container-fuild p-md-11 p-5 text-white community-mask-card-1 ">
      <div className="row gx-md-5">
        <div className="col-md-5">
          <h2 className="fs-2 fw-normal">
            <Skeleton height={30}/>
          </h2>
          <p className="py-2">
            <Skeleton count={3}/>
          </p>
          <Skeleton height={50} width={200} borderRadius={50}/>
        </div>
      </div>
    </div>
    )
  }
  </>
  );
};

export default ContactMaskGroup;
