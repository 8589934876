import Skeleton from "react-loading-skeleton";

function PopularSupplierCardSkeleton() {

  return (
    <div className="my-2 px-2">
      <div className="bg-white rounded-4 overflow-hidden p-3 popular-supplier-card">
        <Skeleton height={50} width={50} borderRadius={50}/>
        <h5 className="main-heading main-heading-tablet text-black fs-6 fw-normal my-3 popular-supplier">
          <Skeleton count={3}/>
        </h5>
        <h5
          className="main-heading main-heading-tablet text-black fs-7 fw-light"
        >
        <Skeleton/>
        </h5>
        <div className="d-flex justify-content-between mt-4">
        <Skeleton height={20} width={40} borderRadius={50}/>

        <Skeleton height={20} width={40} borderRadius={50}/>

        </div>
      </div>
    </div>
  );
}

export default PopularSupplierCardSkeleton;
