import Skeleton from "react-loading-skeleton";

const CallToActionSimpleSkeleton = () => {

  return (
    <div
      className={`call-to-action-simple d-flex justify-content-between align-items-center flex-column p-4 p-md-5 p-md-8 p-lg-11`}
    >
      <Skeleton height={60} className="mb-2" width={800} />
      <Skeleton height={20} width={200} />
      <Skeleton height={20} width={600} className="mb-2"/>
      <Skeleton height={40} width={200} borderRadius={50} />
    </div>
  );
};

export default CallToActionSimpleSkeleton;
