"use client";
import { Modal, Button } from "@/components/bootstrap";
import { useRouter } from "@/navigation";
import { BsXLg } from "react-icons/bs";

interface ShowLoginModalProps {
  show: boolean;
  onHide: () => void;
  data: any;
}
const ShowLoginModal: React.FC<ShowLoginModalProps> = ({
  show,
  onHide,
  data,
}) => {
const router = useRouter();
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="text-center p-3">
        <div className="d-flex justify-content-end  align-items-center cursor-pointer" onClick={onHide}>
          <BsXLg size={30}/>
        </div>
        <h4 className="text-secondary">{data?.title}</h4>
            <p className="fw-semibold fs-6 supplier-content-color">
            Sign in or get your free account on e-Procure now
            </p>
            <div>
              <Button
                onClick={()=>router.push("/sign-in-by-email")}
                variant="outline-secondary"
                className="text-color-secondary rounded-5 px-5 py-2 my-sm-2 mx-4"
                size="sm"
              >
                Sign In
              </Button>
              <Button
                onClick={()=>router.push("/sign-in")}
                variant="secondary"
                className="text-color-white rounded-5 px-5 py-2 my-sm-2"
                size="sm"
              >
                Sign Up For Free
              </Button>
            </div>
      </div>
    </Modal>
  );
};

export default ShowLoginModal;
