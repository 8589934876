"use client";
import { useState, useEffect } from "react";
import {
  Nav,
  TabContainer,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "@/components/bootstrap";
import {
  fetchCoordinates,
  fetchSupplierNetworkData,
  fetchExpertNetworkData,
  fetchCategoryData,
  fetchPopularBuyers,
  fetchPopularSuppliers,
  fetchTopBrands,
  fetchBuyerNetworkData,
} from "@/utils/apiServices";
import {
  SupplierNetworkPayload,
  ExpertNetworkPayload,
  CategoryData,
  CategoryPayload,
  popularSupplierBuyer,
  topBrands,
} from "@/utils/types";
import { USER_TYPE_BUYER, USER_TYPE_SUPPLIER } from "@/utils/constant";
import DiscoverAllLayout from "./discover-all-layout";
import {
  SupplierBuyerNetworkCard,
  ExpertNetworkCard,
  NetworkCard,
  BussinessMapView,
  BussinessNetworkSidebar,
  BussinessCombineView,
  ExpertCombineView,
  ExpertCommonCard,
  ScrollPagination,
  ExpertSuccessStories,
} from "../common";
import { PopularSupplierSection } from "@/components/widgets";
import Lottie from "lottie-react";
import loaderData from "../../utils/animationJson/loader.json";
import { useSettingsContext } from "@/context";
import { useTranslations } from "next-intl";

function BussinessNetworkTabs({ tabsData, bannerData }: any) {
  const { onUpdate, searchTerm, businessCategory, currentTab } = useSettingsContext();
  const [activeTab, setActiveTab] = useState(    currentTab==="Supplier" ? "suppliers" : currentTab==="Buyer" ? "buyers" : currentTab==="Expert" ? "experts" : "discover-all"
  );
  const [activeTabShow, setActiveTabShow] = useState<string>(
    currentTab==="Supplier" ? "Suppliers" : currentTab==="Buyer" ? "Buyers" : currentTab==="Expert" ? "Experts" : "Discover All"
  );
  const [networkCardData, setNetworkCardData] = useState({
    heading: "",
    subheading: "",
    bgImage: "",
  });
  const [supplierData, setSupplierData] = useState<any>([]);
  const [currentTextSearch, setCurrentTextSearch] = useState<string>("Text");
  const [buyerData, setBuyerData] = useState<any>([]);
  const [expertData, setExpertData] = useState<any>([]);
  const [supplierSearchQuery, setSupplierSearchQuery] = useState("");
  const [rating, setRating] = useState(0);
  const [isFeatured, setIsFeatured] = useState(false);
  const [isBookMakared, setIsBookMarked] = useState(false);
  const [buyerSearchQuery, setBuyerSearchQuery] = useState("");
  const [expertSearchQuery, setExpertSearchQuery] = useState("");
  const [coords, setCoords] = useState({ latitude: 0, longitude: 0 });
  const [categoryData, setCategoryData] = useState<CategoryData[]>([]);
  const [popularSupplier, setPopularSupplier] = useState<
    popularSupplierBuyer[]
  >([]);
  const [popularBuyer, setPopularBuyer] = useState<popularSupplierBuyer[]>([]);
  const [supplierSelectedCategories, setSupplierSelectedCategories] = useState<
    number[]
  >([]);
  const [buyerSelectedCategories, setBuyerSelectedCategories] = useState<
    number[]
  >([]);
  const [expertSelectedCategories, setExpertSelectedCategories] = useState<
    number[]
  >([]);
  const [supplierIndustryId, setSupplierIndustryId] = useState<number>(0);
  const [buyerIndustryId, setBuyerIndustryId] = useState<number>(0);
  const [expertIndustryId, setExpertIndustryId] = useState<number>(0);
  const [isSupplierLoading, setIsSupplierLoading] = useState(true);
  const [isLoadSupplier, setIsLoadSupplier] = useState<boolean>(true);
  const [supplierTotalRecords, setSupplierTotalRecords] = useState<number>(0);
  const [supplierRecords, setSupplierRecords] = useState<any>({});
  const [supplierFilterOption, setSupplierFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [isBuyerLoading, setIsBuyerLoading] = useState(true);
  const [isLoadBuyer, setIsLoadBuyer] = useState<boolean>(true);
  const [buyerTotalRecords, setBuyerTotalRecords] = useState<number>(0);
  const [buyerFilterOption, setBuyerFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [isExpertLoading, setIsExpertLoading] = useState(true);
  const [isLoadExpert, setIsLoadExpert] = useState<boolean>(true);
  const [expertTotalRecords, setExpertTotalRecords] = useState<number>(0);
  const [expertFilterOption, setExpertFilterOption] = useState({
    record_count: 0,
    limit: 15,
  });
  const [topBrands, setTopBrands] = useState<topBrands[]>([]);
  let paginator = ScrollPagination();
  const networkTabText = useTranslations("networkTabText")

  const handleSupplierScroll = async (e: React.UIEvent, scrollType: string) => {
    let elem = e.target as HTMLElement;

    if (elem.className && typeof elem.className.indexOf === "function") {
      if (supplierData.length < supplierTotalRecords && !isLoadSupplier) {
        setIsSupplierLoading(true);

        try {
          await paginator.scrollEvent(scrollType, async () => {
            if (supplierFilterOption.record_count <= supplierTotalRecords) {
              setSupplierFilterOption((prevFilter) => ({
                ...prevFilter,
                record_count: prevFilter.record_count + prevFilter.limit,
              }));
            }

            setIsLoadSupplier(true);
          });
        } catch (error) {
          console.error("Error loading more supplier data:", error);
        } finally {
          setIsSupplierLoading(false);
        }
      }
    }
  };

  const handleBuyerScroll = async (e: React.UIEvent, scrollType: string) => {
    let elem = e.target as HTMLElement;

    if (elem.className && typeof elem.className.indexOf === "function") {
      if (buyerData.length < buyerTotalRecords && !isLoadBuyer) {
        setIsBuyerLoading(true);

        try {
          await paginator.scrollEvent(scrollType, () => {
            setBuyerFilterOption((prevFilter) => ({
              ...prevFilter,
              record_count: prevFilter.record_count + prevFilter.limit,
            }));
            setIsLoadBuyer(true);
          });
        } catch (error) {
          console.error("Error loading more buyer data:", error);
        } finally {
          setIsBuyerLoading(false);
        }
      }
    }
  };

  const handleExpertScroll = async (e: React.UIEvent, scrollType: string) => {
    let elem = e.target as HTMLElement;

    if (elem.className && typeof elem.className.indexOf === "function") {
      if (expertData.length < expertTotalRecords && !isLoadExpert) {
        setIsExpertLoading(true);

        try {
          await paginator.scrollEvent(scrollType, () => {
            setExpertFilterOption((prevFilter) => ({
              ...prevFilter,
              record_count: prevFilter.record_count + prevFilter.limit,
            }));
            setIsLoadExpert(true);
          });
        } catch (error) {
          console.error("Error loading more expert data:", error);
        } finally {
          setIsExpertLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (tabsData.length > 0) {
      setNetworkCardData({
        heading: tabsData[0]?.headline || "",
        subheading: tabsData[0]?.content || "",
        bgImage: tabsData[0]?.theme.backgroungImgUrl || "",
      });
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const coordsData = await fetchCoordinates();
        setCoords(coordsData);
      } catch (error) {
        console.error("Error fetching Coordinates data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchTopBrandsData() {
      try {
        let data = {
          limit: 10,
          recordCount: 1,
        };
        const brandData = await fetchTopBrands(data);
        setTopBrands(brandData);
      } catch (error) {
        console.error("Error fetching Coordinates data:", error);
      }
    }
    fetchTopBrandsData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const popularBuyers = await fetchPopularBuyers();
        setPopularBuyer(popularBuyers);
        const popularSuppliers = await fetchPopularSuppliers();
        setPopularSupplier(popularSuppliers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const categoriesPayload: CategoryPayload = {
        filter: "",
        language_id: 1,
      };
      const categoriesData = await fetchCategoryData(categoriesPayload);
      setCategoryData(categoriesData);
    }
    fetchData();
  }, []);

  const handleSupplierSearch = (query: string) => {
    setSupplierSearchQuery(query);
    setCurrentTextSearch(query)
    onUpdate("searchTerm", "");
    setSupplierData([]);
    setSupplierTotalRecords(0);
    setSupplierFilterOption({
      record_count: 0,
      limit: 15,
    });
  };
  const handleSupplierRating = (query: number) => {
    setRating(query);
    setSupplierData([]);
    setBuyerData([]);
    setExpertData([]);
    setSupplierTotalRecords(0);
    setBuyerTotalRecords(0);
    setExpertTotalRecords(0);
    setSupplierFilterOption({
      record_count: 0,
      limit: 15,
    });
    setBuyerFilterOption({
      record_count: 0,
      limit: 15,
    });
    setExpertFilterOption({
      record_count: 0,
      limit: 15,
    });
  };
  const handleIsFeatured = (query: boolean) => {
    setIsFeatured(query);
    setSupplierData([]);
    setSupplierTotalRecords(0);
    setSupplierFilterOption({
      record_count: 0,
      limit: 15,
    });
    setBuyerData([]);
    setExpertData([]);
    setBuyerTotalRecords(0);
    setExpertTotalRecords(0);

    setBuyerFilterOption({
      record_count: 0,
      limit: 15,
    });
    setExpertFilterOption({
      record_count: 0,
      limit: 15,
    });
  };
  const handleIsBookMarked = (query: boolean) => {
    setIsBookMarked(query);
    setSupplierData([]);
    setSupplierTotalRecords(0);
    setSupplierFilterOption({
      record_count: 0,
      limit: 15,
    });
    setBuyerData([]);
    setExpertData([]);
    setBuyerTotalRecords(0);
    setExpertTotalRecords(0);

    setBuyerFilterOption({
      record_count: 0,
      limit: 15,
    });
    setExpertFilterOption({
      record_count: 0,
      limit: 15,
    });
  };

  const handleBuyerSearch = (query: string) => {
    setBuyerSearchQuery(query);
    setCurrentTextSearch(query)
    onUpdate("searchTerm", "");
    setBuyerData([]);
    setBuyerTotalRecords(0);
    setBuyerFilterOption({
      record_count: 0,
      limit: 15,
    });
  };
  const handleExpertSearch = (query: string) => {
    setExpertSearchQuery(query);
    setCurrentTextSearch(query)
    onUpdate("searchTerm", "");
    setExpertData([]);
    setExpertTotalRecords(0);
    setExpertFilterOption({
      record_count: 0,
      limit: 15,
    });
  };

  const handleSupplierCategoryCheck = (categoryId: number) => {
    if (supplierSelectedCategories.includes(categoryId)) {
      setSupplierSelectedCategories(
        supplierSelectedCategories.filter((id) => id !== categoryId)
      );
      setSupplierIndustryId(0);
      onUpdate("businessCategory", 0);
    } else {
      setSupplierSelectedCategories([
        ...supplierSelectedCategories,
        categoryId,
      ]);
      setSupplierIndustryId(categoryId);
    }
    setSupplierData([]);
    setSupplierTotalRecords(0);
    setSupplierFilterOption({
      record_count: 0,
      limit: 15,
    });
    setIsSupplierLoading(true);
  };
  const handleBuyerCategoryCheck = (categoryId: number) => {
    if (buyerSelectedCategories.includes(categoryId)) {
      setBuyerSelectedCategories(
        buyerSelectedCategories.filter((id) => id !== categoryId)
      );
      setBuyerIndustryId(0);
      onUpdate("businessCategory", 0);
    } else {
      setBuyerSelectedCategories([...buyerSelectedCategories, categoryId]);
      setBuyerIndustryId(categoryId);
    }
    setBuyerData([]);
    setBuyerTotalRecords(0);
    setBuyerFilterOption({
      record_count: 0,
      limit: 15,
    });
    setIsBuyerLoading(true);
  };
  const handleExpertCategoryCheck = (categoryId: number) => {
    if (expertSelectedCategories.includes(categoryId)) {
      setExpertSelectedCategories(
        expertSelectedCategories.filter((id) => id !== categoryId)
      );
      setExpertIndustryId(0);
      onUpdate("businessCategory", 0);
    } else {
      setExpertSelectedCategories([...expertSelectedCategories, categoryId]);
      setExpertIndustryId(categoryId);
    }
    setExpertData([]);
    setExpertTotalRecords(0);
    setExpertFilterOption({
      record_count: 0,
      limit: 15,
    });
    setIsExpertLoading(true);
  };
  useEffect(() => {
    async function fetchSupplierData() {
      const supplierPayload: SupplierNetworkPayload = {
        ...supplierFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        search: supplierSearchQuery,
        user_type_id: USER_TYPE_SUPPLIER,
        is_featured: isFeatured,
        rating: rating,
        in_watchlist: isBookMakared,
      };

      if (searchTerm !== "" && searchTerm !== undefined) {
        handleTabChange("suppliers");
        supplierPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        handleTabChange("suppliers");
        setSupplierSelectedCategories([
          ...supplierSelectedCategories,
          businessCategory,
        ]);
        setSupplierIndustryId(businessCategory);
      }

      if (supplierSelectedCategories.length > 0) {
        supplierPayload.industry_ids = supplierSelectedCategories.join(",");
      }

      try {
        const supplier: any = await fetchSupplierNetworkData(supplierPayload);
        if (supplier.status === 200) {
          setSupplierTotalRecords(supplier?.total_records);
          setSupplierData([...supplierData, ...supplier?.data]);
          setSupplierRecords(supplier?.counts);
          setIsLoadSupplier(false);
          setIsSupplierLoading(false);
        } else {
          setIsLoadSupplier(false);
          setIsSupplierLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (coords.latitude && coords.longitude) {
      fetchSupplierData();
    }
  }, [
    supplierFilterOption,
    coords,
    supplierSearchQuery,
    supplierIndustryId,
    rating,
    isFeatured,
    isBookMakared,
    businessCategory,
  ]);

  useEffect(() => {
    async function fetchBuyerData() {
      const buyerPayload: SupplierNetworkPayload = {
        ...buyerFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        search: buyerSearchQuery,
        user_type_id: USER_TYPE_BUYER,
        is_featured: isFeatured,
        rating: rating,
      };
      if (searchTerm !== "" && searchTerm !== undefined) {
        buyerPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        setBuyerSelectedCategories([
          ...buyerSelectedCategories,
          businessCategory,
        ]);
        setBuyerIndustryId(businessCategory);
      }
      if (buyerSelectedCategories.length > 0) {
        buyerPayload.industry_ids = buyerSelectedCategories.join(",");
      }

      try {
        const buyer = await fetchBuyerNetworkData(buyerPayload);
        if (buyer.status === 200) {
          setBuyerTotalRecords(buyer.total_records);
          setBuyerData([...buyerData, ...buyer?.data]);
          setIsLoadBuyer(false);
          setIsBuyerLoading(false);
        } else {
          setIsLoadBuyer(false);
          setIsBuyerLoading(false);
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (coords.latitude && coords.longitude) {
      fetchBuyerData();
    }
  }, [
    buyerFilterOption,
    coords,
    buyerSearchQuery,
    buyerIndustryId,
    rating,
    isFeatured,
    businessCategory,
  ]);

  useEffect(() => {
    async function fetchExpertData() {
      const expertPayload: ExpertNetworkPayload = {
        ...expertFilterOption,
        latitude: coords.latitude,
        longitude: coords.longitude,
        price_end_range: 500,
        price_start_range: 0,
        search: expertSearchQuery,
        is_featured: isFeatured,
        rating: rating,
      };
      if (searchTerm !== "" && searchTerm !== undefined) {
        expertPayload.search = searchTerm;
      }
      if (businessCategory != 0) {
        setExpertSelectedCategories([
          ...expertSelectedCategories,
          businessCategory,
        ]);
        setExpertIndustryId(businessCategory);
      }
      if (expertSelectedCategories.length > 0) {
        expertPayload.industry_ids = expertSelectedCategories.join(",");
      }
      try {
        const expert = await fetchExpertNetworkData(expertPayload);
        if (expert.status === 200) {
          setExpertTotalRecords(expert.total_records);
          setExpertData([...expertData, ...expert?.data]);
          setIsLoadExpert(false);
          setIsExpertLoading(false);
        } else {
          setIsLoadExpert(false);
          setIsExpertLoading(false);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadExpert(false);
        setIsExpertLoading(false);
      }
    }
    if (coords.latitude && coords.longitude) {
      setIsExpertLoading(true);
      fetchExpertData();
    }
  }, [
    expertFilterOption,
    coords,
    expertSearchQuery,
    expertIndustryId,
    rating,
    isFeatured,
    businessCategory,
  ]);

  const supplierTabs = ["suppliers", "supplier-card-view", "supplier-map-view"];
  const buyerTabs = ["buyers", "buyer-card-view", "buyer-map-view"];
  const expertTabs = ["experts", "expert-card-view", "expert-map-view"];

  const handleTabChange = (selectedTab: string | null) => {
    if (selectedTab) {
      setActiveTab(selectedTab);
      const selectedData = tabsData.find(
        (tab: any) => tab.title === selectedTab
      );
      if (selectedData) {
        setNetworkCardData({
          heading: selectedData.headline || "",
          subheading: selectedData?.content || "",
          bgImage: selectedData?.theme.backgroungImgUrl || "",
        });
      }
    }
  };
  return (
    <>
      <div className="px-md-5 pt-md-5">
        <NetworkCard
          heading={networkCardData.heading}
          subHeading={networkCardData.subheading}
          bgImage={networkCardData.bgImage}
        />
      </div>
      <div className="my-5">
        <TabContainer
          activeKey={activeTab}
          onSelect={handleTabChange}
          id="myTab"
        >
          <Nav
            className="nav-tabs px-md-5 px-3 d-flex flex-wrap justify-content-between"
            id="myTab"
            role="tablist"
          >
            <div className="nav nav-tabs-scroll">
              <NavItem className="nav-item" onClick={()=>setActiveTabShow("Discover All")} role="presentation">
                <NavLink eventKey="discover-all" className={`${activeTabShow==="Discover All" ? "active":""}`}>{networkTabText("discover_all")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>setActiveTabShow("Suppliers")} role="presentation">
                <NavLink eventKey="suppliers" className={`${activeTabShow==="Suppliers" ? "active":""}`}>{networkTabText("suppliers")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>setActiveTabShow("Buyers")}  role="presentation">
                <NavLink eventKey="buyers" className={`${activeTabShow==="Buyers" ? "active":""}`}>{networkTabText("buyers")}</NavLink>
              </NavItem>
              <NavItem className="nav-item" onClick={()=>setActiveTabShow("Experts")}  role="presentation">
                <NavLink eventKey="experts" className={`${activeTabShow==="Experts" ? "active":""}`} >{networkTabText("experts")}</NavLink>
              </NavItem>
            </div>
            {supplierTabs.includes(activeTab) ? (
              <div className="d-flex align-items-center">
                <NavItem role="presentation">
                  <NavLink eventKey="supplier-card-view">{networkTabText("combined_view")}</NavLink>
                </NavItem>
                <NavItem role="presentation">
                  <NavLink eventKey="supplier-map-view">{networkTabText("map_view")}</NavLink>
                </NavItem>
              </div>
            ) : buyerTabs.includes(activeTab) ? (
              <div className="d-flex align-items-center">
                <div role="presentation">
                  <NavLink eventKey="buyer-card-view">{networkTabText("combined_view")}</NavLink>
                </div>
                <NavItem role="presentation">
                  <NavLink eventKey="buyer-map-view">{networkTabText("map_view")}</NavLink>
                </NavItem>
              </div>
            ) : expertTabs.includes(activeTab) ? (
              <div className="d-flex align-items-center">
                <div role="presentation">
                  <NavLink eventKey="expert-card-view">{networkTabText("combined_view")}</NavLink>
                </div>
                <NavItem role="presentation">
                  <NavLink eventKey="expert-map-view">{networkTabText("map_view")}</NavLink>
                </NavItem>
              </div>
            ) : null}
          </Nav>
          <TabContent className="tab-content overflow-hidden" id="myTabContent">
            <TabPane eventKey="discover-all">
              <DiscoverAllLayout
                popularSupplier={popularSupplier}
                supplierCategories={categoryData}
                topBrands={topBrands}
                expertData={expertData}
                supplierData={supplierData}
                bannerData={bannerData && bannerData}
                handleTabChange={handleTabChange}
              />
            </TabPane>
            <TabPane eventKey="suppliers">
              <>
                <div className="d-lg-flex flex-column flex-sm-row gap-md-5">
                  <BussinessNetworkSidebar
                    onSearch={handleSupplierSearch}
                    changeRating={handleSupplierRating}
                    handleIsFeatured={handleIsFeatured}
                    categoryData={categoryData}
                    selectedCategories={supplierSelectedCategories}
                    handleCategoryCheck={handleSupplierCategoryCheck}
                    handleIsBookMarked={handleIsBookMarked}
                    count={supplierRecords}
                  />
                  <div className="my-lg-3 w-100">
                    {supplierData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {supplierData.length}+ Companies @ e-procure
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                    <div
                      className="row my-sm-2 ps-3 ps-md-3 ps-lg-0 justify-content-start hide-scrollbar network-cards-scroll"
                      onScroll={(e) => handleSupplierScroll(e, "horizontal")}
                    >
                      {isSupplierLoading ? (
                        <div className="d-flex justify-content-center">
                          <Lottie
                            animationData={loaderData}
                            className="custom-loader"
                          />
                        </div>
                      ) : (
                        <>
                          {supplierData.length ? (
                            supplierData.map((item: any, index: number) => (
                              <div className="col-md-6 col-lg-4" key={index}>
                                <SupplierBuyerNetworkCard
                                  key={index}
                                  id={item.supplier_id}
                                  slug={item.slug}
                                  data={item}
                                  type="Supplier"
                                />
                              </div>
                            ))
                          ) : (
                   <div className="col-12 w-100 text-center">
                    <h4 className="mt-5 fw-semibold text-center">You searched for</h4>
                    <h4 className="fw-bold text-center">"{currentTextSearch}"</h4>
                    <img src="../assets/img/empty_data.png" width={100} alt="no data found" />
                    <h4 className="mt-5 fw-semibold text-center">We couldn't find any matches!</h4>
                    <p>Please check the spelling or try searching something else</p>
                  </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row p-0 px-lg-5 px-3 align-items-center">
                  <div className="col-md-2">
                    <h5 className="fs-5 fw-normal mt-4">
                      {networkTabText("few_popular")} <span className="fw-bold">{networkTabText("suppliers")}</span>
                    </h5>
                    <h6 className="fs-6 fw-light">{networkTabText("explore_all")}</h6>
                  </div>
                  <div className="col-md-10">
                    <PopularSupplierSection
                      data={popularSupplier}
                      type="Supplier"
                    />
                  </div>
                </div>
              </>
            </TabPane>
            <TabPane eventKey="buyers">
              <>
                <div className="d-lg-flex flex-column flex-sm-row gap-md-5">
                  <BussinessNetworkSidebar
                    onSearch={handleBuyerSearch}
                    changeRating={handleSupplierRating}
                    handleIsFeatured={handleIsFeatured}
                    categoryData={categoryData}
                    selectedCategories={buyerSelectedCategories}
                    handleCategoryCheck={handleBuyerCategoryCheck}
                  />
                  <div className="my-lg-3 w-100">
                  {buyerData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {buyerData.length}+ Companies @ e-procure
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                    <div
                      className="row my-sm-2 ps-3 ps-md-3 ps-lg-0  justify-content-start hide-scrollbar network-cards-scroll"
                      onScroll={(e) => handleBuyerScroll(e, "horizontal")}
                    >
                      {isBuyerLoading ? (
                        <div className="d-flex justify-content-center">
                          <Lottie
                            animationData={loaderData}
                            className="custom-loader"
                          />
                        </div>
                      ) : (
                        <>
                          {buyerData.length ? (
                            buyerData.map((item: any, index: number) => {
                              return (
                                <div className="col-md-6 col-lg-4" key={index}>
                                  <SupplierBuyerNetworkCard
                                    key={index}
                                    id={item.buyer_id}
                                    data={item}
                                    type="Buyer"
                                    slug={item.slug}
                                  />
                                </div>
                              );
                            })
                          ) : (
                            <div className="col-12 w-100 text-center">
                            <h4 className="mt-5 fw-semibold text-center">You searched for</h4>
                            <h4 className="fw-bold text-center">"{currentTextSearch}"</h4>
                            <img src="../assets/img/empty_data.png" width={100} alt="no data found" />
                            <h4 className="mt-5 fw-semibold text-center">We couldn't find any matches!</h4>
                            <p>Please check the spelling or try searching something else</p>
                          </div>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row p-0 px-md-5 px-3">
                  <div className="col-md-2">
                    <h5 className="fs-5 fw-normal mt-5">
                      {networkTabText("few_popular")} <span className="fw-bold">{networkTabText("buyers")}</span>
                    </h5>
                    <h6 className="fs-6 fw-light">EXPLORE ALL</h6>
                  </div>
                  <div className="col-md-10">
                    <PopularSupplierSection data={popularBuyer} type="Buyer" />
                  </div>
                </div>
              </>
            </TabPane>
            <TabPane eventKey="experts">
              <>
                <div className="d-lg-flex flex-column flex-sm-row gap-md-5">
                  <BussinessNetworkSidebar
                    onSearch={handleExpertSearch}
                    changeRating={handleSupplierRating}
                    handleIsFeatured={handleIsFeatured}
                    categoryData={categoryData}
                    selectedCategories={expertSelectedCategories}
                    handleCategoryCheck={handleExpertCategoryCheck}
                  />
                  <div className="my-lg-3 w-100">
                  {expertData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {expertData.length}+ {networkTabText("professional_experts_listed")}
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                    <div
                      className="row my-sm-2 ps-3 ps-md-3 ps-lg-0  justify-content-start hide-scrollbar network-cards-scroll"
                      onScroll={(e) => handleExpertScroll(e, "horizontal")}
                    >
                      {isExpertLoading ? (
                        <div className="d-flex justify-content-center">
                          <Lottie
                            animationData={loaderData}
                            className="custom-loader"
                          />
                        </div>
                      ) : (
                        <>
                          {expertData.length ? (
                            expertData.map((item: any, index: number) => (
                              <div className="col-md-6 col-lg-4" key={index}>
                                <ExpertNetworkCard
                                  key={index}
                                  id={item.expert_id}
                                  data={item}
                                  slug={item.slug}
                                  experts_id={item.expert_id}
                                />
                              </div>
                            ))
                          ) : (
                            <div className="col-12 w-100 text-center">
                            <h4 className="mt-5 fw-semibold text-center">You searched for</h4>
                            <h4 className="fw-bold text-center">"{currentTextSearch}"</h4>
                            <img src="../assets/img/empty_data.png" width={100} alt="no data found" />
                            <h4 className="mt-5 fw-semibold text-center">We couldn't find any matches!</h4>
                            <p>Please check the spelling or try searching something else</p>
                          </div>
                          )}
                        </>
                      )}
                    </div>
                    <ExpertSuccessStories />
                    <ExpertCommonCard />
                  </div>
                </div>
              </>
            </TabPane>
            </TabContent>
          <TabContent className="tab-content overflow-hidden" id="myTabs">

            <TabPane eventKey="supplier-card-view">
              <>
                <div className="d-flex flex-column flex-md-column flex-lg-row gap-lg-5">
                  <BussinessNetworkSidebar
                    onSearch={handleSupplierSearch}
                    changeRating={handleSupplierRating}
                    handleIsFeatured={handleIsFeatured}
                    categoryData={categoryData}
                    selectedCategories={supplierSelectedCategories}
                    handleCategoryCheck={handleSupplierCategoryCheck}
                  />
                  <div className="my-lg-3">
                  {supplierData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {supplierData.length}+ Companies @ e-procure
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                    <BussinessCombineView
                      networkData={supplierData}
                      type="Supplier"
                    />
                  </div>
                </div>
                <div className="row p-0 px-5">
                  <div className="col-md-2">
                    <h5 className="fs-5 fw-normal mt-5">
                      {networkTabText("few_popular")} <span className="fw-bold">
                      {networkTabText("suppliers")}
                      </span>
                    </h5>
                    <h6 className="fs-6 fw-light">
                    {networkTabText("explore_all")}
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <PopularSupplierSection
                      data={popularSupplier}
                      type="Supplier"
                    />
                  </div>
                </div>
              </>
            </TabPane>
            <TabPane eventKey="supplier-map-view">
              <div className="d-flex flex-column flex-md-row flex-lg-row gap-lg-5">
                <BussinessNetworkSidebar
                  onSearch={handleSupplierSearch}
                  changeRating={handleSupplierRating}
                  handleIsFeatured={handleIsFeatured}
                  categoryData={categoryData}
                  selectedCategories={supplierSelectedCategories}
                  handleCategoryCheck={handleSupplierCategoryCheck}
                />
                <div className="my-lg-3">
                  <h3 className="main-heading text-black fs-5 fw-normal px-4 px-md-3 px-lg-0">
                  {networkTabText("supplier_network")}
                  </h3>
                  <div className="my-sm-4">
                    <BussinessMapView
                      networkData={supplierData}
                      view={"mapView"}
                      type="Supplier"
                    />
                  </div>
                </div>
              </div>
              <div className="row p-0 px-5">
                <div className="col-md-2">
                  <h5 className="fs-5 fw-normal mt-5">
                    {networkTabText("few_popular")} <span className="fw-bold">{networkTabText("suppliers")}</span>
                  </h5>
                  <h6 className="fs-6 fw-light">
                  {networkTabText("explore_all")}
                  </h6>
                </div>
                <div className="col-md-10">
                  <PopularSupplierSection
                    data={popularSupplier}
                    type="Supplier"
                  />
                </div>
              </div>
            </TabPane>
            <TabPane eventKey="buyer-card-view">
              <>
                <div className="d-flex flex-column flex-md-row flex-lg-row  gap-lg-5">
                  <BussinessNetworkSidebar
                    onSearch={handleBuyerSearch}
                    changeRating={handleSupplierRating}
                    handleIsFeatured={handleIsFeatured}
                    categoryData={categoryData}
                    selectedCategories={buyerSelectedCategories}
                    handleCategoryCheck={handleBuyerCategoryCheck}
                  />
                  <div className="my-lg-3">
                  {buyerData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {buyerData.length}+ Companies @ e-procure
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                    <BussinessCombineView
                      networkData={buyerData}
                      type="Buyer"
                    />
                  </div>
                </div>
                <div className="row p-0 px-5">
                  <div className="col-md-2">
                    <h5 className="fs-5 fw-normal mt-5">
                      {networkTabText("few_popular")} <span className="fw-bold">{networkTabText("buyers")}</span>
                    </h5>
                    <h6 className="fs-6 fw-light">
                  {networkTabText("explore_all")}
                    </h6>
                  </div>
                  <div className="col-md-10">
                    <PopularSupplierSection data={popularBuyer} type="Buyer" />
                  </div>
                </div>
              </>
            </TabPane>
            <TabPane eventKey="buyer-map-view">
              <div className="d-flex flex-column flex-md-row flex-lg-row gap-lg-5">
                <BussinessNetworkSidebar
                  onSearch={handleBuyerSearch}
                  changeRating={handleSupplierRating}
                  handleIsFeatured={handleIsFeatured}
                  categoryData={categoryData}
                  selectedCategories={buyerSelectedCategories}
                  handleCategoryCheck={handleBuyerCategoryCheck}
                />
                <div className="my-lg-3">
                  <h3 className="main-heading text-black fs-5 fw-normal px-4 px-md-3 px-lg-0">
                    Buyers Network
                  </h3>
                  <div className="my-sm-4">
                    <BussinessMapView
                      networkData={buyerData}
                      view={"mapView"}
                      type="Buyer"
                    />
                  </div>
                </div>
              </div>
              <div className="row p-0 px-5">
                <div className="col-md-2">
                  <h5 className="fs-5 fw-normal mt-5">
                    {networkTabText("few_popular")} <span className="fw-bold">{networkTabText("buyers")}</span>
                  </h5>
                  <h6 className="fs-6 fw-light">
                  {networkTabText("explore_all")}
                  </h6>
                </div>
                <div className="col-md-10">
                  <PopularSupplierSection data={popularBuyer} type="Buyer" />
                </div>
              </div>
            </TabPane>
            <TabPane eventKey="expert-card-view">
              <div className="d-flex flex-column flex-md-row flex-lg-row gap-lg-5">
                <BussinessNetworkSidebar
                  onSearch={handleExpertSearch}
                  changeRating={handleSupplierRating}
                  handleIsFeatured={handleIsFeatured}
                  categoryData={categoryData}
                  selectedCategories={expertSelectedCategories}
                  handleCategoryCheck={handleExpertCategoryCheck}
                />
                <div className="my-lg-3">
                {expertData.length>0 &&(
                      <>
                      <h3 className="main-heading text-black fs-6 fw-semibold px-4 px-md-3 px-lg-0 mb-0">
                    {expertData.length}+ Professional experts listed
                    </h3>
                        <a href="/sign-in-by-email" className="d-block text-dark fs-7 fw-normal text-decoration-none px-3 px-sm-0">Login to view more</a>
                      </>
                    )}
                  <ExpertCombineView networkData={expertData} />
                  <ExpertSuccessStories />
                  <ExpertCommonCard />
                </div>
              </div>
            </TabPane>
            <TabPane eventKey="expert-map-view">
              <div className="d-flex flex-column flex-md-row flex-lg-row gap-lg-5">
                <BussinessNetworkSidebar
                  onSearch={handleExpertSearch}
                  changeRating={handleSupplierRating}
                  handleIsFeatured={handleIsFeatured}
                  categoryData={categoryData}
                  selectedCategories={expertSelectedCategories}
                  handleCategoryCheck={handleExpertCategoryCheck}
                />
                <div className="my-lg-3">
                  <h3 className="main-heading text-black fs-5 fw-normal px-4 px-md-3 px-lg-0">
                    Expert Network
                  </h3>
                  <div className="my-sm-4">
                    <BussinessMapView
                      networkData={expertData}
                      view={"mapView"}
                    />
                  </div>

                  <ExpertSuccessStories />

                  <ExpertCommonCard />
                </div>
              </div>
            </TabPane>
          </TabContent>
        </TabContainer>
      </div>
    </>
  );
}

export default BussinessNetworkTabs;
