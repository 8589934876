"use client";
import React, { useEffect, useState } from "react";
import { BlogEprocureSection } from "@/components/widgets";
import { Dropdown } from "@/components/bootstrap";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";

const BlogsPage = ({
  blogCategoriesData,
  blogsData: initialBlogsData,
}: any) => {
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (blogCategoriesData) {
      setLoading(false);
    }
  }, [blogCategoriesData]);

  const handleCategoryClick = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  
  const filteredBlogsData =
    selectedCategory === "all"
      ? initialBlogsData
      : initialBlogsData.filter(
          (blog: any) => blog.category && blog.category.id === selectedCategory
        );

  return (
    <>
      <div className="position-relative">
        {!isMobile && (
          <div className="position-absolute top-0 start-50 translate-middle w-100 px-3 px-md-5">
            <ul className="nav justify-content-start shadow rounded-5 p-2 bg-white my-5 nav-tabs-scroll">
              <li
                className={`nav-item cursor-pointer ${
                  selectedCategory === "all" ? "active" : ""
                }`}
              >
                <a
                  className="nav-link"
                  onClick={() => handleCategoryClick("all")}
                >
                  All Blogs
                </a>
              </li>
              {!loading ? blogCategoriesData &&
                blogCategoriesData.map((item: any, index: any) => (
                  <li
                    className={`nav-item cursor-pointer ${
                      selectedCategory === item.id ? "active" : ""
                    }`}
                    key={index}
                  >
                    <a
                      className="nav-link"
                      onClick={() => handleCategoryClick(item.id)}
                    >
                      {item.categoryText}
                    </a>
                  </li>
                ))
              :
              (
                Array.from({ length: 11 }, (_, index) => (
                  <li
                    className="nav-item cursor-pointer"
                    key={index}
                  >
                        <Skeleton borderRadius={5} height={20} width={80} className="mx-2 mt-3" />
                  </li>
                ))
              )
              }
            </ul>
          </div>
        )}
      </div>
      <div className="bg-gradient-gray container-fluid p-md-3 p-lg-5 pt-md-5 text-center">
        {isMobile && (
          <div className="d-flex justify-content-center py-4 ">
            <Dropdown
              show={isOpen}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="outline-dark cursor-pointer"
            >
              <Dropdown.Toggle
                role="button"
                variant=""
                id="dropdown-basic"
                as="span"
                className="cursor-pointer text-black"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-expanded={isOpen}
                aria-haspopup="true"
                placeholder="dropdown"
                style={{
                  background: "white",
                  padding: "20px 60px",
                  borderRadius: "80px",
                  display: "flex",
                  justifyItems: "center",
                }}
              >
                Select Category Name
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-blog-dropdown-menu">
                <Dropdown.Item onClick={() => handleCategoryClick("all")}>
                  All
                </Dropdown.Item>
                {blogCategoriesData &&
                  blogCategoriesData.map((item: any, index: any) => (
                    <Dropdown.Item
                      onClick={() => handleCategoryClick(item.id)}
                      key={index}
                    >
                      {" "}
                      {item.categoryText}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
        <BlogEprocureSection data={filteredBlogsData} />
      </div>
    </>
  );
};

export default BlogsPage;
