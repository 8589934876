import { Button } from "@/components/bootstrap";
import { CallToActionProps } from "@/utils/types";
import { isMobileDevice } from "@/hooks/server-side-mobile";
import { Link } from "@/navigation";

async function CallToActionSecondary({
  heading,
  buttonText,
  buttonLink,
  bgImage,
}: CallToActionProps) {
  const mobile = await isMobileDevice();
  return mobile ? (
    <>
      <div>
        {!!bgImage && (
          <img src={bgImage} alt="" width="100%" height="100%" loading="lazy" />
        )}
      </div>
      <div className="call-to-action-box">
        <h3 className="text-black fs-4 fw-normal mb-5">{heading}</h3>
        <Link
          href={buttonLink ?? ""}
          className="text-white rounded-5 py-3 px-5 custom-cta-btn"
        >
          {buttonText}
        </Link>
      </div>
    </>
  ) : (
    <>
      <div
        className={`call-to-action container d-flex justify-content-end position-relative my-11 `}
      >
        <div className="call-to-action-box">
          <h3 className="text-black fs-4 fw-normal mb-5">{heading}</h3>
          <Link
            href={buttonLink ?? ""}
            className="text-white rounded-5 py-3 px-5 custom-cta-btn"
          >
            {buttonText}
          </Link>
        </div>
        <div>
          {!!bgImage && (
            <img src={bgImage} alt="" width={897} height={450} loading="lazy" />
          )}
        </div>
      </div>
    </>
  );
}

export default CallToActionSecondary;
