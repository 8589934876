"use client";
import { FC, useEffect, useState } from "react";
import { FactsNumbersCard } from "../common";
import { Button } from "@/components/bootstrap";
import { factNumberProps } from "@/utils/types";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";

type props = {
  factsNumbersData: factNumberProps;
};

const NumbersCard: FC<props> = ({ factsNumbersData }) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (factsNumbersData) {
      setLoading(false);
    }
  }, [factsNumbersData]);
  return (
    <div className="home-facts-widget home-widget container px-md-3 px-lg-5 py-4">
      <h3 className="text-black text-center fs-3 my-4 px-sm-0 px-3">
        <span>{factsNumbersData?.headline}</span>
      </h3>

      {isMobile ? (
        <>
          <div className="container fact-card">
            {factsNumbersData?.items
              .slice(0, 2)
              .map((item: any, index: number) => (
                <FactsNumbersCard key={index} item={item} isMobile={isMobile} />
              ))}
          </div>
          <div className="container fact-card-child py-3">
            {factsNumbersData?.items
              .slice(2, 5)
              .map((item: any, index: number) => (
                <FactsNumbersCard key={index} item={item} isMobile={isMobile} />
              ))}
          </div>
          <div className="d-flex justify-content-center my-3">
            <Button
              href={factsNumbersData?.cta?.redirectionUrl}
              type="button"
              className="secondary_btn rounded-5 text-white fs-6"
              size="lg"
            >
              {factsNumbersData?.cta?.label}
            </Button>
          </div>
        </>
      ) : (
        <div className="container fact-card">
          { factsNumbersData?.items
            .slice(0, 2)
            .map((item: any, index: number) => (
              <FactsNumbersCard key={index} item={item} />
            ))
          }
          <div className="fact-card-child">
            {factsNumbersData?.items
              .slice(2, 5)
              .map((item: any, index: number) => (
                <FactsNumbersCard key={index} item={item} />
              ))
          }
          </div>
        </div>
      )}
    </div>
  );
};

export default NumbersCard;
