"use client";
import { FC, useState } from "react";
import { BlogCard } from "../common";
import { Button, Dropdown } from "@/components/bootstrap";
import { useRouter } from "@/navigation";
import { Variants } from "framer-motion";
import { stripHtmlTags } from "@/utils/helpers";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import BlogMobileSection from "./blog-mobile-section";

const ITEMS_PER_PAGE = 3;

const BlogDetailsSection: FC<any> = ({ data, blogCategories }) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [isOpen, setIsOpen] = useState(false);
  const router = useRouter();

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const currentItems = data.slice(0, indexOfLastItem);

  const handleCategoryClick = (categoryId: string) => {
    setSelectedCategory(categoryId);
  };
  const handleMouseEnter = () => {
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    setIsOpen(false);
  };
  const handleLoadMore = () => {
    setCurrentPage((prevPage: any) => prevPage + 1);
  };

  const isLoadMoreDisabled = currentItems.length >= data.length;

  return (
    <>
      <div className="bg-gradient-gray container-fluid p-md-3 p-lg-5 pt-md-5 text-center">
        {isMobile && (
          <div className="d-flex justify-content-center py-4">
            <Dropdown
              show={isOpen}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="outline-dark cursor-pointer"
            >
              <Dropdown.Toggle
                role="button"
                variant=""
                id="dropdown-basic"
                as="span"
                className="cursor-pointer blog-select-cat"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-expanded={isOpen}
                aria-haspopup="true"
                placeholder="dropdown"
              >
                Search other blogs by Category
              </Dropdown.Toggle>
              <Dropdown.Menu className="custom-blog-dropdown-menu">
                <Dropdown.Item onClick={() => handleCategoryClick("all")}>
                  All
                </Dropdown.Item>
                {blogCategories &&
                  blogCategories.map((item: any, index: any) => (
                    <Dropdown.Item
                      onClick={() => handleCategoryClick(item.id)}
                      key={index}
                    >
                      {" "}
                      {item.categoryText}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
      <div
        className={`blog-widget container-fluid p-lg-3 p-lg-5 ${
          isMobile ? "" : "bg-gradient-gray"
        }`}
      >
        <h3 className="text-center fs-4 my-4 my-md-5">
          <span>Other related blogs</span>
        </h3>
        {isMobile ? (
          <>
            <BlogMobileSection item={ITEMS_PER_PAGE} blogs={data && data} />
            <div className="d-flex justify-content-center  my-md-5">
              <Button
                type="button"
                variant="outline-dark"
                className="rounded-5 my-4 my-md-0 "
                size="lg"
                onClick={() => router.replace(`/blog`)}
              >
                All Blogs
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className="row px-md-4 px-lg-5">
              {currentItems.map((item: any, index: number) => {
                const cardVariants: Variants = {
                  hidden: {
                    y: 100,
                  },
                  visible: {
                    y: 0,
                    transition: {
                      duration: 0.5,
                      delay: 0.2 * (index + 1),
                    },
                  },
                };
                return (
                  <BlogCard
                    key={index}
                    heading={stripHtmlTags(item.title)}
                    content={stripHtmlTags(item.headline)}
                    image={item.heroImg}
                    slug={item.seoDetails?.slug}
                    cardVariants={cardVariants}
                  />
                );
              })}
            </div>
            <div className="d-flex justify-content-center my-5">
              <Button
                type="button"
                variant="outline-dark"
                className="rounded-5"
                size="lg"
                onClick={handleLoadMore}
                disabled={isLoadMoreDisabled}
              >
                View More
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BlogDetailsSection;
