"use client";

import { useSettingsContext } from "@/context";
import { useRouter } from "@/navigation";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

function CategoryBox({ categoryItems }: any) {
  const { onUpdate } = useSettingsContext();
  const router = useRouter();
  const [loading, setLoading] = useState(true);
  const handleRedirect = (id: number) => {
    onUpdate("businessCategory", id);
    router.push("/network-tabs");
  };
  useEffect(() => {
    if (categoryItems) {
      setLoading(false);
    }
  }, [categoryItems]);
  return (
    <div>
      {!loading ? (categoryItems.slice(0, 6).map((item: any, index: any) => (
        <div
          key={index}
          className="nav nav-item border border-dark border-1 mx-3 px-2 my-2 rounded-4 cursor-pointer tabs-hover-effect"
          onClick={() => handleRedirect(item.industry_id)}
        >
          <a className="nav-link active" href="#">
            {item.industry_name}
          </a>
        </div>
      ))):(
        Array.from({ length: 8 }, (_, index) => (
          <div
          key={index}
          className="nav nav-item border border-dark border-1 mx-3 px-2 my-2 rounded-4 cursor-pointer tabs-hover-effect"
        >
         <Skeleton height={10} width={390}/>
         <Skeleton height={10} width={300}/>
        </div>
        ))
      )}
    </div>
  );
}

export default CategoryBox;
