"use client";
import React, { useState } from "react";
import { Button } from "@/components/bootstrap";
import ShowAddressModal from "./show-address-modal";

const AddressPopup = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);

  return (
    <>
      <Button
        href="#"
        variant="primary"
        className="text-white rounded-5 px-3 my-sm-2"
        size="sm"
        onClick={() => setModalShow(true)}
      >
        See Address
      </Button>

      <ShowAddressModal
        type="supplier"
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
};

export default AddressPopup;
