"use client";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import BussinessMapView from "./bussiness-map-view";
import { profile_pic } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";

function ExpertCombineView({ networkData }: any) {
  const router = useRouter();
  const handleData = (slug: string) => {
    const encrypt = encodeURIComponent(slug);
    router.push(`/expert-profile/${encrypt}`);
  };
  return (
    <>
      <div className="d-flex flex-column flex-lg-row gap-3 my-sm-4">
        <div className="combine-view-list">
          <div className="combine-view-scroll">
            {networkData &&
              networkData.map((data: any, index: number) => (
                <div
                  className="combine-network-card rounded-2 border bg-white overflow-hidden position-relative cursor-pointer my-2 p-2 mx-2"
                  key={index}
                  onClick={() => handleData(data.slug)}
                >
                  <div className="d-flex align-items-center gap-2">
                    <img
                      loading="lazy"
                      className="rounded-circle border"
                      alt="image"
                      src={
                        data.profile_picture_path
                          ? `${AMAZONE_MEDIA_URL}User/${data.expert_id}/Profile/${data.profile_picture_path}`
                          : profile_pic
                      }
                      width={68}
                      height={68}
                    />

                    <div>
                      <h4 className="main-heading text-black fs-6 fw-normal text-capitalize">
                        {data.first_name} {data.last_name}
                      </h4>
                      <h5 className="main-heading text-black fs-7 fw-light">
                        {data.location}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <BussinessMapView networkData={networkData} view={"combineView"} />
        </div>
      </div>
    </>
  );
}

export default ExpertCombineView;
