"use client";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { ExpertNetworkProps } from "@/utils/types";
import { useRouter } from "@/navigation";
import { Button } from "@/components/bootstrap";
import { profile_pic } from "@/utils/CountryCodes";
import { printCurrency } from "@/utils/helpers";
import { useTranslations } from "next-intl";

const ExpertNetworkCard = ({ id, data, slug }: ExpertNetworkProps) => {
  const router = useRouter();
  const networkTabText = useTranslations("networkTabText")
  const handleData = (slug: string) => {
    const encrypt = encodeURIComponent(slug);
    router.push(`/expert-profile/${encrypt}`);
  };

  function renderHTML(htmlString: string): React.ReactNode {
    return <div dangerouslySetInnerHTML={{ __html: htmlString }} />;
  }

  return (
    <>
      <div
        className="expert-network-card bg-white overflow-hidden position-relative my-3 text-center"
        onClick={() => handleData(slug)}
        style={{ cursor: "pointer" }}
      >
        <div
          className="expert-card-img"
          style={{
            backgroundImage: `url(${
              data.profile_picture_path
                ? `${AMAZONE_MEDIA_URL}User/${data.expert_id}/Profile/${data.profile_picture_path}`
                : profile_pic
            })`,
            width: "100%",
            height: 107,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
        <div className="position-relative" style={{ marginBottom: "70px" }}>
          <div className="position-absolute top-0 start-50 translate-middle">
            <img loading="lazy"
              className="rounded-circle border border-white"
              alt="image"
              src={
                data.profile_picture_path
                  ? `${AMAZONE_MEDIA_URL}User/${data.expert_id}/Profile/${data.profile_picture_path}`
                  : profile_pic
              }
              width={103}
              height={103}
            />
          </div>
        </div>

        <h4 className="main-heading fs-6 fw-bold my-sm-3 text-capitalize expert-heading-color">
          {data.first_name} {data.last_name}
        </h4>

        <div className="my-sm-2 expert-content-height">
          <h4 className="main-heading expert-content-color fs-7 my-sm-2 fw-normal px-2">
            {data.location} <br />
            {data.industry_name}
          </h4>
        </div>
        <h4 className="main-heading expert-heading-color fs-6 fw-semibold px-2">
          {renderHTML(
            printCurrency(data.price_start_range) +
              "-" +
              printCurrency(data.price_end_range)
          )}
        </h4>
        <div className="px-4">
          <hr />
        </div>
        <Button
          href="#"
          variant="outline-dark"
          className="rounded-5 px-4"
          size="sm"
        >
          {networkTabText("hire_now")}
        </Button>
      </div>
    </>
  );
};

export default ExpertNetworkCard;
