"use client";
import React, { useRef, useEffect } from "react";
import { motion, useInView, useAnimation } from "framer-motion";

interface Props {
  title?: string;
  className?: string;
}

const CharacterAnimation = ({ title, className }: Props) => {
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });
  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      mainControls.start("visible");
    }
  }, [isInView]);

  const characters = title?.split("") || [];

  return (
    <div ref={ref}>
      <motion.h4
        variants={{
          hidden: { opacity: 0, y: 20 },
          visible: { opacity: 1, y: 0 },
        }}
        initial="hidden"
        animate={mainControls}
        transition={{ duration: 0.5, delay: 0.1 }}
        className={className}
      >
        {characters.map((char, index) => (
          <motion.span
            key={index}
            initial="hidden"
            variants={{
              hidden: { opacity: 0, y: 20 },
              visible: { opacity: 1, y: 0 },
            }}
            animate={mainControls}
            transition={{ duration: 0.5, delay: 0.2 * (index + 1) }}
          >
            {char}
          </motion.span>
        ))}
      </motion.h4>
    </div>
  );
};

export default CharacterAnimation;
