"use client";
import React, { useEffect, useState } from "react";
import {
  Nav,
  TabContainer,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "@/components/bootstrap";
import ProductListSection from "./product-list";
import { DocumentListSection } from ".";
import AddressPopup from "../common/address-popup";
import RatingReviewSection from "../common/rating-review-section";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { ShowAddressModal } from "../common";
import Skeleton from "react-loading-skeleton";
import { useSettingsContext } from "@/context";

const SupplierNavTabs = ({ productData }: any) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const {onUpdate} = useSettingsContext();
  useEffect(() => {
    if (productData) {
      setLoading(false);
    }
  }, [productData]);
  const handleIconClick = () => {
    onUpdate("redirectionUrl", {
      path: "create RFI for supplier",
      id: productData?.user_id || ""
    });
    setModalShow(true);
  };

  return (
    <>
     {!loading ?  (<TabContainer id="supplierTab" defaultActiveKey={"catalogue"}>
        <Nav
          className="supplier-tabs d-flex justify-content-between"
          id="supplierTab"
          role="tablist"
        >
          <div className="nav nav-tabs-scroll my-2">
            <NavItem className="nav-item">
              <NavLink eventKey="catalogue">Catalogue</NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="documents">Documents</NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="ratings-reviews">Ratings & Reviews</NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="addresses">Addresses</NavLink>
            </NavItem>
          </div>
          <div
            className={`${
              isMobile
                ? "d-flex justify-content-center align-items-center w-100"
                : "d-flex align-items-center "
            } `}
          >
            <Button
              variant=""
              className="rounded-5  border border-dark border-1 text-black px-4"
              onClick={handleIconClick}
            >
              Raise Inquiry
            </Button>
          </div>
        </Nav>
        <TabContent className="tab-content" id="supplierTab">
          <TabPane eventKey="catalogue">
            <div
              className="rounded-5 text-center p-md-5 border my-4"
              style={{ backgroundColor: "#5284D3" }}
            >
              <div className="my-4">
                <h3 className="text-black fw-normal m-0 fs-5">
                  Banners by Suppliers who has paid for the space
                </h3>
                <p className="text-black fw-normal m-0 my-2 fs-5">
                  Revenue generating opportunity
                </p>
              </div>
            </div>
            <ProductListSection data={productData} />
          </TabPane>
          <TabPane eventKey="documents">
            <DocumentListSection data={productData} />
          </TabPane>
          <TabPane eventKey="ratings-reviews">
            <RatingReviewSection data={productData} />
          </TabPane>
          <TabPane eventKey="addresses">
            <AddressPopup data={productData} />
          </TabPane>
        </TabContent>
      </TabContainer>)
    :
    (
      <TabContainer id="supplierTab" defaultActiveKey={"catalogue"}>
        <Nav
          className="supplier-tabs d-flex justify-content-between"
          id="supplierTab"
          role="tablist"
        >
          <div className="nav nav-tabs-scroll my-2">
            <NavItem className="nav-item">
              <NavLink eventKey="catalogue">
                <Skeleton width={90} height={20}/>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="documents">
              <Skeleton width={90} height={20}/>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="ratings-reviews">
              <Skeleton width={90} height={20}/>
              </NavLink>
            </NavItem>
            <NavItem className="nav-item">
              <NavLink eventKey="addresses">
              <Skeleton width={90} height={20}/>
              </NavLink>
            </NavItem>
          </div>
          <div
            className={`${
              isMobile
                ? "d-flex justify-content-center align-items-center w-100"
                : "d-flex align-items-center "
            } `}
          >
            <Skeleton width={90} height={30} borderRadius={50}/>
          </div>
        </Nav>
        <TabContent className="tab-content" id="supplierTab">
          <TabPane eventKey="catalogue">
          <Skeleton height={280} borderRadius={40}/>
          </TabPane>
          <TabPane eventKey="documents">
          <Skeleton height={280} borderRadius={40}/>
          </TabPane>
          <TabPane eventKey="ratings-reviews">
          <Skeleton height={280} borderRadius={40}/>
          </TabPane>
          <TabPane eventKey="addresses">
          <Skeleton height={280} borderRadius={40}/>
          </TabPane>
        </TabContent>
      </TabContainer>
    )  
    }
      <ShowAddressModal
        type="supplier"
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={productData}
      />
    </>
  );
};

export default SupplierNavTabs;
