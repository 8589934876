"use client";
import React, { useEffect, useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { Button } from "@/components/bootstrap";
import { CustomerFeedbackCard } from "../common";
import { FeedbackListProps } from "@/utils/types";
import Skeleton from "react-loading-skeleton";
type CustomerFeedbackSectionProps = {
  feedbackList: FeedbackListProps[];
};

function CustomerFeedbackSection({
  feedbackList,
}: CustomerFeedbackSectionProps) {
  const [page, setPage] = useState<number>(3);
  const [showMore, setShowMore] = useState<boolean>(feedbackList.length > 3);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (feedbackList) {
      setLoading(false);
    }
  }, [feedbackList]);

  const handleClick = (e: any) => {
    e.preventDefault();
    setPage(feedbackList.length);
    setShowMore(false);
  };

  return (
    <div className="px-0 px-md-3 px-lg-5">
      <h3 className="main-heading text-black text-center fs-5 fw-bold bn-section-m mb-4">
        Here’s What Our Customers Say About Us
      </h3>

      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}>
        <Masonry columnsCount={3} gutter="10px">
          {!loading ? (feedbackList &&
            feedbackList.length > 0 &&
            feedbackList.slice(0, page).map((item, index) => (
              <div
              className={` ${index % 2 === 0 ? "mt-md-5" : ""}`}
                key={item.id}
              >
                <CustomerFeedbackCard
                  title={item?.message}
                  author={item?.commentedBy}
                  post={item?.userDesignation}
                />
              </div>
            ))):
            (
              Array.from({ length: 3 }, (_, index) => (
                <div
                className={` ${index % 2 === 0 ? "mt-md-5" : ""}`}
                  key={index}
                >
                     <div className="rounded-5 shadow bg-white p-3 my-4">
                  <p className="fw-light m-0 px-4 mt-5" style={{ minHeight: "120px" }}>
                    <Skeleton count={5}/>
                  </p>
                  <p className="fw-normal m-0 px-4 mt-3">
                  <Skeleton width={100}/>  
                  </p>
                  <p className="fw-light m-0 px-4 mb-3">
                  <Skeleton width={100}/>  
                  </p>
                </div>
                </div>
              ))
            )
            }
        </Masonry>
      </ResponsiveMasonry>
      {!loading ? (showMore && (
        <div className="d-flex justify-content-center">
          <Button
            href="#"
            variant="dark"
            className="text-white rounded-5 get-started-btn"
            size="lg"
            onClick={(e) => handleClick(e)}
          >
            View More
          </Button>
        </div>
      )):(
        <div className="d-flex justify-content-center">
        <Skeleton width={140} height={40} borderRadius={50}/>
        </div>    
      )}
    </div>
  );
}

export default CustomerFeedbackSection;
