"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "react-bootstrap";
import { documentData } from "@/utils/types";
import { DocumentIcon } from "@/utils/helpers";
import ShowAddressModal from "./show-address-modal";

function DocumentListCard({ id, data, title, cardVariants }: documentData) {
  const [modalShow, setModalShow] = useState<boolean>(false);

  return (
    <motion.div variants={cardVariants}>
      <div
        className="rounded-3 border bg-white overflow-hidden position-relative my-2 p-2"
        key={id}
      >
        <div className="d-flex justify-content-between align-items-center">
          <img loading="lazy" src={DocumentIcon(title)} alt="attach_doc" className="mx-2" />
          <h4 className="main-heading text-black fs-6 fw-normal">{title}</h4>
          <h6 className="fw-light fs-6">34kb</h6>
          <Button
            type="button"
            variant="secondary"
            className="text-white rounded-5 px-3 mx-2"
            size="sm"
            onClick={() => setModalShow(true)}
            >
            Download PDF
          </Button>
        </div>
        <ShowAddressModal
          type="supplier"
          show={modalShow}
          onHide={() => setModalShow(false)}
          data={data}
        />
      </div>
    </motion.div>
  );
}

export default DocumentListCard;
