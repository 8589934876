import React from "react";
import { Modal, Button } from "@/components/bootstrap";
import { profile_pic } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL, MAINDOMAINURL, PROTOCOL } from "@/utils/constant";
import { BsXLg } from "react-icons/bs";
import { useRouter } from "@/navigation";

const ExpertLoginSignupModal = ({ show, onHide, data }: any) => {
  const router = useRouter()
  return (
    <>
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      className="otp-modal"
    >
              <div
          className="d-flex justify-content-end  align-items-center cursor-pointer pe-3 pt-2"
          onClick={onHide}
        >
          <BsXLg size={30} />
        </div>
        <div className="text-center py-5">
          <img
            loading="lazy"
            className="rounded-circle border mx-2"
            alt="image"
            src={
              data.profile_picture_path
                ? `${AMAZONE_MEDIA_URL}User/${data.user_id}/Profile/${data.profile_picture_path}`
                : profile_pic
            }
            width={84} 
            height={84}
          />
          <h4 className="fw-bold my-2 supplier-content-color">To hire</h4>
          <h4 className="text-color-danger">{data.name}</h4>
          <div>
          <Button
            onClick={()=>router.push("/sign-in-by-email")}
            className="supplier-content-color sign-in-btn rounded-5 py-2 px-4 my-sm-2 mx-4"
          >
            Sign In
          </Button>
          <Button
            onClick={()=>router.push("/sign-in")}
            variant="secondary"
            className="text-color-white rounded-5 py-2 px-4 my-sm-2 sign-up-btn"
          >
            Sign up for free
          </Button>
        </div>
        </div>
      </Modal>
    </>
  );
};

export default ExpertLoginSignupModal;
