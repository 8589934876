"use client";
import React, { useEffect, useState } from "react";
import { RecentViewCard } from "@/components/common";
import { ProductDataProps, RecentViewMarketplaceProps } from "@/utils/types";
import {
  AMAZONE_MEDIA_URL,
  defaultproduct,
  ThumbImageRatio,
} from "@/utils/constant";
import Skeleton from "react-loading-skeleton";

export default function RecentlyViewedSection({
  title,
  heading,
  result,
}: RecentViewMarketplaceProps) {
const [loading, setLoading] = useState(true);
useEffect(() => {
  if (result) {
    setLoading(false);
  }
}, [result]);
  return (
    <>
      {!loading ? (result?.length>0 && (
        <>
          <h3 className="text-black fs-5 fw-normal mb-4">
            {" "}
            <span className="text-purple"> {title}</span> {heading}
          </h3>
          <div className="row">
            {result?.slice(0, 4).map((item: ProductDataProps) => (
              <div className="col-md-6 col-6 my-2" key={item.id}>
                <RecentViewCard
                  slug={item?.slug}
                  image={
                    item.media_path
                      ? AMAZONE_MEDIA_URL +
                        "User/" +
                        item.supplier_id +
                        "/Product/" +
                        item.id +
                        "/" +
                        ThumbImageRatio +
                        "/" +
                        item.media_path
                      : defaultproduct
                  }
                />
              </div>
            ))}
          </div>
        </>
      )):(
        <>
                  <h3 className="text-black fs-5 fw-normal mb-4">
            {" "}
            <span className="text-purple"> {title}</span> {heading}
          </h3>
          <div className="row">
            {Array.from({ length: 2 }, (_, index) => (
              <div className="col-md-6 col-6 my-2" key={index}>
               <Skeleton height={190} width={270}/>
              </div>
            ))}
          </div>
        </>
      )}
    </>
  );
}
