"use client";
import { FC, useEffect, useState } from "react";
import { ExpertImgCard, ExpertLoginSignupModal } from "../common";
import { ExpertData } from "@/utils/types";
import Slider from "@/components/slick";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import ExpertCardSectionSkeleton from "../skeleton/expert-card-section-skeleton";
type ExpertCardSectionProps = {
  expertData: ExpertData[];
};

const ExpertCardSection: FC<ExpertCardSectionProps> = ({ expertData }) => {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [data, setData] = useState<{}>({});
  const [loading, setLoading] = useState(true);
  const settings = {
    className: "center",
    centerMode: false,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 820,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          centerMode: true,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const hireModalShow = ({ id, image, firstName, lastName }: any) => {
    setModalShow(true);
    setData({
      user_id: id,
      profile_picture_path: image,
      name: firstName + " " + lastName,
    });
  };
  useEffect(() => {
    if (expertData) {
      setLoading(false);
    }
  }, [expertData]);
  return (
    <>
      <div
        className={` ${isMobile ? "" : "slider-wrapper"}`}
      >
        <Slider {...settings}>
          {!loading ? (expertData.map((item: ExpertData, index: number) => {
            return (
              <ExpertImgCard
                key={index}
                id={item.expert_id}
                image={item.profile_picture_path}
                firstName={item.first_name}
                lastName={item.last_name}
                hireModalShow={hireModalShow}
              />
            );
          })) : (
            Array.from({ length: 8 }, (_, index) => (
            <ExpertCardSectionSkeleton key={index} />
            ))
          )}
        </Slider>
      </div>
      <ExpertLoginSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </>
  );
};

export default ExpertCardSection;
