import { PaginationVar } from "@/utils/types";

function ScrollPagination() {
  const paginationVar: PaginationVar = {
    set: false,
    expertcard: {},
    content: {},
    currentPage: 1,
  };

  const configPagination = (contentRef: HTMLElement, cardRef: HTMLElement) => {
    const pagination: PaginationVar = {
      expertcard: {},
      content: {},
      set: true,
      currentPage: 1,
    };

    pagination.expertcard["height"] = cardRef.clientHeight;
    pagination.expertcard["width"] = cardRef.offsetWidth;
    pagination.content["height"] = contentRef.clientHeight;
    pagination.content["width"] = contentRef.offsetWidth;
    pagination["totCard_row"] = Math.floor(
      pagination.content.width / pagination.expertcard.width
    );
    pagination["totCard_col"] = Math.floor(
      pagination.content.height / pagination.expertcard.height
    );
    pagination["totCard"] =
      pagination["totCard_row"] * pagination["totCard_col"];

    return pagination;
  };

  const scrollEvent = (scrollType: string, func: () => void) => {
    const threshold = 150;

    if (
      (scrollType === "vertical" &&
        document.body.offsetHeight - threshold ===
          window.innerHeight + window.scrollY - threshold) ||
      (scrollType === "horizontal" &&
        document.body.offsetWidth - threshold <=
          window.innerWidth + window.scrollX)
    ) {
      func();
    }
    paginationVar.currentPage += 1;
  };

  return { configPagination, scrollEvent, paginationVar };
}

export default ScrollPagination;
