import { InfoCardProps } from "@/utils/types";

function InfoCard({ heading, content, image }: InfoCardProps) {
  return (
    <div className="col-md-4">
      <div className=" p-3 p-lg-5">
        <img
          loading="lazy"
          alt="image"
          src={image}
          width={131}
          height={131}
          className="card-img-contain"
        />
        <h4 className="fs-5 fw-fw-semibold text-black mt-5">{heading}</h4>
        <span dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    </div>
  );
}

export default InfoCard;
