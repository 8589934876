'use client'
import React, { useCallback } from 'react';
import Particles from 'react-tsparticles';
import { loadSlim } from "tsparticles-slim"; // if you are going to use `loadSlim`, install the "tsparticles-slim" package too.
import type { Container, Engine } from "tsparticles-engine";
import { useClientMediaQuery } from '@/hooks/use-client-media-query';


const BackgroundLayoutHome: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const isMobile = useClientMediaQuery("(max-width: 900px)");
    const particlesInit = useCallback(async (engine: Engine) => {
        // you can initialize the tsParticles instance (engine) here, adding custom shapes or presets
        // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
        // starting from v2 you can add only the features you need reducing the bundle size
        //await loadFull(engine);
        await loadSlim(engine);
      }, []);
    
      const particlesLoaded = useCallback(
        async (container: Container | undefined) => {
          // await console.log(container);
        },
        []
      );

  return (
    <div className="background-bubbles">
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
            background: {
                image: "url(https://e-procure-alpha-headless-cms.s3.me-south-1.amazonaws.com/bg_hero_bb3c0eee0a.webp)",
                size: `${isMobile ? "100% 100%" : "cover"}`,
                position: "center",
                repeat: "no-repeat",
              },
              particles: {
                number: {
                  value: 50,
                  density: {
                    enable: true,
                    value_area: 552.4033491425909,
                  },
                },
                color: {
                  value: "random",
                },
                shape: {
                  type: "circle",
                  stroke: {
                    width: 0,
                    color: "#000000",
                  },
                  polygon: {
                    nb_sides: 3,
                  },
                  image: {
                    src: "../assets/img/hero-icon-1.png",
                    width: 70,
                    height: 100,
                  },
                },
                opacity: {
                  value: 0.2,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 2,
                    opacity_min: 0.2,
                    sync: false,
                  },
                },
                size: {
                  value: 4,
                  random: true,
                  anim: {
                    enable: false,
                    speed: 40,
                    size_min: 0.1,
                    sync: false,
                  },
                },
                line_linked: {
                  enable: false,
                  distance: 150,
                  color: "#ffffff",
                  opacity: 0.4,
                  width: 1,
                },
                move: {
                  enable: true,
                  speed: 1.5782952832645452,
                  direction: "none",
                  random: true,
                  straight: false,
                  out_mode: "out",
                  bounce: false,
                  attract: {
                    enable: false,
                    rotateX: 600,
                    rotateY: 1200,
                  },
                },
              },
              interactivity: {
                detect_on: "canvas",
                events: {
                  onhover: {
                    enable: false,
                    mode: "repulse",
                  },
                  onclick: {
                    enable: true,
                    mode: "repulse",
                  },
                  resize: true,
                },
                modes: {
                  grab: {
                    distance: 400,
                    line_linked: {
                      opacity: 1,
                    },
                  },
                  bubble: {
                    distance: 400,
                    size: 40,
                    duration: 2,
                    opacity: 8,
                    speed: 3,
                  },
                  repulse: {
                    distance: 200,
                    duration: 0.4,
                  },
                  push: {
                    particles_nb: 4,
                  },
                  remove: {
                    particles_nb: 2,
                  },
                },
              },
          retina_detect: true,
          fullScreen: {
            enable: true,
          },
        }}
      />
      <div className="content">
        {children}
      </div>
      <style jsx global>{`
        .background-bubbles {
          position: relative;
          min-height: 100vh;
          width: 100%;
          overflow: hidden;
        }
        .content {
          position: relative;
          z-index: 1;
        }
      `}</style>
    </div>
  );
};

export default BackgroundLayoutHome;
