import React from "react";
import { ProgressBar } from "@/components/bootstrap";
import { StarIcon } from "@/utils/icons";

const RatingView = () => {
  return (
    <>
      <div>
        <div className="d-flex justify-content-between gap-1 align-items-center fs-7">
          <div>
            5 <StarIcon />
          </div>
          <ProgressBar
            now={100}
            variant="success"
            className="bar-5 progressbar-css"
          />
          <div className="fw-light">52</div>
        </div>
        <div className="d-flex justify-content-between gap-1 align-items-center fs-7">
          <div>
            4 <StarIcon />
          </div>
          <ProgressBar
            now={30}
            variant="success"
            className="bar-4 progressbar-css"
          />
          <div className="fw-light">15</div>
        </div>
        <div className="d-flex justify-content-between gap-1 align-items-center fs-7">
          <div>
            3 <StarIcon />
          </div>
          <ProgressBar
            now={5}
            variant="success"
            className="bar-3 progressbar-css"
          />
          <div className="fw-light">1</div>
        </div>
        <div className="d-flex justify-content-between gap-1 align-items-center fs-7">
          <div>
            2 <StarIcon />
          </div>
          <ProgressBar
            now={1}
            variant="success"
            className="bar-2 progressbar-css"
          />
          <div className="fw-light">0</div>
        </div>
        <div className="d-flex justify-content-between gap-1 align-items-center fs-7">
          <div>
            1 <StarIcon />
          </div>
          <ProgressBar
            now={15}
            variant="warning"
            className="bar-1 progressbar-css"
          />
          <div className="fw-light">4</div>
        </div>
      </div>
    </>
  );
};

export default RatingView;
