"use client";
import { useRouter } from "@/navigation";
import BussinessMapView from "./bussiness-map-view";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { SupplierBuyerNetworkProps } from "@/utils/types";
import { default_supplier_logo } from "@/utils/CountryCodes";

function BussinessCombineView({ networkData, type }: any) {
  const router = useRouter();
  const handleData = (slug: string) => {
    if (type === "Supplier") {
      router.push(`/supplier-profile/${slug}`);
    } else {
      router.push(`/buyer-profile/${slug}`);
    }
  };
  return (
    <>
      <div className="d-flex flex-column flex-lg-row gap-3 my-sm-4">
        <div className="combine-view-list">
          <div className="combine-view-scroll">
            {networkData &&
              networkData.map((data: any, index: number) => (
                <div
                  className="combine-network-card rounded-2 shadow bg-white overflow-hidden position-relative cursor-pointer my-2 p-2 mx-2"
                  key={index}
                  onClick={() => handleData(data.slug)}
                >
                  <div className="d-flex align-items-center gap-2">
                    <img
                      loading="lazy"
                      className="rounded-circle"
                      alt="image"
                      src={
                        data.business_logo
                          ? `${AMAZONE_MEDIA_URL}User/${data.supplier_id}/BusinessLogo/${data.business_logo}`
                          : default_supplier_logo
                      }
                      width={68}
                      height={68}
                    />

                    <div>
                      <h4 className="main-heading text-black fs-6 fw-normal text-capitalize">
                        {data.supplier_name ?? data.business_name}
                      </h4>
                      <h5 className="main-heading text-black fs-7 fw-light">
                        {data.location}
                      </h5>
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        <div>
          <BussinessMapView networkData={networkData} view={"combineView"} type={type} />
        </div>
      </div>
    </>
  );
}

export default BussinessCombineView;
