import { Button } from "@/components/bootstrap";
import { plansSectionItem } from "@/utils/types";
import { DOMAINNAME, PROTOCOL } from "@/utils/constant";

type Props = {
  item: plansSectionItem;
};
function PlanCard({ item }: Props) {
  const activeDomain = `${PROTOCOL + DOMAINNAME}`;

  return (
    <div className="col-md-4 position-relative">
      <div className="plan-card rounded-4 p-4 bg-white">
        {item.title == "Advanced" && (
          <div className="tag-box">
            <span>Value for money</span>
          </div>
        )}
        <img
          loading="lazy"
          alt="image"
          src={item.imageUrl}
          width={150}
          height="100%"
          className="position-absolute top-0 ms-5 translate-middle card-img-contain"
        />
        <h4 className="fs-5 fw-fw-semibold text-black mt-5">{item.title}</h4>
        <h5 className="fs-6 fw-fw-normal text-black mb-3">{item.headline}</h5>
        <p className="fs-6 fw-fw-lighter text-black plan-content m-0">
          {item.content}
        </p>
        {!!item.statsInfo && (
          <p className="fs-6 fw-fw-lighter text-black m-0">{item.statsInfo}</p>
        )}
        <div className="m-auto d-flex justify-content-center plan-btn-container mt-5">
          <Button
            href={`${activeDomain}/sign-up-buyer`}
            type="button"
            variant={
              item.title === "Starter"
                ? "outline-primary"
                : item.title === "Professional"
                ? "outline-info"
                : "outline-secondary"
            }
            className="rounded-5 plan-btn"
            size="lg"
          >
            {item.title === "Starter"
              ? "Free Trial"
              : item.title === "Professional"
              ? "Subscribe Now"
              : "Get Advanced Plan"}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PlanCard;
