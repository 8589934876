"use client";
import { FC, useState, useEffect } from "react";
import Skeleton from "react-loading-skeleton";
import { BlogCard, ScrollPagination } from "../common";
import { Variants } from "framer-motion";
import { stripHtmlTags } from "@/utils/helpers";
import "react-loading-skeleton/dist/skeleton.css";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import BlogMobileSection from "./blog-mobile-section";

const BlogEprocureSection: FC<any> = ({ data }) => {
  const ITEMS_PER_PAGE = data?.length;
  const [visibleRecords, setVisibleRecords] = useState(ITEMS_PER_PAGE);
  const [loading, setLoading] = useState(true);
  const isMobile = useClientMediaQuery("(max-width: 768px)");

  let paginator = ScrollPagination();

  useEffect(() => {
    setVisibleRecords(ITEMS_PER_PAGE);
    if(data){
      setLoading(false)
    }
  }, [data]);

  // const handleScroll = async (
  //   e: React.UIEvent<HTMLDivElement>,
  //   scrollType: string
  // ) => {
  //   const element = e.target as HTMLDivElement;
  //   const height: any = "140vh";
  //   if (element.scrollHeight - height < element.clientHeight) {
  //     setLoading(true);
  //     await paginator.scrollEvent(scrollType, async () => {
  //       setVisibleRecords((prev) => prev + ITEMS_PER_PAGE);
  //       setLoading(false);
  //     });
  //   }
  // };
  return (
    <div className="blog-widget bg-gradient-gray container-fluid p-md-3 p-lg-5 pt-md-5 pt-md-11 d-flex justify-content-center">
      <div
        className="row px-md-4 px-lg-5 g-md-5 mt-sm-2 hide-scrollbar blog-cards-scroll"
        // onScroll={(e) => handleScroll(e, "horizontal")}
      >
        {isMobile ? (
          <BlogMobileSection item={ITEMS_PER_PAGE} blogs={data && data} />
        ) : (
          <>
            {!loading ? data &&
              data.slice(0, visibleRecords).map((item: any, index: number) => {
                const cardVariants: Variants = {
                  hidden: {
                    y: 100,
                  },
                  visible: {
                    y: 0,
                    transition: {
                      duration: 0.5,
                      delay: 0.2 * (index + 1),
                    },
                  },
                };
                return (
                  <BlogCard
                    key={index}
                    heading={stripHtmlTags(item?.title)}
                    content={stripHtmlTags(item?.headline)}
                    image={item?.heroImg}
                    slug={item?.seoDetails?.slug}
                    cardVariants={cardVariants}
                  />
                );
              })
            :(
              Array.from({ length: ITEMS_PER_PAGE }, (_, index) => (
                <div key={index} className="col-md-4 my-sm-3">
                  <div className="rounded-5 overflow-hidden custom-blog-cards">
                    <Skeleton height={248} />
                    <div className="py-4">
                      <Skeleton height={30} />
                      <Skeleton height={80} count={1} />
                    </div>
                  </div>
                </div>
              ))
            )
            }
          </>
        )}
      </div>
    </div>
  );
};

export default BlogEprocureSection;
