"use client";

function BoxSectionMarketplace() {
  return (
    <div className="item bg-white my-3">
      <div className="row">
        <div className="col-md-3">
          <div className="item text-center bg-white custom-box-section border border-dark border-1 rounded-5"></div>
        </div>
        <div className="col-md-3">
          <div className="item text-center bg-white custom-box-section border border-dark border-1 rounded-5"></div>
        </div>
        <div className="col-md-6">
          <div className="item text-center bg-gray custom-box-section rounded-5"></div>
        </div>
      </div>
    </div>
  );
}

export default BoxSectionMarketplace;
