'use client'
import React, { useEffect, useState } from "react";
import RatingView from "./rating-view";
import ChartView from "./chart-view";
import { StarIconplus } from "@/utils/icons";
import Skeleton from "react-loading-skeleton";

const ExpertDetailsRatingsReview = () => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
      setLoading(false);
  }, []);
  return (
 <>
 {
  !loading ? (
    <div className="rounded-5 bg-white overflow-hidden position-relative my-3">
    <h5
      className="main-heading text-black fw-normal my-4 px-4 text-20"
    >
      Ratings & Reviews
    </h5>
    <div className="row px-4">
      <div className="col-md-3">
        <p className="fw-normal fs-5">
          4.5 <StarIconplus />
        </p>
        <p className="fw-light fs-7">72 Ratings 17 Reviews</p>
      </div>
      <div className="col-md-9">
        <RatingView />
      </div>
    </div>
    <div className="my-4">
      <ChartView />
    </div>
  </div>
  ) : (
    <div className="rounded-5 bg-white overflow-hidden position-relative my-3">
    <h5
      className="main-heading text-black fw-normal my-4 px-4"
    >
      <Skeleton  width={120}/>
    </h5>
    <div className="row px-4">
      <div className="col-md-3">
        <p className="fw-normal fs-5">
        <Skeleton  count={2}/>
        </p>
      </div>
      <div className="col-md-9">
      <Skeleton  height={10} count={8}/>
      </div>
    </div>
    <div className="m-4 d-flex align-items-center gap-2">
    <Skeleton  width={80} height={80} borderRadius={50}/>
    <Skeleton  width={80} height={80} borderRadius={50}/>
    <Skeleton  width={80} height={80} borderRadius={50}/>
    <Skeleton  width={80} height={80} borderRadius={50}/>
    </div>
  </div>
  )
 }
 </>
  );
};

export default ExpertDetailsRatingsReview;
