"use client";
import { RecentViewCardProps } from "@/utils/types";
import {Link} from "@/navigation";

export default function RecentViewCard({ slug, image }: RecentViewCardProps) {
  return (
    <Link href={`/item-detail/${slug}`}>
      <div className="item text-center custom-theme-shadow rounded-5 overflow-hidden cursor-pointer">
        <img
          loading="lazy"
          alt="image"
          className="card__img recent--view-img card-img-cover custom-recently-view"
          src={image}
        />
      </div>
    </Link>
  );
}
