"use client";
import { useRouter } from "@/navigation";
import { BlogCardProps } from "@/utils/types";

function BlogMobileCard({ heading, content, image, slug }: BlogCardProps) {
  const router = useRouter();

  return (
    <div className="col-12 mt-3 d-flex justify-content-center">
      <div
        onClick={() => router.replace(`/blog/${slug}`)}
        className="row cursor-pointer border-top border-bottom overflow-hidden mobile-blog-card"
      >
        <div className="col-5 mobile-card">
          {image ? (
            <img
              loading="lazy"
              alt="image"
              src={image}
              width="100%"
              height="100%"
              className="w-100 h-100"
            />
          ) : (
            <img
              loading="lazy"
              alt="placeholder-image"
              src="./assets/img/elementor-placeholder-image.webp"
              width="100%"
              height="100%"
              className="w-100 h-100"
            />
          )}
        </div>
        <div className="col-7 p-2">
          <h4
            className="main-heading text-black my-2 px-3  fs-6 fw-normal"
            style={{ minHeight: "60px" }}
          >
            {heading}
          </h4>
          <p className="fw-light m-0 px-3">
            {content.substring(0, 50) + "..."}
          </p>
        </div>
      </div>
    </div>
  );
}

export default BlogMobileCard;
