"use client";
import { pulicRfpDataType } from "@/utils/types";
import { motion } from "framer-motion";
import { AMAZONE_MEDIA_URL, blockchain_verified } from "@/utils/constant";
import { default_supplier_logo } from "@/utils/CountryCodes";

function PublicRFPCard({
  id,
  user_id,
  business_name,
  purchase_type,
  business_logo_path,
  cardVariants,
}: pulicRfpDataType) {
  return (
    <motion.div className="col-md-3" variants={cardVariants}>
      <div className="row bg-gray rounded-3 customPublicRFP p-3 align-items-center">
        <div className="col-lg-5 col-md-12 col-5 position-relative">
          <img
            loading="lazy"
            alt="image"
            src={
              business_logo_path
                ? AMAZONE_MEDIA_URL +
                  "User/" +
                  user_id +
                  "/BusinessLogo/" +
                  business_logo_path
                : default_supplier_logo
            }
            className="rounded-4 publicRfpImg"
          />
          <img
            loading="lazy"
            src={blockchain_verified}
            className="verifiedIcon position-absolute bottom-0 start-10"
            alt="verified"
          />
        </div>
        <div className="col-lg-7 col-md-12 col-7">
          <h4 className="text-black fs-6 fw-normal h-50">{business_name}</h4>
          <p className="fw-normal m-0 fs-7">{purchase_type}</p>
        </div>
      </div>
    </motion.div>
  );
}

export default PublicRFPCard;
