"use client";
import { FC, useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import { HeroSectionItems, ServiceCta } from "@/utils/types";
import { ServiceCard } from "../common";
import { Button } from "@/components/bootstrap";
import MobileServiceCard from "../common/mobile-service-card";
import MobileSecondaryServiceCard from "../common/mobile-secondary-service-card";
import Skeleton from "react-loading-skeleton";
// import Link from "next/link";
import { Link } from "@/navigation";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";

type Props = {
  type: "primary" | "secondary";
  servicesData: HeroSectionItems[];
  servicesCta?: ServiceCta;
};

const ServicesSection: FC<Props> = ({
  type,
  servicesData,
  servicesCta,
}) => {
  const [loading, setLoading] = useState(true);
  const isMobile = useClientMediaQuery("(max-width: 768px)");

  useEffect(() => {
    if (servicesData) {
      setLoading(false);
    }
  }, [servicesData]);
  const controls = useAnimation();
  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      const triggerSectionPosition = 450;

      if (scrollY >= triggerSectionPosition) {
        controls.start({ opacity: 0, y: -20 });
      } else {
        controls.start({ opacity: 1, y: 0 });
      }
    };

    if (type === "primary") {
      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    }
  }, [controls]);

  return type === "primary" ? (
    isMobile ? (
      <div
        className={`row business-cards justify-content-sm-between justify-content-center align-items-bottom position-absolute w-100 d-none px-md-5 m-auto top-60 px-3`}
      >
        {servicesData.map((item: HeroSectionItems, index: number) => {
          return <MobileServiceCard key={index} item={item} type={type} />;
        })}
      </div>
    ) : (
      <motion.div
        className={`business-cards row gy-5 justify-content-between align-items-bottom position-absolute bottom-0 start-0 end-0 ${
          isMobile ? "w-100" : "w-97"
        } px-md-5 px-lg-12 m-auto`}
        initial={{ opacity: 1, y: 0 }}
        animate={controls}
        transition={{ duration: 0.3 }}
      >
        
        {servicesData.map((item: HeroSectionItems, index: number) => {
          return <ServiceCard key={index} item={item} type={type} />;
        })}
      
      
      </motion.div>
    )
  ) : (
    <>
      <div className="services-container">
        <div
          className={`row ${
            isMobile ? "" : "g-5"
          } justify-content-center align-items-center`}
        >
          {servicesData.map((item: HeroSectionItems, index: number) => {
            return isMobile ? (
              <MobileSecondaryServiceCard key={index} item={item} type={type} />
            ) : (
              <ServiceCard key={index} item={item} type={type} />
            );
          })
        }
        </div>
      </div>

      {servicesCta !== undefined && (
        <div
          className={`row ${
            isMobile ? "" : "g-5"
          } justify-content-center align-items-center`}
        >
          <Link
            className="secondary_btn text-white rounded-5 px-5 py-3 w-auto my-5 text-decoration-none text-center"
            href={servicesCta?.redirectionUrl ?? ""}
          >
            {servicesCta?.label}
          </Link>
        </div>
      )}
    </>
  );
};

export default ServicesSection;
