'use client'
import React, { useEffect, useState } from "react";
import { SuggestedSupplierBuyerCards } from "../common";
import Skeleton from "react-loading-skeleton";

const SuggestedSupplierBuyerSection = ({ suggestedSeller, title, type }: any) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (suggestedSeller) {
      setLoading(false);
    }
  }, [suggestedSeller]);
  return (
    <div>
      <h3 className="main-heading text-black fs-5 fw-bold my-sm-4">{title}</h3>
      <div className="row">
        {!loading ? (suggestedSeller?.data?.map((item: any, index: number) => {
          return (
            <div className="col-md-6 col-lg-4" key={index}>
              <SuggestedSupplierBuyerCards
                title={item?.business_name}
                industry={item?.industry_name}
                distance={item?.distance}
                location={item?.location}
                id={item?.user_id}
                rating={item?.avg_rating}
                business_logo={item?.business_logo_path}
                slug={item?.slug}
                type={type}
              />
            </div>
          );
        })):
        (
          Array.from({ length: 3 }, (_, index) => (
            <div className="col-md-4" key={index}>
            <div
            className="suggested-network-card rounded-4 border bg-white overflow-hidden position-relative my-2 p-2 cursor-pointer"
          >
            <div className="d-flex align-items-center gap-2">
            <Skeleton height={80} width={80} borderRadius={50}/>
    
              <div className="my-2">
                <h4 className="main-heading text-black fs-6 fw-normal">
            <Skeleton height={40} width={200} />
                </h4>
                <h6 className="main-heading text-black fs-6 fw-light">
                <Skeleton count={2} width={200}/>
                </h6>
              </div>
            </div>
          </div>
          </div>
          ))
        )
        }
      </div>
    </div>
  );
};

export default SuggestedSupplierBuyerSection;
