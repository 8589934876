"use client";
import { Offcanvas, Button } from "@/components/bootstrap";
import { useSettingsContext } from "@/context";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { ArrowRightIcon } from "@/utils/icons";
import { CartProps } from "@/utils/types";
import Lottie from "lottie-react";
import { Link, useRouter } from "@/navigation";
import animationData from "../../utils/animationJson/empty-cart.json";
import StarRatingsFeature from "./star-ratings-feature";
import { useTranslations } from "next-intl";

function Cart({ showCart, handleShow,activeLang }: CartProps) {
  const router = useRouter();
  const { cartItem,currentCountryCurrency,liveCurrencyRate } = useSettingsContext();
  const cartText = useTranslations("cartText")
  const handleRedirect = () => {
    router.push("/cart");
    handleShow();
  };
  const getAdjustedPrice = (price: number) =>
    liveCurrencyRate ? (price * liveCurrencyRate).toFixed(2) : price;
  return (
    <Offcanvas
      show={showCart}
      onHide={handleShow}
      placement="end"
      className="cart-offcanvas"
    >
      <Offcanvas.Header closeButton className="pb-0 justify-content-between">
        <Offcanvas.Title as={"h6"}>{cartText("your_cart")}</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="p-0 cart-body">
        {cartItem?.length > 0 ? (
          <>
            <div className="cart-body-head px-3 py-1 mb-2">
              <p className="cart-items mb-3">{cartItem?.length} Item</p>
              {/* <p className="heading">{cartText("select_this_option_at_checkout")}.</p> */}
              <Button
                onClick={handleRedirect}
                variant="link"
                className="secondary_btn px-3 py-1 text-decoration-none rounded-5"
              >
                {cartText("view_cart")}
              </Button>
            </div>
            {cartItem &&
              cartItem?.map((item: any, index: number) => (
                <div
                key={index} className="ps-3 py-2 cursor-pointer"
                onClick={()=>handleShow()}
                >
                  <Link
                  href={`/item-detail/${item?.slug}`}
                  className="py-2 d-flex gap-4 align-items-start text-black"
                  >
                    <img
                      loading="lazy"
                      alt={item?.title}
                      className="shadow-less rounded-4 p-2"
                      src={
                        item?.media_path
                          ? AMAZONE_MEDIA_URL +
                            "User/" +
                            item?.seller_id +
                            "/Product/" +
                            item?.id +
                            "/" +
                            ThumbImageRatio +
                            "/" +
                            item?.media_path
                          : defaultproduct
                      }
                      width={80}
                      height={80}
                    />
                    <div style={{ minWidth: "220px" }}>
                    <p className="text-12 mb-0 text-capitalize">
                      {item?.title?.length > 60
                        ? item?.title?.substring(0, 60) + '...'
                        : item?.title}
                    </p>
                    {/* / */}
                    {/* <span className="fs-7 fw-light">
                      {item?.inventory_type
                        ? item?.inventory_type
                        : "Product type"}
                    </span> */}
                    <StarRatingsFeature
                    data={item?.avg_rating}
                    starRatedColor="black"
                    starHoverColor="black"
                    starDimension="17px"
                  />
                  <h4 className="text-20 mt-1">{`${getAdjustedPrice(item.price)} ${currentCountryCurrency}`}</h4>
                  </div>
                  </Link>
                </div>
              ))}
          </>
        ) : (
          <>
          <p className="ps-3 py-2 fs-7 text-start">
          {cartText("empty_cart")}
        </p>
          <div className="d-flex flex-column justify-content-between align-items-center">
            <img src="../assets/img/empty-cart.webp" style={{maxWidth:"100%"}} width={150}/>

            <button
                onClick={handleRedirect}
                className="secondary_btn btn rounded-5 text-decoration-none fw-semibold mt-4 fs-7 px-3 py-2 text-white"
              >
                {cartText("view_cart")}
              </button>
          </div>
          </>
        )}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

export default Cart;
