"use client";
import React from "react";
import {Link} from "@/navigation";
interface IBreadCrumbData {
  breadcrumbArray: BreadCrumbArray[];
}

type BreadCrumbArray = {
  text: string;
  link: string;
  categoryId: number | null;
};

const BreadcrumbProduct = ({ breadcrumbArray }: IBreadCrumbData) => {
  return (
    <nav
      aria-label="breadcrumb"
      className="d-flex justify-content-between align-items-center"
    >
      <ol className="breadcrumb">
        {breadcrumbArray.map((breadcrumb: BreadCrumbArray, index: number) => {
          return index + 1 === breadcrumbArray.length ? (
            <li
              key={index}
              className="breadcrumb-item fw-normal active text-capitalize text-14"
              aria-current="page"
              style={{opacity:0.8}}
            >
            {breadcrumb?.text && breadcrumb?.text.length > 20 ? `${breadcrumb?.text.substring(0, 20)}...` : breadcrumb?.text}
            </li>
          ) : (
            <li
              key={index}
              className="breadcrumb-item fw-bold text-14"
            >
              <Link
                href={breadcrumb.link}
                className="p-0 text-decoration-none text-blue"
              >
                {breadcrumb?.text && breadcrumb?.text.length > 20 ? `${breadcrumb?.text.substring(0, 20)}...` : breadcrumb?.text}
              </Link>
            </li>
          );
        })}
      </ol>
    </nav>
  );
};

export default BreadcrumbProduct;
