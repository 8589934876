import React from "react";
import Skeleton from "react-loading-skeleton";

const ProductDetailSkeleton = () => {
  return (
    <div className="col-md-7">
      <div className="overflow-hidden">
        <nav
          aria-label="breadcrumb"
          className="d-flex justify-content-between align-items-center"
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item cursor-pointer">
              <Skeleton width={70} />
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              <Skeleton width={70} />
            </li>
          </ol>
          <div className="d-flex align-items-center gap-2">
            <Skeleton width={40} height={40} borderRadius={50} />
            <Skeleton width={40} height={40} borderRadius={50} />
            <Skeleton width={40} height={40} borderRadius={50} />
          </div>
        </nav>
        <h4 className="main-heading text-black my-2 fs-4 fw-bold ">
          <Skeleton width={400} height={20} />
          <Skeleton width={300} height={20} />
        </h4>
        <div className="row">
          <p
            className="text-black m-0"
          >
            <Skeleton width={100} height={10} />
          </p>
        </div>

        <div className="py-2 gap-2" style={{ display: "flex" }}>
          <Skeleton width={30} height={30} borderRadius={50} />
          <Skeleton width={30} height={30} borderRadius={50} />
          <Skeleton width={30} height={30} borderRadius={50} />
          <Skeleton width={30} height={30} borderRadius={50} />
          <Skeleton width={30} height={30} borderRadius={50} />
        </div>

        <hr />
        <p className="fw-normal text-black m-0 fs-4 fs-4">
          <Skeleton height={30} width={180} borderRadius={10} />
        </p>
        <p className="fw-light text-black m-0 fs-6 ">
          <Skeleton height={10} width={150} borderRadius={10} />
        </p>
        <div className="d-flex justify-content-start gap-md-5 mt-5">
          <div className="d-flex justify-content-center align-items-center gap-2">
            <Skeleton height={20} width={40} />

            <Skeleton height={60} width={60} borderRadius={50} />
            <Skeleton height={20} width={40} />
          </div>
          <div className="mt-2 position-relative d-flex gap-5">
            <Skeleton height={40} width={140} borderRadius={50} />
            <Skeleton height={40} width={140} borderRadius={50} />
          </div>
        </div>

        <div className="mt-3">
          <div
            className="d-md-flex justify-content-between align-items-center mb-3"
            style={{ marginTop: "30px" }}
          >
            <div className="mt-4 w-100">
              <div className="product_detail_icon icon-card-wrapper mx-md-3 bg-white align-items-center d-flex justify-content-between">
                <div className="d-flex align-items-center justify-content-between ">
                  <Skeleton height={90} width={100} borderRadius={10} />
                  <p className="fs-6 fw-light px-4 mt-2">
                    <Skeleton width={300} height={10} />
                    <Skeleton width={300} height={10} />
                  </p>
                </div>
                <Skeleton height={40} width={140} borderRadius={50} />
              </div>
            </div>
          </div>
        </div>

        <div className="my-5">
          <Skeleton width={600} height={20} />
          <hr />
          <Skeleton width={600} height={20} />
          <hr />
          <Skeleton width={600} height={20} />
          <hr />
          <Skeleton width={600} height={20} />
          <hr />
          <Skeleton width={600} height={20} />
          <hr />
        </div>
      </div>
    </div>
  );
};

export default ProductDetailSkeleton;
