"use client";
import { Modal, Button } from "@/components/bootstrap";
import { useRouter } from "@/navigation";
import { useTranslations } from "next-intl";
import { useEffect, useState } from "react";
import { BsXLg } from "react-icons/bs";
import { BsBookmarkFill, BsFillFlagFill } from "react-icons/bs";

interface ShowLoginModalProps { 
  show: boolean;
  onHide: () => void;
  data: string;
}
const ShowProductModal: React.FC<ShowLoginModalProps> = ({
  show,
  onHide,
  data,
}) => {
  const router = useRouter();
  const bookmarkModelText = useTranslations("bookmarkModel")
  return (
    <Modal show={show} onHide={onHide} centered>
      <div className="text-center p-3">
        <div className="d-flex justify-content-end  align-items-center cursor-pointer" onClick={onHide}>
          <BsXLg size={30}/>
        </div>
        <h4 className="text-secondary">{data==='Bookmark' ? <BsBookmarkFill size={40}/>:<BsFillFlagFill size={40}/>}</h4>
        <h4 className="text-secondary fs-5"> {data==='Bookmark' ? 
          bookmarkModelText("title")
        : bookmarkModelText("report-text")}</h4>
            <p className="fw-semibold fs-6 supplier-content-color">
            {bookmarkModelText("description")}
            </p>
            <div>
          <Button
            onClick={()=>router.push("/sign-in-by-email")}
            className="supplier-content-color sign-in-btn rounded-5 py-2 px-4 my-sm-2 mx-4"
          >
          {bookmarkModelText("login")}
          </Button>
          <Button
            onClick={()=>router.push("/sign-in")}
            variant="secondary"
            className="text-color-white rounded-5 py-2 px-4 my-sm-2 sign-up-btn"
          >
          {bookmarkModelText("free-signup")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShowProductModal;
