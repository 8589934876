"use client";
import { ProcurementCardProps } from "@/utils/types";

function ProcurementCard({
  heading,
  percentage,
  content,
}: ProcurementCardProps) {
  return (
    <div className="col d-flex align-items-center">
      <div className="item text-center box-shadow">
        <div className="my-4">
          <h4 className="fs-6 fw-fw-bold text-black">{heading}</h4>
          <h5 className="fs-1 fw-fw-bold" style={{ color: "#504998" }}>
            {percentage}
          </h5>
          <p className="fs-6 fw-fw-bold text-black m-0 mx-3">{content}</p>
        </div>
      </div>
    </div>
  );
}

export default ProcurementCard;
