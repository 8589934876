"use client";
import React, { FC } from "react";
import { Variants } from "framer-motion";
import { ProductCard } from "../common";
import { ProductDataProps } from "@/utils/types";
import { useLocalStorage } from "@/hooks/use-local-storage";

const ProductListSection: FC<{ data: any }> = ({ data }) => {
  const [viewedProducts, setViewedProducts] = useLocalStorage("recentData", []);
  const handleRecentView = (item: ProductDataProps) => {
    if (
      viewedProducts.findIndex(
        (product: ProductDataProps) => product.id === item.id
      ) === -1
    ) {
      const existingArray = [item, ...viewedProducts];
      setViewedProducts(existingArray);
    }
  };

  return (
    <>
      {!data ? (
        <h4 className="my-5 text-center">There isn’t any items here</h4>
      ) : (
        <div className="row">
          {data?.products?.data?.map(
            (item: ProductDataProps, index: number) => {
              const cardVariants: Variants = {
                hidden: {
                  y: 100,
                },
                visible: {
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * (index + 1),
                  },
                },
              };
              return (
                <div className="col-md-4">
                  <div
                    key={index}
                    className="my-2"
                    onClick={() => {
                      handleRecentView(item);
                    }}
                  >
                    <ProductCard
                      heading={item?.title}
                      content={item?.inventory_type}
                      image={item?.media_path}
                      price={item?.price}
                      cardVariants={cardVariants}
                      id={item?.id}
                      rating={item?.avg_rating}
                      supplier_id={item?.user_id}
                      slug={item?.slug}
                      inventoryType={item?.inventory_type}
                      businessLogo={data?.business_logo_path}
                      description={item?.description}
                    />
                  </div>
                </div>
              );
            }
          )}
        </div>
      )}
    </>
  );
};

export default ProductListSection;
