"use client";
import { FC } from "react";
import { ProductBoxCard } from "@/components/common";
import { ProductsData } from "@/utils/types";
import { useTranslations } from "next-intl";

type Props = {
  topRankingProducts: ProductsData[];
  newProducts: ProductsData[];
  popularProducts: ProductsData[];
  rfpProducts: any;
};

const MarketPageSection: FC<Props> = ({
  topRankingProducts,
  newProducts,
  popularProducts,
  rfpProducts,
}) => {
  const MarketplaceText = useTranslations("marketplaceText")
  return (
    <div className="p-4 p-md-5 p-lg-5">
      <div className="row">
        <div className="col-12 col-md-4 my-2">
          {newProducts && (
            <ProductBoxCard title={MarketplaceText("new_arrivals")} data={newProducts} />
          )}
        </div>
        <div className="col-12 col-md-4 my-2">
          {topRankingProducts && (
            <ProductBoxCard title={MarketplaceText("top_ranking")} data={topRankingProducts} />
          )}
        </div>
        <div className="col-12 col-md-4 my-2">
          {newProducts && (
            <ProductBoxCard title={MarketplaceText("weekly_deals")} data={newProducts} />
          )}
        </div>
      </div>

      <div className="row mt-md-4 mt-lg-5">
        {rfpProducts && rfpProducts.length > 0 && (
          <div className="col-12 col-md-4 my-2">
            <ProductBoxCard title={MarketplaceText("public_rfp")} data={rfpProducts} />
          </div>
        )}
        <div className="col-12 col-md-4 my-2">
          <ProductBoxCard title={MarketplaceText("new_catelogues_added")} data={newProducts} />
        </div>
        <div className="col-12 col-md-4 my-2">
          {popularProducts && (
            <ProductBoxCard title={MarketplaceText("promotional")} data={popularProducts} />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarketPageSection;
