"use client";
import {
  AMAZONE_MEDIA_URL,
  ThumbImageRatio,
  defaultproduct,
} from "@/utils/constant";
import { MarketplaceBlogCardProps } from "@/utils/types";
import ProductQuantity from "./product-quantity";
import { useEffect, useState } from "react";
import { useSettingsContext } from "@/context";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { useRouter } from "@/navigation";
import { CloseIcon } from "@/utils/icons";

function CartItemsCard({
  id,
  heading,
  content,
  image,
  price,
  seller_id,
  product_brand_name,
  product_quantity,
  businessLogo,
  seller_slug,
  product_slug,
}: MarketplaceBlogCardProps) {
  const { onUpdate, cartItem, currentCountryCurrency } = useSettingsContext();
  const [quantity, setQuantity] = useState(product_quantity || 1);
  const router = useRouter();
  const incrementQuantity = () => {
    setQuantity(quantity + 1);
  };

  const decrementQuantity = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    } else {
      handleDelete();
    }
  };

  const handleDelete = () => {
    let item = cartItem.filter((item: any) => item?.id != id);
    onUpdate("cartItem", item);
  };

  useEffect(() => {
    if (quantity) {
      let item = cartItem.map((item: any) =>
        item?.id === id ? { ...item, quantity: quantity } : item
      );
      onUpdate("cartItem", item);
    }
  }, [quantity]);

  return (
    <>
      <div className="d-flex flex-sm-row flex-column justify-content-between px-sm-4 px-2 gap-3">
        <div className="d-flex justify-content-center align-items-center gap-3 cursor-pointer"
            onClick={() => router.push(`/item-detail/${product_slug}`)}
        >
          <img
            loading="lazy"
            alt="image"
            className="border rounded-4 cursor-pointer"
            src={
              image
                ? AMAZONE_MEDIA_URL +
                  "User/" +
                  seller_id +
                  "/Product/" +
                  id +
                  "/" +
                  ThumbImageRatio +
                  "/" +
                  image
                : defaultproduct
            }
            width={96}
            height={98}
          />
          <div style={{ width: "150px" }} className="text-truncate">
            <span className="fs-6">{heading}</span>
            <br />
            <span className="fs-7 fw-light">
              {content ? content : "Product type"}
            </span>
            <div className="d-flex justify-content-start align-items-center gap-2 py-1">
              <img
                loading="lazy"
                alt="image"
                className="border rounded-circle cursor-pointer"
                src={
                  businessLogo
                    ? `${AMAZONE_MEDIA_URL}User/${seller_id}/BusinessLogo/${businessLogo}`
                    : default_supplier_logo
                }
                width={33}
                height={33}
                onClick={() => router.push(`/supplier-profile/${seller_slug}`)}
              />
              <span className="fs-7 fw-light">{product_brand_name}</span>
            </div>
          </div>
        </div>

        <ProductQuantity
          quantity={quantity}
          incrementQuantity={incrementQuantity}
          decrementQuantity={decrementQuantity}
        />
        <div className="fw-bold fs-6 my-4 d-sm-block d-flex justify-content-center align-items-center">
          {price !== "" ? (parseFloat(price) * quantity).toFixed(2) : 0} <br />{" "}
          <span className="fw-light fs-7">{currentCountryCurrency} / Product</span>
        </div>
        <div className="x-icon" onClick={handleDelete}>
          <CloseIcon />
        </div>
      </div>
    </>
  );
}

export default CartItemsCard;
