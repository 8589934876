'use client'
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { NetworkCardProps } from "@/utils/types";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

function NetworkCard({ heading, subHeading, bgImage }: NetworkCardProps) {
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
   setTimeout(()=>{
    if (bgImage) {
      setLoading(false);
    }
   },1000)
  }, [bgImage]);
  return (
    <>
  {!loading ? (<div
      className={`call-to-action rounded-5 text-center p-md-5 p-3 bn-cta ${
        bgImage === undefined ? "bg-white" : ""
      }`}
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div>
        <h3 className="text-white fw-bold m-0 fs-3">{heading}</h3>
        <p
          className={`${
            isMobile
              ? "fs-6 text-white m-0 fw-light"
              : "fs-5 text-white m-0 fw-light"
          }`}
        >
          {subHeading}
        </p>
      </div>
    </div>)
  :
  (
    <Skeleton borderRadius={50} height={220} className="mx-2 mt-3" />
  )  
  }
    </>

  );
}

export default NetworkCard;
