"use client";
import { BlogsNavData } from "@/utils/types";

function BlogsNavCard({ title }: BlogsNavData) {
  return (
    <li className="nav-item">
      <a className="nav-link" href="#" rel="noopener noreferrer">
        {title}
      </a>
    </li>
  );
}

export default BlogsNavCard;
