"use client";
import React, { useEffect, useState } from "react";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { EmailOpenIcon, GlobeIcon, PhoneIcon } from "@/utils/icons";
import ShowAddressModal from "./show-address-modal";
import { Button } from "@/components/bootstrap";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import Skeleton from "react-loading-skeleton";
import { useSettingsContext } from "@/context";

const BuyerDetailsCard = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const isMobile = useClientMediaQuery("(max-width: 768px)");
  const [loading, setLoading] = useState(true);
  const {onUpdate} = useSettingsContext()
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const handleIconClick = () => {
    onUpdate("redirectionUrl", {
      path: "send invitation to buyer",
      id: data?.user_id || ""
    });
    setModalShow(true);
  };
  return (
 <>
 {
  !loading ? (
    <div className="rounded-5 bg-white p-4">
    <div className="row">
      <div className="col-md-1">
    <img loading="lazy"
      className="rounded-circle border"
      alt="image"
      src={
        data.business_logo_path
          ? AMAZONE_MEDIA_URL +
            "User/" +
            data.user_id +
            "/BusinessLogo/" +
            data.business_logo_path
          : default_supplier_logo
      }
      width={84}
      height={84}
    />
    </div>
    <div className="col-md-8 px-5">
    <p className="fw-bold m-0 my-3 fs-5 supplier-content-color">
      {data?.business_name}
    </p>
    <p className="fw-normal fs-7 px-2 py-1 border rounded-2 text-start d-inline supplier-content-color">
      {data?.industry_name}
    </p>
    <p className="fw-light fs-7 m-0 my-3 supplier-content-color">
      {data?.business_address}
    </p>
    <div
          className={`${
            isMobile
              ? "d-flex justify-content-center align-items-center w-100"
              : "d-flex align-items-center "
          } `}
        >
          <Button
            variant=""
            className="rounded-5  border border-dark border-1 text-black px-4"
            onClick={handleIconClick}
          >
            Raise Inquiry
          </Button>
        </div>
    </div>
    <div className="col-md-3">
    <div className="d-flex gap-5 m-auto">
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
          <PhoneIcon />
        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
          Call <br /> Now
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
          <EmailOpenIcon />
        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
          Email <br /> Now
        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
          <GlobeIcon />
        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
          Visit <br /> Website
        </p>
      </div>
    </div>
    </div> 
  </div>
      </div>
  ) : (
    <div className="rounded-5 bg-white p-4">
    <div className="row">
      <div className="col-md-1">
      <Skeleton height={100} width={100} borderRadius={50} />

    </div>
    <div className="col-md-8 px-5">
    <p className="fw-bold m-0 my-3 fs-5 supplier-content-color">
    <Skeleton width={300}/>
    </p>
    <Skeleton width={240} height={20}/>
    <p className="fw-light fs-7 m-0 my-3 supplier-content-color">
    <Skeleton width={180} height={10}/>
    </p>
    <div
          className={`${
            isMobile
              ? "d-flex justify-content-center align-items-center w-100"
              : "d-flex align-items-center "
          } `}
        >
                     <Skeleton height={40} width={170} borderRadius={50} />

        </div>
    </div>
    <div className="col-md-3">
    <div className="d-flex gap-5 m-auto">
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
        <Skeleton height={40} width={40} borderRadius={50} />

        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
        <Skeleton count={2} width={20} height={10} />

        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
        <Skeleton height={40} width={40} borderRadius={50} />

        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
        <Skeleton count={2} width={20} height={10} />

        </p>
      </div>
      <div
        className="d-flex flex-column align-items-center cursor-pointer"
        onClick={handleIconClick}
      >
        <div className="bg-white rounded-circle border suppliers-circle-container">
        <Skeleton height={40} width={40} borderRadius={50} />

        </div>
        <p className="fs-7 fw-light my-sm-2 text-center">
        <Skeleton count={2} width={20} height={10} />

        </p>
      </div>
    </div>
    </div> 
  </div>

      </div>
  )
 }
   <ShowAddressModal
      type="buyer"
      show={modalShow}
      onHide={() => setModalShow(false)}
      data={data}
      />
 </>
  );
};

export default BuyerDetailsCard;
