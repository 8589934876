import React from "react";
import { Modal, Button } from "@/components/bootstrap";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";
import { BsXLg } from "react-icons/bs";
import { useRouter } from "@/navigation";

const ShowAddressModal = ({ show, onHide, data }: any) => {
const router = useRouter();
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      centered
      style={{
        width: "625px",
        height: "482px",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
              <div
          className="d-flex justify-content-end  align-items-center cursor-pointer pe-3 pt-2"
          onClick={onHide}
        >
          <BsXLg size={30} />
        </div>
      <div className="text-center py-5">
        {data?.businesslogo_path !== undefined ? (
          <img
            loading="lazy"
            className="rounded-circle border mx-2"
            alt="image"
            src={
              data?.businesslogo_path
                ? AMAZONE_MEDIA_URL +
                  "User/" +
                  data.id +
                  "/BusinessLogo/" +
                  data.businesslogo_path
                : default_supplier_logo
            }
            width={84}
            height={84}
          />
        ) : (
          <img
            loading="lazy"
            className="rounded-circle border mx-2"
            alt="image"
            src={
              data?.business_logo_path
                ? AMAZONE_MEDIA_URL +
                  "User/" +
                  data?.user_id +
                  "/BusinessLogo/" +
                  data?.business_logo_path
                : default_supplier_logo
            }
            width={84}
            height={84}
          />
        )}

        <h4 className="fw-bold my-2 supplier-content-color">To Connect With</h4>
        <h4 className="text-color-danger">{data?.business_name}</h4>

        <div>
          <Button
            onClick={()=>router.push("/sign-in-by-email")}
            className="supplier-content-color sign-in-btn rounded-5 py-2 px-4 my-sm-2 mx-4"
          >
            Sign In
          </Button>
          <Button
            onClick={()=>router.push("/sign-in")}
            variant="secondary"
            className="text-color-white rounded-5 py-2 px-4 my-sm-2 sign-up-btn"
          >
            Sign up for free
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShowAddressModal;
