import { FC, ReactElement } from "react";
import { OfferSectionData } from "@/utils/types";

type OfferSectionProps = {
  children?: ReactElement;
  offersSectionData: OfferSectionData;
};

const OfferSection: FC<OfferSectionProps> = ({
  children,
  offersSectionData,
}) => {
  return (
    <div className="home-offer-widget container-fluid p-md-3  p-lg-5">
      <div className="text-center my-5">
        <div
          dangerouslySetInnerHTML={{ __html: offersSectionData?.title || offersSectionData || "" }}
        />
        <p className="fw-normal fs-6 px-4 px-md-0 px-lg-0">
          {offersSectionData?.headline || ""}
        </p>
      </div>

      {!!children && children}
    </div>
  );
};

export default OfferSection;
