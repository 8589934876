import { NetworkBannerData } from "@/utils/types";

function NetworkBanner({ id, heading, content }: NetworkBannerData) {
  return (
    <div className="network-banner rounded-5 text-center p-5 border border-dark bg-white">
      <div className="my-4">
        <h3 className="text-black fw-normal m-0 fs-5">{heading}</h3>
        <p className="text-black fw-normal m-0 fs-5">{content}</p>
      </div>
    </div>
  );
}

export default NetworkBanner;
