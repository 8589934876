import { CallToActionProps } from "@/utils/types";

function InfoCardSecondary({
  heading,
  subHeading,
  bgColor,
  image,
}: CallToActionProps) {
  return (
    <div
      className={`infor-card-secondary justify-content-between d-flex align-items-center flex-column flex-md-row flex-lg-row p-5`}
      style={{
        backgroundColor: bgColor,
      }}
    >
      <div className="p-2 p-md-0 my-3 my-md-0 text-start p-lg-5">
        <h3 className="text-white fw-semibold text-black fs-3">{heading}</h3>
        <p className="text-white text-black m-0 fs-6 fw-normal">{subHeading}</p>
      </div>
      {image}
    </div>
  );
}

export default InfoCardSecondary;
