"use client";
import { FC } from "react";
import { ProductImg } from "../common";
import { ProductsData } from "@/utils/types";
import Slider from "@/components/slick";

const ProductImgSection: FC<{ popularProduct: ProductsData[] }> = ({
  popularProduct,
}) => {
  const sliderSettings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0px",
    slidesToShow: 5,
    speed: 500,
    arrows: false,
    dots: false,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          initialSlide: 5,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 3,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: true,
          centerPadding: "0",
        },
      },
    ],
  };

  return (
    <div>
      <Slider {...sliderSettings}>
        {popularProduct.map((item: ProductsData, index: number) => (
          <ProductImg
            key={index}
            image={item.media_path}
            id={item?.id}
            supplier_id={item?.supplier_id}
            fetchImagePath={undefined}
            slug={item.slug}
          />
        ))}
      </Slider>
    </div>
  );
};

export default ProductImgSection;
