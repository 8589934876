'use client'
import { OfferCardProps } from "@/utils/types";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

function OfferCard({ title, content, src, bgColor }: OfferCardProps) {
  const [loading, setLoading] = useState(true);
  useEffect(()=>{
    setLoading(false);
  },[])
  return (
    <div className="col-lg-4 col-md-6">
     {!loading ? (
      <div
        className="rounded-4 p-4 offer-card-box"
        style={{ background: bgColor }}
      >
        <div className="d-flex justify-content-between align-items-end position-relative mb-1">
          <h4 className="text-black fw-normal fs-5 pt-2">{title}</h4>
          {!!src && (
            <img
              src={src}
              alt={title}
              width={92}
              height={92}
              loading="lazy"
              className="card-img-contain"
            />
          )}
        </div>
        <h5 className="text-black fw-light fs-6">{content}</h5>
      </div>
      ):(
     <div
        className="rounded-4 p-4 offer-card-box shadow bg-white"
      >
        <div className="d-flex justify-content-between align-items-end position-relative mb-1">
          <h4 className="text-black fw-normal fs-5 pt-2">
            <Skeleton width={120}/>
          </h4>
          <Skeleton height={50} width={50} borderRadius={50}/>
        </div>
        <h5 className="text-black fw-light fs-6">
        <Skeleton width={250} count={5}/>
        </h5>
      </div>
      )}
    </div>
  );
}

export default OfferCard;
