"use client";
import React, { FC } from "react";
import { Variants } from "framer-motion";
import { DocumentListCard } from "../common";
import { Button } from "@/components/bootstrap";

const DocumentListSection: FC<{ data: any }> = ({ data }) => {

  return (
    <>
      {!data?.business_documents ? (
        <div className="d-flex gap-3 align-items-center">
          <img src="/assets/network-tabs/no-documents.webp" alt="document"  />
          <div>
          <h5 className="fw-bold">Looks like the supplier haven’t added the documents. You will need to login to view.</h5>
          <Button
              href="/sign-in-by-email"
              variant="secondary"
              className="text-color-white rounded-5 py-2 px-4 my-sm-2 sign-up-btn"
              target="_blank"
            >
              Login Now
            </Button>
          </div>
        </div>
      ) : (
        <div className="row">
          {data?.business_documents?.map((item: any, index: number) => {
            const cardVariants: Variants = {
              hidden: {
                y: 100,
              },
              visible: {
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2 * (index + 1),
                },
              },
            };
            return (
              <DocumentListCard
                id={item.media_id}
                data={data}
                title={item?.media_title}
                cardVariants={cardVariants}
              />
            );
          })}
        </div>
      )}
    </>
  );
};

export default DocumentListSection;
