"use client";
import { ProcurementCardProps } from "@/utils/types";
import Skeleton from "react-loading-skeleton";

function ProcurementCardSkeleton() {
  return (
    <div className="col d-flex align-items-center">
      <div className="item text-center box-shadow">
        <div className="my-4">
          <h4 className="fs-6 fw-fw-bold text-black"> <Skeleton width={140}/></h4>
          <h5 className="fs-1 fw-fw-bold" style={{ color: "#504998" }}>
          <Skeleton height={80} width={100}/>
          </h5>
          <p className="fs-6 fw-fw-bold text-black m-0 mx-3"><Skeleton width={140} count={2}/></p>
        </div>
      </div>
    </div>
  );
}

export default ProcurementCardSkeleton;
