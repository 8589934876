import React from "react";
import { Modal, Button } from "@/components/bootstrap";
import { useRouter } from "@/navigation";


const NoMoreCompareModal = ({ show, onHide, data }: any) => {
  const router = useRouter();
  const handleRedirect = () => {
    router.push("/compare");
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      style={{borderRadius:"30px"}}
    >
      <div className="text-center py-5">
        <img src="../assets/search/5.webp" width={140} height={140} alt="compare" />
        <p>{data}</p>
        <div className="d-flex gap-5 align-items-center justify-content-center">
          <Button
            href="#"
            variant="outline-secondary"
            className="rounded-5 px-5 py-2 my-sm-2 close-btn"
            onClick={onHide}
          >
            Close
          </Button>
          <Button
            href="#"
            variant="secondary"
            className="text-color-white rounded-5 px-3 py-2 my-sm-2 view-compare-btn"
            onClick={handleRedirect}
          >
            View Compare
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default NoMoreCompareModal;
