"use client";
import React, { useEffect, useState } from "react";
import { useRouter } from "@/navigation";
import ProductMediaBox from "../widgets/product-media-box";
import ProductQuantity from "./product-quantity";
import StarRatingsFeature from "./star-ratings-feature";
import { ProductDetailAccordian, ProdcuctIconCard } from "@/components/widgets";
import { Button } from "@/components/bootstrap";
import MarketplaceSignupModal from "./marketplace-signup-modal";
import { useSettingsContext } from "@/context";
import { ProductDetailProps } from "@/utils/types";
import { BsBookmark, BsFillFlagFill, BsFillShareFill } from "react-icons/bs";
import ShowProductModal from "./product-detail-signup-modal";
import NoMoreCompareModal from "../widgets/no-more-compare-modal";
import CopyLinkModal from "./copy-link-modal";
import Skeleton from "react-loading-skeleton";
import ProductDetailSkeleton from "../skeleton/product-detail-skeleton";
import BreadcrumbProduct from "./breadcrumb-product";
import { useTranslations } from "next-intl";
interface IconOption {
  title: string;
  icon: JSX.Element; // Specify that the icon is a React element
}
function ProductDetail(props: ProductDetailProps) {
  const {
    id,
    title,
    price,
    description,
    media_path,
    avg_rating,
    supplier_id,
    seller_id,
    seller_slug,
    product_media,
    product_brand_name,
    warranty_info,
    specification,
    information,
    total_reviews,
    inventory_type,
    business_logo,
    quantity,
    slug,
    product_category_name,
    product_category_id
  } = props.productData;
  const router = useRouter();
  const [modalShow, setModalShow] = useState(false);
  const [iconModalShow, setIconModalShow] = useState(false);
  const [showContent, setShowContent] = useState<string>("")
  const [isAddedToCart, setIsAddedToCart] = useState<boolean>(false);
  const [productQuantity, setProductQuantity] = useState<number>(1);
  const { onUpdate, cartItem, compareItem,liveCurrencyRate, currentCountryCurrency } = useSettingsContext();
  const [isAddToCart, setIsAddToCart] = useState<boolean>(false);
  const [isAddToCompare, setIsAddToCompare] = useState<boolean>(false);
  const [compareModalShow, setCompareModalShow] = useState<boolean>(false);
  const [showQuantity,setShowQuantity] = useState<boolean>(false)
  const [hostUrl, setHostUrl] = useState<string>("");
  const [showLink, setShowLink] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [compareShow, setCompareShow] = useState<any>([]) 
  const shareModalText = useTranslations("bookmarkModel")
  const productDetailText = useTranslations("productDetail")

  useEffect(() => {
    if (compareItem) {
      setCompareShow(compareItem);
    }
  }, [compareItem]);
  useEffect(()=>{
    if(props.productData){
      setLoading(false)
    }
  },[props.productData])

  const incrementQuantity = () => {
    setProductQuantity(productQuantity + 1);
    setShowQuantity(true)
  };

  const decrementQuantity = () => {
    if (productQuantity > 1) {
      setProductQuantity(productQuantity - 1);
      setShowQuantity(true)
    }
  };

  useEffect(()=>{
    let existingItem = cartItem.find((item) => item.id === id);
    if (existingItem) {
      setShowQuantity(false)
      const newData = cartItem.map((item:any) =>
        item.id === id ? { ...item, quantity: productQuantity } : item
      );
      onUpdate("cartItem", [...newData]);
    }else{
      setShowQuantity(false)
    }
  },[showQuantity])

  useEffect(()=>{
    let existingItem = cartItem.find((item) => item.id === id);
    if(existingItem){
      setProductQuantity(existingItem?.quantity ?? productQuantity)
    }
  },[cartItem])

  const data = {
    id,
    title,
    price,
    description,
    media_path,
    avg_rating,
    supplier_id,
    seller_id,
    seller_slug,
    product_media,
    product_brand_name,
    warranty_info,
    specification,
    information,
    total_reviews,
    inventory_type,
    business_logo,
    quantity,
    slug,
  };

  const handleModalShow = () => {
    setModalShow(!modalShow);
  };

  useEffect(()=>{
    const cartStatus=cartItem?.some(cartItem => cartItem.id === id)
    const compareStatus = compareItem?.some(compareItem => compareItem?.id === id)
    setIsAddToCart(cartStatus);
    setIsAddToCompare(compareStatus)
  },[cartItem,compareItem])

  const onAddToCart = () => {
    const cartStatus=cartItem.some(cartItem => cartItem.id === id)
    setIsAddToCart(cartStatus);
    let existingItem = cartItem.find((item) => item.id === data.id);
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 0) + productQuantity;
      setShowQuantity(false)
    } else {
      data.quantity = productQuantity;
      cartItem.push(data);
      setShowQuantity(false)
    }
    onUpdate("cartItem", [...cartItem]);
    setIsAddedToCart(true);
    onUpdate("cartModal", true);
  };

  const onAddToCompare = () => {
   if(compareItem?.length<3){    
    const compareStatus = compareItem?.some(compareItem => compareItem?.id === id)
    setIsAddToCompare(compareStatus)
    let existingItem = compareItem?.find((item) => item?.id === data.id);
    if (existingItem) {
      existingItem.quantity = (existingItem.quantity ?? 0) + productQuantity;
    } else {
      data.quantity = productQuantity;
      compareItem.push(data);
    }
    onUpdate("compareItem", [...compareItem]);
    onUpdate("compareModal", true);}
    else{
      setCompareModalShow(true)
    }
  };
  const showIconData: IconOption[] = [
    { title: "Bookmark", icon: <BsBookmark size={16}/> },
    { title: "Report", icon: <BsFillFlagFill size={16}/> },
    { title: "Share", icon: <BsFillShareFill size={16}/> },
  ];

  const onClickIcon=(data:string)=>{
    if (typeof window !== "undefined") {
      const url = window.location.href
      setHostUrl(url);
    }
    if(data==="Share"){
      setShowLink(true)
    }else{
      setIconModalShow(true)
    }
    setShowContent(data)
  }
  const breadcrumbArray = [
    {
      text: "B2B Marketplace",
      link: "/marketplace",
      categoryId: null
    },
    {
      text: `${product_category_name}`,
      link: "/marketplace",
      categoryId: product_category_id,
    },
    {
    text: `${title}`,
    link: "/",
    categoryId: null
    }
]
  // Adjust price based on conversion rate
  const getAdjustedPrice = (price: number) =>
    liveCurrencyRate ? (price * liveCurrencyRate).toFixed(2) : price;
  return (
    <div>
      <div className="row">
        <div className="col-md-5 sticky-box">
          <ProductMediaBox
            media_path={media_path}
            supplier_id={seller_id}
            id={id}
            imgData={product_media}
          />
        </div>
        {!loading ? (<div className="col-md-7">
          <div className="overflow-hidden">
            <div className="row align-items-baseline">
            <div className="col-md-9">
          <BreadcrumbProduct breadcrumbArray={breadcrumbArray}/>
          </div>
          <div className="col-md-3">
          <div  className="d-flex align-items-center gap-2">
                {
                  showIconData.map((item,index)=>(
                    <div key={index}>
                    <div className="product-icon" onClick={()=>onClickIcon(item?.title)}>
                    {item?.icon}
                    </div>
                  </div>
                  ))
                }
              </div>
              </div>
              </div>
            <h4 className="main-heading text-black my-2 fs-4 fw-bold ">
              {title}
            </h4>
            <div className="row">
              <p className="text-black m-0 pe-5" style={{fontSize:'15px',fontWeight:"200"}}>{description}</p>
            </div>

            <div className="py-2" style={{ display: "flex" }}>
              <StarRatingsFeature
                data={avg_rating}
                starRatedColor="black"
                starHoverColor="black"
                starDimension="22px"
              />{" "}
              <div style={{ marginLeft: "20px", paddingTop: "3.5px" }}>
                {avg_rating ? avg_rating : 0} / 5
              </div>
            </div>

            <hr />
            <p className="fw-normal text-black m-0 fs-4 fs-4">
            {`${getAdjustedPrice(price)} ${currentCountryCurrency}`}
            </p>
            <p className="fw-light text-black m-0 fs-6 ">
              ( Including tax and shipping )
            </p>
            <div className="d-flex justify-content-start gap-md-5 mt-5">
              <ProductQuantity
                quantity={productQuantity}
                incrementQuantity={incrementQuantity}
                decrementQuantity={decrementQuantity}
              />
              <div className="mt-2 position-relative">
                <button
                  type="submit"
                  disabled={isAddToCart}
                  className="text-white rounded-5 secondary_btn get-started-btn btn"
                  onClick={() => onAddToCart()}
                  style={{cursor:`${isAddToCart ? "not-allowed": "pointer"}`,pointerEvents:`${isAddToCart ? "auto": "auto"}`}}
                >
                  {isAddToCart ? productDetailText("already_added") : productDetailText("add_to_cart")}
                </button>
                {isAddedToCart && (
              <div className="w-100 text-center position-absolute">
                <p className="text-success text-center my-2">
                  {productDetailText("successfully_added_to_cart")}
                </p>
              </div>
            )}
              </div>

              <div className="mt-2">
                <button type="submit"  
                className="text-dark primary_btn rounded-5 get-started-btn btn"
                disabled={isAddToCompare}
                onClick={()=> onAddToCompare()}
                style={{cursor:`${isAddToCompare ? "not-allowed": "pointer"}`,pointerEvents:`${isAddToCompare ? "auto": "auto"}`}}
                >
                {productDetailText("compare_now")}
                </button>
              </div>
            </div>

            <div className="mt-3">
              <div
                className="d-md-flex justify-content-between align-items-center mb-3"
                style={{ marginTop: "30px" }}
              >
                <div className="mt-4 w-100">
                  <ProdcuctIconCard
                    icon={business_logo}
                    title={productDetailText("Ask_supplier")}
                    time={productDetailText("time")}
                    handleModalShow={handleModalShow}
                    supplier_id={seller_id}
                    seller_slug={seller_slug}
                    id={id}
                  />
                </div>
              </div>
            </div>

            <div className="my-5">
              <ProductDetailAccordian
                description={description}
                warranty_info={warranty_info}
                specification={specification}
                information={information}
                total_reviews={total_reviews}
                avg_rating={avg_rating}
              />
            </div>
          </div>
        </div>):(
        <ProductDetailSkeleton />
        )}
      </div>

      <MarketplaceSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        id={id}
        title={title}
        media_path={media_path}
        supplier_id={seller_id}
      />
      <ShowProductModal 
      show={iconModalShow}
      data={showContent}
      onHide={() => setIconModalShow(false)}
      />
      <CopyLinkModal
      show={showLink}
      onHide={() => setShowLink(false)}
      title={shareModalText("share-text")}
      url={hostUrl}
      isIcon={true}
      iconName={<BsFillShareFill size={40} />}
      />
      <NoMoreCompareModal
        type="supplier"
        show={compareModalShow}
        onHide={() => setCompareModalShow(false)}
        data={"You can maximum add 3 product to compare!"}
      />
      {compareShow && compareShow?.length >1 && <Button 
      href="/compare"
      className="compare-sticky-btn">
      Compare
      </Button>}
    </div>
  );
}

export default ProductDetail
