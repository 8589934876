"use client";
import { motion } from "framer-motion";
import { useRouter } from "@/navigation";
import { BlogCardProps } from "@/utils/types";

function BlogCard({
  heading,
  content,
  image,
  slug,
  cardVariants,
}: BlogCardProps) {
  const router = useRouter();

  const handleData = (slug: any) => {
    const encrypt = encodeURIComponent(slug);
    router.replace(`/blog/${encrypt}`);
  };

  return (
    <motion.div
      className="col-lg-4 col-md-6 my-3 d-flex justify-content-center"
      variants={cardVariants}
    >
      <div onClick={() => handleData(slug)} className="cursor-pointer">
        <div className="rounded-5 shadow overflow-hidden custom-blog-cards">
          {image ? (
            <img
              alt={heading}
              src={image}
              width={377}
              height={248}
              className="w-100"
              fetchPriority="high"
            />
          ) : (
            <img
              alt="placeholder-image"
              src="/assets/img/elementor-placeholder-image.webp"
              width={377}
              height={248}
              className="w-100"
              fetchPriority="high"
            />
          )}
          <h4
            className="main-heading text-start text-black mt-4 fs-6 fw-normal px-4 "
            style={{ minHeight: "60px" }}
          >
            {heading}
          </h4>
          <p className="fw-light m-0 px-4 pb-4 text-start" style={{ minHeight: "87px" }}>
            {content.substring(0, 90) + "..."}
          </p>
        </div>
      </div>
    </motion.div>
  );
}

export default BlogCard;
