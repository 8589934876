"use client";
import React, { useState, useEffect, useRef } from "react";
import { fetchAllCategoryData } from "@/utils/apiServices";
import Slider from "react-slick";
import Skeleton from "react-loading-skeleton";

function MarketTabs({ setSelectedCategory }: any) {
  const [categoryData, setCategoryData] = useState<any>([]);
  const [loading, setLoading] = useState(true);
  const [select, setSelect] =   useState<string>()
  const sliderRef = useRef(null); 

  useEffect(() => {
    const fetchCategoryData = async () => {
      try {
        const result = await fetchAllCategoryData();
        setCategoryData(result);
        return result;
      } catch (error) {
        console.error("Error fetching category data:", error);
      } finally {
        setLoading(false)
        console.log("Fetch category data process completed.");
      }
    };
  
    fetchCategoryData();
  }, []);
  
  const handleSelect = (id: number,text: string) => {
    setSelect(text)
    setSelectedCategory(id,text);
  };

  const sliderSettings = {
    className: "center",
    arrows: false,
    dots: false,
    infinite: true,
    centerMode: true,
    centerPadding: "0",
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          centerMode: true,
          centerPadding: "0",
        },
      },
      {
        breakpoint: 576,
        settings: {
          centerMode: true,
          centerPadding: "0",
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <div className="container-fluid slider-padding">
      <Slider ref={sliderRef} {...sliderSettings}>
        {!loading ? (categoryData.map((item: any) => (
          <div
            className={`categories__tabs tabs-hover-effect cursor-pointer ${select===item?.product_category_name && 'tabs-active'}`}
            key={item?.product_category_id}
            onClick={() => handleSelect(item?.product_category_id,item?.product_category_name)}
          >
            <button
              className="nav-link active text-truncate "
            >
              {item?.product_category_name}
            </button>
          </div>  
        ))):(
          Array.from({ length: 11 }, (_, index) => (
            <Skeleton key={index} height={30}  borderRadius={50}/>
          ))
        )}
      </Slider>
    </div>
  );
}

export default MarketTabs;
