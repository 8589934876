"use client";
import Image from "next/image";
import { NetworkImgCardProps } from "@/utils/types";
import { motion } from "framer-motion";
import { default_supplier_logo } from "@/utils/CountryCodes";
import { AMAZONE_MEDIA_URL } from "@/utils/constant";

function NetworkImgCard({ id, image, cardVariants }: NetworkImgCardProps) {
  return (
    <motion.div className="col-md-3 mt-3" variants={cardVariants}>
      <div className="network-image-card overflow-hidden position-relative">
        <img
          loading="lazy"
          alt="image"
          src={
            image
              ? `${AMAZONE_MEDIA_URL}User/${id}/BusinessLogo/${image}`
              : default_supplier_logo
          }
          width="100%"
          height={190}
          fetchPriority="high"
          rel="preload"
          className="card-img-cover"
        />
      </div>
    </motion.div>
  );
}

export default NetworkImgCard;
