"use client";
import { MAINDOMAINURL, PROTOCOL } from "@/utils/constant";
import { useClientMediaQuery } from "@/hooks/use-client-media-query";
import { Link } from "@/navigation";
import { getCookie } from "@/utils/helpers";
import { useSettingsContext } from "@/context";

function SearchButton() {
  const { currentLocale } = useSettingsContext();
  const activeLang = currentLocale || "en";
  let link = "experts." + MAINDOMAINURL + `/${activeLang}` + "/signup-expert";
  const isMobile = useClientMediaQuery("(max-width: 768px)");

  return (
    <div>
      <ul className="nav bg-white mt-2 mt-md-3">
        <li className="nav-item rounded-5 mx-3 px-3 bg-light-blue my-2">
          <Link className="nav-link active text-dark" href="/sign-up-supplier">
            Become a Supplier
          </Link>
        </li>
        <li className="nav-item rounded-5 mx-3 px-3  bg-light-purple my-2">
          <Link className="nav-link text-dark" href="/sign-up-buyer">
            Become a Buyer
          </Link>
        </li>
        <li className="nav-item rounded-5 mx-3 px-3 bg-light-green my-2">
          <Link className="nav-link text-dark" href={link}>
            Become an Expert
          </Link>
        </li>
      </ul>
    </div>
  );
}

export default SearchButton;
