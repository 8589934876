"use client";
import {
  InputGroup,
  Dropdown,
  DropdownButton,
  Form,
  Button,
} from "@/components/bootstrap";
import { SearchIcon } from "@/utils/icons";
import { useTranslations } from "next-intl";

function SearchBar() {
const searchText = useTranslations("searchText")
  return (
    <InputGroup>
      <DropdownButton variant="outline-light" title="Furniture">
        <Dropdown.Item eventKey="Office">Office</Dropdown.Item>
        <Dropdown.Item eventKey="Home">Home</Dropdown.Item>
      </DropdownButton>
      <Form.Control
        aria-label="Search supplier , experts, products etc"
        aria-describedby="basic-addon1"
        placeholder={searchText("search_supplier_experts_products_etc")}
      />
      <Button href="#" variant="" className="text-black" size="lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-x-lg mt-2"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </Button>
      <Button href="#" variant="" className="text-black" size="lg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          className="bi bi-mic mt-2"
          viewBox="0 0 16 16"
        >
          <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z" />
          <path d="M10 8a2 2 0 1 1-4 0V3a2 2 0 1 1 4 0v5zM8 0a3 3 0 0 0-3 3v5a3 3 0 0 0 6 0V3a3 3 0 0 0-3-3z" />
        </svg>
      </Button>
      <Button variant="outline-primary search-btn justify-content-between align-items-center">
        <span className="bi-search-1">
          <SearchIcon />
        </span>
      </Button>
    </InputGroup>
  );
}

export default SearchBar;
