"use client";
import { BussinessCardProps } from "@/utils/types";

function BusinessMobileCard({
  image,
  heading,
  content,
  subcontent,
}: BussinessCardProps) {
  return (
    <div className="col-md-6 col-lg-3 col-sm-6 my-3">
      <div className="row bg-white px-2 py-4">
        <div className="col-8 mt-2 ">
          <h4 className="main-heading text-black  fw-normal px-4">{heading}</h4>
          <p className="fw-light m-0 px-4">{content}</p>
          <h4 className="main-heading text-black mt-3 fs-6 fw-normal px-4 ">
            {subcontent}
          </h4>
        </div>
        <div className="col-4 text-center">
          <img
            loading="lazy"
            alt=""
            src={image}
            width="100%"
            height="100%"
            className="card-img-contain"
          />
        </div>
      </div>
    </div>
  );
}

export default BusinessMobileCard;
