import React from "react";
import DonutChart from "./donut-chart";

const ChartView = () => {
  return (
    <div className="d-flex justify-content-between w-100 m-auto">
      <div className="d-flex flex-column align-items-center">
        <DonutChart series={[3.1, 1.9]} />
        <p className="fs-7 fw-normal text-center">
          Work <br /> Quality
        </p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <DonutChart series={[4.1, 0.9]} />
        <p className="fs-7 fw-normal text-center">
          Overall <br /> Behaviour
        </p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <DonutChart series={[3.8, 1.2]} />
        <p className="fs-7 fw-normal text-center">
          Work <br /> Experience
        </p>
      </div>
      <div className="d-flex flex-column align-items-center">
        <DonutChart series={[3.8, 1.2]} />
        <p className="fs-7 fw-normal text-center">
          Response <br /> Rate
        </p>
      </div>
    </div>
  );
};

export default ChartView;
