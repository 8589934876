"use client";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ShowAddressModal from "./show-address-modal";
import ChartView from "./chart-view";
import { StarIconplus } from "@/utils/icons";
import RatingView from "./rating-view";

const RatingReviewSection = ({ data }: any) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  return (
    <div className="bg-white">
      <div className="d-flex justify-content-between my-2 gap-10 px-4 py-4">
        <div className="row py-2">
          <div className="col-md-3">
            <h4 className="fw-normal fs-5">
              4.5 <StarIconplus />
            </h4>
            <h6 className="fw-light fs-7">
              72 Ratings & <br /> 17 Reviews
            </h6>
          </div>
          <div className="col-md-9">
            <RatingView />
          </div>
        </div>
        <div className="w-400">
          <ChartView />
        </div>
      </div>

      <Button
        href="#"
        variant="primary"
        className="text-white rounded-5 px-3 my-sm-4 mx-4"
        size="sm"
        onClick={() => setModalShow(true)}
      >
        View review
      </Button>

      <ShowAddressModal
        type="supplier"
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
    </div>
  );
};

export default RatingReviewSection;
