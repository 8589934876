"use client";
import React, { useEffect, useState } from "react";
import { Button } from "@/components/bootstrap";
import ExpertLoginSignupModal from "./expert-login-signup-modal";
import Skeleton from "react-loading-skeleton";
import { useSettingsContext } from "@/context";

const ExpertDetailsPortfolio = ({ data }: any) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const {onUpdate} = useSettingsContext();
  return (
  <>
  {
    !loading ? (
      <div className="rounded-5 bg-white overflow-hidden position-relative my-3 text-center">
      <div className="d-flex justify-content-between align-items-center px-4 my-4">
        <h5
          className="main-heading text-black fw-normal"
          style={{ fontSize: "1.25rem" }}
        >
          Portfolio
        </h5>
        <div className="d-flex justify-content-center align-items-center px-1 gap-2">
          <Button
            href="#"
            variant="secondary"
            className="text-white px-3 rounded-5"
            size="sm"
            onClick={() => {
              onUpdate("redirectionUrl", {
                path: "Create RFI for Expert",
                id: data?.user_id || ""
              });
              setModalShow(true)}}
          >
            RFI
          </Button>
          <Button
            href="#"
            variant="primary"
            className="text-white px-3 rounded-5"
            size="sm"
            onClick={() => {
              onUpdate("redirectionUrl", {
                path: "Create RFP for Expert",
                id: data?.user_id || ""
              });
              setModalShow(true)}}
          >
            RFP
          </Button>
        </div>
      </div>

      <iframe
        src="/assets/resume/portfolio.pdf#toolbar=0&navpanes=0&scrollbar=0"
        className="mb-4 custom-experts-portfolio"
      ></iframe>
    </div>
    ) : (
      <div className="rounded-5 bg-white overflow-hidden position-relative my-3 text-center">
      <div className="d-flex justify-content-between align-items-center px-4 my-4">
        <h5>
               <Skeleton height={20} width={170} />
        </h5>
        <div className="d-flex justify-content-center align-items-center px-1 gap-2">
        <Skeleton height={40} borderRadius={50} width={70} />
        <Skeleton height={40} borderRadius={50} width={70} />

        </div>
      </div>

      <Skeleton height={500} />

    </div>
    )
  }

   <ExpertLoginSignupModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={data}
      />
  </>
  );
};

export default ExpertDetailsPortfolio;
