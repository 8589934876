"use client";
import { fetchHomeContent } from "@/utils/apiServices";
import React, { useState, useEffect } from "react";
const ExpertSuccessStories = () => {
  const [successStory, setSuccessStory] = useState<any>();
  useEffect(() => {
    async function fetchSuccessStoryData() {
      try {
        const payload = {
          filter: {
            slug: {
              operator: "eq",
              value: "business network",
            },
          },
          projection: {
            populate: [
              "content.items.cta",
              "content.items.theme",
              "content.cta",
              "deep",
            ],
          },
        };
        const SuccessData = await fetchHomeContent(payload);
        const successStoryList = await SuccessData[0]?.content?.find(
          (item: any) => item.category == "list" && item.order == 3
        );
        setSuccessStory(successStoryList);
      } catch (error) {
        console.error("Error fetching Coordinates data:", error);
      }
    }
    fetchSuccessStoryData();
  }, []);

  return (
    <>
      {successStory && (
        <div className="my-sm-5">
          <h3 className="main-heading text-black fs-5 fw-normal">
            {successStory[0]?.headline}
          </h3>
          <div className="row my-sm-2">
            {successStory?.items.map((item: any) => (
              <div className="col-12 col-md-6" key={item.id}>
                <div className="rounded-5 shadow bg-white p-4 my-4">
                  <div className="d-flex flex-column flex-md-row align-items-center justify-content-center justify-content-md-between gap-4">
                    <img
                      loading="lazy"
                      className="rounded-circle border mb-3 mb-md-0"
                      alt="image"
                      src="/assets/blog/3.jpg"
                      width={90}
                      height={90}
                    />
                    <div>
                      <p className="fw-light m-0 px-2 mb-3">{item.headline}</p>
                      <p className="fw-bold m-0 px-2 my-3 mb-0">
                        - {item.title}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default ExpertSuccessStories;
