'use client'
import { FC, useEffect, useState } from "react";
import Reveal from "../common/Reveal";
import { InfoCard } from "../common";
import { InfoContentDataProps } from "@/utils/types";
import Skeleton from "react-loading-skeleton";

type props = {
  infoContentData: InfoContentDataProps;
};

const InfoContent: FC<props> = ({ infoContentData }) => {
const [loading, setLoading] = useState(true);
useEffect(() => {
  if (infoContentData) {
    setLoading(false);
  }
}, [infoContentData]);
  return (
    <Reveal width="100%">
      <div className="info-content-widget container-fluid px-3 px-lg-5 py-10">
        <div className="row px-md-4 px-lg-5">
         { infoContentData?.items?.map((item: any, index: number) => {
            return (
              <InfoCard
                key={index}
                heading={item?.title}
                content={item?.headline}
                image={item?.imageUrl}
              />
            );
          })}
        </div>
      </div>
    </Reveal>
  );
};

export default InfoContent;
