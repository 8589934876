"use client";
import { FC, useEffect, useState } from "react";
import { ProductGalleryCard } from "../common";
import { ProductImgData, SubProductImgSectionProps } from "@/utils/types";
import { Variants } from "framer-motion";
import Slider from "react-slick";

const ProductImageGallery: FC<SubProductImgSectionProps> = ({
  imgData,
  supplier_id,
  subid,
  fetchImagePath,
}: SubProductImgSectionProps) => {
  const [imageData, setImageData] = useState([]);

  useEffect(() => {
    const result: any = imgData?.filter(
      (item) =>
        item.media_path.split(".")[item.media_path.split(".").length - 1] !==
        "pdf"
    );
    setImageData(result);
  }, [imgData]);

  const sliderSettings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
  };

  return (
    <div className="my-2 my-md-0 my-lg-0">
      <Slider {...sliderSettings}>
        {imageData?.map((item: ProductImgData, index: number) => {
          const cardVariants: Variants = {
            hidden: {
              y: 100,
            },
            visible: {
              y: 0,
              transition: {
                duration: 0.5,
                delay: 0.2 * (index + 1),
              },
            },
          };
          return (
            <ProductGalleryCard
              key={index}
              supplier_id={supplier_id}
              id={subid}
              image={item?.media_path}
              cardVariants={cardVariants}
              fetchImagePath={fetchImagePath}
            />
          );
        })}
      </Slider>
    </div>
  );
};

export default ProductImageGallery;
