import {
  csvIcon,
  docIcon,
  pdfIcon,
  pptIcon,
  xlsIcon,
  xmlIcon,
  imgIcon,
} from "./constant";
import { TranHoverType } from "./types";
import { parse } from "parse5";

export const varTranHover = (props?: TranHoverType) => {
  const duration = props?.duration || 0.32;
  const ease = props?.ease || [0.43, 0.13, 0.23, 0.96];
  return { duration, ease };
};

export const isObjectEmpty = (objectName: object): boolean => {
  return JSON.stringify(objectName) === "{}";
};

export const getValidSubdomain = (host?: string | null) => {
  let subdomain: string | null = null;
  if (!host && typeof window !== "undefined") {
    // On client side, get the host from window
    host = window.location.host;
  }
  if (host && host.includes(".")) {
    const candidate = host.split(".")[0];
    if (
      candidate &&
      !candidate.includes("localhost") &&
      !candidate.includes("alpha")
    ) {
      // Valid candidate
      subdomain = candidate;
    }
  }
  return subdomain;
};

export const DocumentIcon = (filename: string) => {
  var ext = filename.split(".").pop();
  if (ext === "pdf") {
    return pdfIcon;
  } else if (ext === "csv") {
    return csvIcon;
  } else if (ext === "doc" || ext === "docx") {
    return docIcon;
  } else if (ext === "ppt") {
    return pptIcon;
  } else if (ext === "xls" || ext === "xlsx") {
    return xlsIcon;
  } else if (ext === "xml") {
    return xmlIcon;
  } else {
    return imgIcon;
  }
};

const CURRENCY = "SAR";

export const printCurrency = (value: string | number) => {
  if (
    value !== "" &&
    value !== "null" &&
    value !== undefined &&
    typeof value !== "object"
  ) {
    var formatter = new Intl.NumberFormat("en-SA", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    var value_var;
    if (typeof value === "number") {
      value_var = formatter.format(value);
    } else {
      value_var = formatter.format(parseFloat(value.replace(/\,/g, "")));
    }
    let html =
      "<span>" +
      value_var +
      "<label className='Currency-icon'> " +
      CURRENCY +
      "</label></span>";
    return html;
  } else {
    let html =
      "<span><label className='Currency-icon'> " + CURRENCY + "</label></span>";
    return html;
  }
};

export const getCookie = (name: string) => {
  if (typeof document !== "undefined") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.startsWith(name + "=")) {
        return cookie.substring(name.length + 1);
      }
    }
  }
  return null;
};

// export const stripHtmlTags = (htmlString: any) => {
//   const doc = new DOMParser().parseFromString(htmlString, "text/html");
//   return doc.body.textContent || "";
// };

export const stripHtmlTags = (htmlString: any) => {
  const document = parse(htmlString);
  const traverse = (node: any): string => {
    if (node.nodeName === "#text") {
      return node.value;
    }
    if (node.childNodes) {
      return node.childNodes.map(traverse).join("");
    }
    return "";
  };
  return traverse(document);
};

export const login = (user_id: any, isCategoryExpert = false) => {
  switch (isCategoryExpert) {
    case true:
      localStorage.setItem("procureToken_expert", JSON.stringify(true));
      localStorage.setItem("user_id_expert", user_id);
      localStorage.setItem("breadcrumArr", JSON.stringify([]));
      break;

    case false:
      localStorage.setItem("procureToken", JSON.stringify(true));
      localStorage.setItem("breadcrumArr", JSON.stringify([]));
      localStorage.setItem("user_id", user_id);
      break;

    default:
      return;
  }
};

export const addUser = (user: any) => {
  localStorage.setItem(document.location.hostname, JSON.stringify(user));

  if (user.permission_managements) {
    var permissionArr: { [index: string]: { is_master: boolean } } = {};
    user.permission_managements.forEach(function (arr: any) {
      permissionArr[arr.index_field] = { is_master: arr.is_master };
    });
    localStorage.setItem("permission", JSON.stringify(permissionArr));
  }
};
