"use client";
import React, { useState } from "react";
import dynamic from "next/dynamic";
const Chart = dynamic(() => import("react-apexcharts"), { ssr: false });

const DonutChart = ({ series }: any) => {
  const [chartData, setChartData] = useState({
    options: {
      plotOptions: {
        pie: {
          donut: {
            size: "85%",
          },
        },
      },
      colors: ["#FF6A57", "#FFFFFF"],
      labels: [],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
    },
    series: series,
  });

  return (
    <div className="donut donut-chart">
      <Chart
        options={chartData.options}
        series={chartData.series}
        type="donut"
        width={100}
        height={110}
      />
      <p className="donut-text">{chartData.series[0]}</p>
    </div>
  );
};

export default DonutChart;
