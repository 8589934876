"use client";
import React from "react";

export default function ProductQuantity({
  quantity,
  incrementQuantity,
  decrementQuantity,
}: any) {
  
  return (
    <div className="btn-wrapper-prod justify-content-sm-start justify-content-center">
      <div className="quantity-input">
        <button className="quantity-button" onClick={decrementQuantity}>
          -
        </button>
        <span className="quantity-number">{quantity}</span>
        <button className="quantity-button" onClick={incrementQuantity}>
          +
        </button>
      </div>
    </div>
  );
}
