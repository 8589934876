"use client";
import { FC } from "react";
import { PublicRFPCard } from "../common";
import { pulicRfpDataType } from "@/utils/types";
import { Variants } from "framer-motion";
import Reveal from "../common/Reveal";

const PublicRfpSection: FC<any> = ({ data }) => {
  return (
    <div className="py-3">
      <Reveal width="100%">
        <div className="row row gx-5 gy-3">
          {data &&
            data.map((item: pulicRfpDataType, index: number) => {
              const cardVariants: Variants = {
                hidden: {
                  y: 100,
                },
                visible: {
                  y: 0,
                  transition: {
                    duration: 0.5,
                    delay: 0.2 * (index + 1),
                  },
                },
              };
              return (
                <PublicRFPCard
                  key={index}
                  id={item.id}
                  user_id={item.user_id}
                  business_name={item.business_name}
                  purchase_type={item.purchase_type}
                  business_logo_path={item.business_logo_path}
                  cardVariants={cardVariants}
                />
              );
            })}
        </div>
      </Reveal>
    </div>
  );
};

export default PublicRfpSection;
